import { Button, Label, Modal, TextInput } from 'flowbite-react';
import { useRef, useState } from 'react';

import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';

interface ThumbnailModalProps<T> {
  open: boolean;
  onClose: () => void;
  onUpload: (data: T, thumb?: File | null) => void;
  formValues: T & { name?: string; description: string };
  loading?: boolean;
}
export const ThumbnailModal = <T extends object>(props: ThumbnailModalProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [thumb, setThumb] = useState<File | undefined>();
  const [data, setData] = useState(props.formValues);
  const onFileChange = () => {
    if (inputRef && inputRef.current && inputRef.current.files)
      setThumb(inputRef.current?.files[0]);
  };
  return (
    <>
      <Modal show={props.open} onClose={props.onClose}>
        <Modal.Header>Edit basic info</Modal.Header>
        <Modal.Body>
          <Label>
            Name<span style={{ color: 'red' }}>*</span>:
          </Label>
          <TextInput
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
          <Label>
            Description<span style={{ color: 'red' }}>*</span>:
          </Label>
          <TextInput
            value={data.description}
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />
          <Label>Thumbnail:</Label>
          <div>
            <input type={'file'} ref={inputRef} onChange={onFileChange} />
          </div>
          {inputRef.current && inputRef.current.files && inputRef.current.files[0] ? (
            <div className={'flex justify-center items-center'}>
              <img
                src={thumb ? URL.createObjectURL(thumb) : ''}
                height={300}
                style={{ height: 300 }}
                alt={'thumbnail for upload'}
              />
            </div>
          ) : (
            <div className={'flex justify-center items-center'} style={{ height: 300 }}>
              <strong>No valid thumbnail added!</strong>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              inputRef.current?.files
                ? props.onUpload(data, inputRef.current?.files[0])
                : props.onUpload(data)
            }
            disabled={props.loading || data.name === '' || data.description === ''}
          >
            {props.loading && <LoadingIndicator />}Edit
          </Button>
          <Button color={'gray'} onClick={props.onClose} disabled={props.loading}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
