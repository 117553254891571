import './style.scss';

import React, { useContext, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineGroup,
  AiOutlineUngroup,
} from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ModeEnum } from '../../../core/ui/enums/ModeEnum';
import { AnimationPanelDef } from '../../../model/definitions/AnimationPanelDef';
import { AudioElement } from '../../../model/definitions/AudioElement';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../../../model/definitions/ImagePanelDef';
import { KeyFramesDef } from '../../../model/definitions/KeyFramesDef';
import { LogicalGroupElement } from '../../../model/definitions/LogicalGroupElement';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { PointDateDef } from '../../../model/definitions/PointDateDef';
import { SceneDef } from '../../../model/definitions/SceneDef';
import { TextPanelDef } from '../../../model/definitions/TextPanelDef';
import { VideoPanelDef } from '../../../model/definitions/VideoPanelDef';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { GroupingEnum } from '../../../model/UI/enums/GroupingEnum';
import PlayerContext from '../../../pages/playground/playerContext/PlayerContext';
import {
  ActiveDef,
  addToMultiselect,
  setElement,
  setGrouping,
} from '../../../store/slices/active-slice';
import { CurrentMapState } from '../../../store/slices/map-state.slice';
import {
  addKeyFrame,
  addLogicalGroup,
  cutElement,
  enableLayer,
  updatePanel,
} from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import { AllElementsDefs, ElementType } from '../../../types/elements';
import { elementEnumToKey, keyToElementsEnum } from '../helpers';
import { LogicalGroupsList } from '../LogicalGroupsList';
import { AddGroupModal } from '../modals/AddGroupModal';
import MapListElement from './MapListElement';
import { PosterElementList } from './PosterElementList';
import RegularListElement from './RegularListElement';

interface Interface {
  project: C9ProjectDef;
  elements?: SceneDef;
  projectName?: string;
  openElement?: boolean;
  setOpenElement?: (e: boolean) => void;
  hide?: Array<SceneKeys<SceneDef>>;
  setHide?: (e: SceneKeys<SceneDef>) => void;
}

const ElementList = ({ elements, projectName, hide = [], setHide, project }: Interface) => {
  const { activeElement, biasMode, activeScene, activeProp, mode, grouping } =
    useSelector<RootState>((state) => state.active) as ActiveDef;
  const { time } = useContext(PlayerContext);
  const map = useSelector<RootState>((state) => state.mapState) as CurrentMapState;
  const addTypeToPanel = (
    panels:
      | TextPanelDef[]
      | AudioElement[]
      | VideoPanelDef[]
      | ImagePanelDef[]
      | AnimationPanelDef[]
      | WeatherPosterDef[]
      | MapPanelDef[]
      | ObservedWDElementDef[]
      | ForecastWDElementDef[]
      | PointDateDef[]
      | undefined,
    type: keyof SceneDef,
  ) => {
    const reworkedPanels:
      | TextPanelDef[]
      | AudioElement[]
      | VideoPanelDef[]
      | ImagePanelDef[]
      | AnimationPanelDef[]
      | WeatherPosterDef[]
      | ObservedWDElementDef[]
      | ForecastWDElementDef[]
      | PointDateDef[]
      | MapPanelDef[] = [];
    panels?.forEach((element) => {
      //@ts-ignore
      reworkedPanels.push({ ...element, elementType: type });
    });
    return reworkedPanels;
  };
  const isFlyoverEnabled = elements?.mapPanels?.find(
    (map) => map.id === activeElement,
  )?.flyOverEnabled;
  const dispatch = useDispatch();
  const [select, setSelect] = useState<boolean>();

  const [visibleImage, setVisibleImage] = useState(true);
  const [visibleAnimation, setVisibleAnimation] = useState(true);
  const [visibleMap, setVisibleMap] = useState(true);
  const [visibleVideo, setVisibleVideo] = useState(true);
  const [visibleText, setVisibleText] = useState(true);
  const [visibleAudio, setVisibleAudio] = useState(true);
  const [visibleWeather, setVisibleWeather] = useState(true);
  const [visibleObserved, setVisibleObserved] = useState(true);
  const [visibleForecast, setVisibleForecast] = useState(true);
  const [visibleDate, setVisibleDate] = useState(true);
  const [isAddGroup, setAddGroup] = useState<boolean>(false);
  const panels = [
    addTypeToPanel(elements?.textPanels, 'textPanels'),
    addTypeToPanel(elements?.weatherPosters, 'weatherPosters'),
    addTypeToPanel(elements?.imagePanels, 'imagePanels'),
    addTypeToPanel(elements?.animationPanels, 'animationPanels'),
    addTypeToPanel(elements?.videoPanels, 'videoPanels'),
    addTypeToPanel(elements?.mapPanels, 'mapPanels'),
    addTypeToPanel(elements?.audioElements, 'audioElements'),
    addTypeToPanel(elements?.observedWDElements, 'observedWDElements'),
    addTypeToPanel(elements?.forecastWDElements, 'forecastWDElements'),
    addTypeToPanel(elements?.pointDates, 'pointDates'),
    addTypeToPanel(elements?.pointLocation, 'pointLocation'),
  ]
    .flat()
    .filter(Boolean);
  useHotkeys(
    'insert',
    (ev) => {
      ev.preventDefault();
      if (isFlyoverEnabled) createFlyOverKeyFrame('PARABOLIC');
    },
    [createFlyOverKeyFrame, isFlyoverEnabled],
  );
  const handleAddGroup = (element: LogicalGroupElement) => {
    dispatch(addLogicalGroup({ activeScene, element }));
  };
  useHotkeys(
    'ctrl+insert, command+insert',
    (ev) => {
      ev.preventDefault();
      if (isFlyoverEnabled) createFlyOverKeyFrame('LINEAR');
    },
    [createFlyOverKeyFrame, isFlyoverEnabled],
  );
  useHotkeys(
    'up',
    (ev) => {
      ev.preventDefault();
      !biasMode && selectPrevNext('prev');
    },
    [panels, activeElement],
  );
  useHotkeys(
    'down',
    (ev) => {
      ev.preventDefault();
      !biasMode && selectPrevNext('next');
    },
    [panels, activeElement],
  );
  const sceneDefinition = project.sceneDefs.find((scene) => scene.id === activeScene);
  function createFlyOverKeyFrame(transition?: string) {
    let keyFrame = new KeyFramesDef();
    keyFrame = {
      ...keyFrame,
      mapPositionControl: { ...map.mapState },
      timeInMS: time,
      transitionTilt: 0,
      transitionType: transition ?? 'LINEAR',
    };
    dispatch(
      addKeyFrame({
        activeScene: activeScene as string,
        elementId: activeElement,
        keyFrame: keyFrame,
      }),
    );
    setSelect(false);
  }
  function selectPrevNext(direction: 'prev' | 'next') {
    if (!activeElement) return;
    const currentIndex = panels.findIndex((p) => p.id === activeElement);
    if (currentIndex === -1) return;
    if (currentIndex === 0 && direction === 'prev') return;
    if (currentIndex === panels.length - 1 && direction === 'next') return;
    const newIndex = direction === 'prev' ? currentIndex - 1 : currentIndex + 1;
    const newElId = panels[newIndex]?.id;
    const newElType = panels[newIndex]?.elementType;
    dispatch(setElement({ activeElement: newElId, activeProp: newElType }));
  }
  const enable = (e: boolean, id: string | number, elementType: string) => {
    dispatch(
      enableLayer({
        activeScene: activeScene,
        activeProp: elementType as keyof SceneDef,
        activeElement: id,
        value: e,
      }),
    );
  };
  const cut = () => {
    dispatch(
      cutElement({
        activeScene: activeScene,
        activeProp: activeProp as keyof SceneDef,
        activeElement: activeElement,
        time,
      }),
    );
  };
  const deleteElement = () => {
    dispatch(
      updatePanel({
        activeScene: activeScene,
        activeProp: activeProp as keyof SceneDef,
        activeElement: activeElement,
      }),
    );
  };

  const addTypesToMultiselect = (
    e: React.MouseEvent,
    type: SceneKeys<SceneDef>,
    callback: () => void,
  ) => {
    if ((e.metaKey || e.ctrlKey) && elements) {
      const els = elements[type];
      els.forEach((el) => {
        dispatch(
          addToMultiselect({
            element: { element: el as AllElementsDefs, type: type as ElementType },
          }),
        );
      });
    } else {
      setHide && setHide(type);
      callback();
    }
  };
  const insetGroupingPanel = (id: string, type: SceneKeys<SceneDef>) => {
    // @ts-ignore
    const index = elements && elements[type].findIndex((object) => object.id === id);
    if (index === 0)
      switch (type) {
        case 'imagePanels':
          return (
            <div
              className={`element-parent ${visibleImage ? 'element-parent-active' : ''}`}
              onClick={(e) => addTypesToMultiselect(e, type, () => setVisibleImage(!visibleImage))}
            >
              {!visibleImage ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              IMAGE <span>({sceneDefinition && sceneDefinition[type].length})</span>{' '}
            </div>
          );
        case 'animationPanels':
          return (
            <div
              className={`element-parent ${visibleAnimation ? 'element-parent-active' : ''}`}
              onClick={(e) =>
                addTypesToMultiselect(e, type, () => setVisibleAnimation(!visibleAnimation))
              }
            >
              {!visibleAnimation ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              ANIMATION <span>({sceneDefinition && sceneDefinition[type].length})</span>{' '}
            </div>
          );
        case 'pointDates':
          return (
            <div
              className={`element-parent ${visibleDate ? 'element-parent-active' : ''}`}
              onClick={(e) => addTypesToMultiselect(e, type, () => setVisibleDate(!visibleDate))}
            >
              {!visibleDate ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              DATES <span>({sceneDefinition && sceneDefinition[type]?.length})</span>{' '}
            </div>
          );
        case 'pointLocation':
          return (
            <div
              className={`element-parent ${visibleDate ? 'element-parent-active' : ''}`}
              onClick={(e) => addTypesToMultiselect(e, type, () => setVisibleDate(!visibleDate))}
            >
              {!visibleDate ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              LOCATIONS <span>({sceneDefinition && sceneDefinition[type]?.length})</span>{' '}
            </div>
          );
        case 'observedWDElements':
          return (
            <div
              className={`element-parent ${visibleObserved ? 'element-parent-active' : ''}`}
              onClick={(e) =>
                addTypesToMultiselect(e, type, () => setVisibleObserved(!visibleObserved))
              }
            >
              {!visibleObserved ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              OBSERVED <span>({sceneDefinition && sceneDefinition[type].length})</span>{' '}
            </div>
          );
        case 'forecastWDElements':
          return (
            <div
              className={`element-parent ${visibleForecast ? 'element-parent-active' : ''}`}
              onClick={(e) =>
                addTypesToMultiselect(e, type, () => setVisibleForecast(!visibleForecast))
              }
            >
              {!visibleForecast ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              FORECAST <span>({sceneDefinition && sceneDefinition[type].length})</span>{' '}
            </div>
          );
        case 'videoPanels':
          return (
            <div
              className={`element-parent ${visibleVideo ? 'element-parent-active' : ''}`}
              onClick={(e) => addTypesToMultiselect(e, type, () => setVisibleVideo(!visibleVideo))}
            >
              {!visibleVideo ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              VIDEO <span>({sceneDefinition && sceneDefinition[type].length})</span>
            </div>
          );
        case 'textPanels':
          return (
            <div
              className={`element-parent ${visibleText ? 'element-parent-active' : ''}`}
              onClick={(e) => addTypesToMultiselect(e, type, () => setVisibleText(!visibleText))}
            >
              {!visibleText ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              TEXT <span>({sceneDefinition && sceneDefinition[type].length})</span>
            </div>
          );
        case 'mapPanels':
          return (
            <div
              className={`element-parent ${visibleMap ? 'element-parent-active' : ''}`}
              onClick={(e) => addTypesToMultiselect(e, type, () => setVisibleMap(!visibleMap))}
            >
              {!visibleMap ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              MAP <span>({sceneDefinition && sceneDefinition[type].length})</span>
            </div>
          );
        case 'audioElements':
          return (
            <div
              className={`element-parent ${visibleAudio ? 'element-parent-active' : ''}`}
              onClick={(e) => addTypesToMultiselect(e, type, () => setVisibleAudio(!visibleAudio))}
            >
              {!visibleAudio ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              AUDIO <span>({sceneDefinition && sceneDefinition[type].length})</span>
            </div>
          );
        case 'weatherPosters':
          return (
            <div
              className={`element-parent ${visibleWeather ? 'element-parent-active' : ''}`}
              onClick={(e) =>
                addTypesToMultiselect(e, type, () => setVisibleWeather(!visibleWeather))
              }
            >
              {!visibleWeather ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
              COMPOSITE <span>({sceneDefinition && sceneDefinition[type].length})</span>
            </div>
          );
      }
    else return null;
  };
  const renderElementList = () => {
    const elementList: JSX.Element[] = [];
    panels.forEach((item) => {
      if (
        item.elementType !== elementEnumToKey(ElementsEnum.MAP) &&
        item.elementType !== 'weatherPosters'
      ) {
        const breakPoint = insetGroupingPanel(item?.id, item.elementType as SceneKeys<SceneDef>);
        elementList.push(
          <React.Fragment key={item.id}>
            {breakPoint}
            {hide.indexOf(item.elementType as SceneKeys<SceneDef>) < 0 && (
              <RegularListElement
                //@ts-ignore
                versionId={item.videoPanelDefTemplate?.versionId}
                item={item}
                elem={item}
                enable={enable}
                enabled={item.enabled}
                key={item.id}
                id={item.id}
                elementType={keyToElementsEnum(item.elementType)}
                name={item.name}
                cut={cut}
                deleteElement={deleteElement}
              />
            )}
          </React.Fragment>,
        );
      } else if (item.elementType === elementEnumToKey(ElementsEnum.MAP)) {
        const breakPoint = insetGroupingPanel(item?.id, item?.elementType);
        elementList.push(
          <React.Fragment key={item.id}>
            {breakPoint}
            {hide.indexOf(item.elementType as SceneKeys<SceneDef>) < 0 && (
              <MapListElement
                enabled={item.enabled}
                enable={enable}
                item={item as MapPanelDef}
                deleteElement={deleteElement}
                cut={cut}
                select={select}
                setSelect={setSelect}
              />
            )}
          </React.Fragment>,
        );
      } else if (item.elementType === elementEnumToKey(ElementsEnum.WEATHER_GRAPH)) {
        const breakPoint = insetGroupingPanel(item?.id, item?.elementType as SceneKeys<SceneDef>);
        elementList.push(
          <React.Fragment key={item.id}>
            {breakPoint}
            {hide.indexOf(item.elementType as SceneKeys<SceneDef>) < 0 && (
              <PosterElementList
                enable={enable}
                item={item as WeatherPosterDef}
                deleteElement={deleteElement}
                select={select}
                setSelect={setSelect}
              />
            )}
          </React.Fragment>,
        );
      }
    });
    return elementList;
  };
  const handleGrouping = (mode: GroupingEnum) => {
    dispatch(setGrouping({ mode }));
  };
  return (
    <div id={'ElementList'}>
      <div className={'flex group-tabs'}>
        <button
          className={`flex items-center p-0.5 mr-1 ml-2.5 group-button ${
            grouping === GroupingEnum.OFF ? 'active-group' : ''
          }`}
          title={'by element type'}
          onClick={() => handleGrouping(GroupingEnum.OFF)}
        >
          <AiOutlineUngroup />
        </button>
        <button
          className={`flex items-center p-0.5 mr-1 ml-2.5 group-button ${
            grouping === GroupingEnum.LOGICAL ? 'active-group' : ''
          }`}
          title={'Grouped'}
          onClick={() => handleGrouping(GroupingEnum.LOGICAL)}
        >
          <AiOutlineGroup />
        </button>
        {grouping === GroupingEnum.LOGICAL && (
          <button
            className={`flex items-center p-0.5 mr-1 ml-2.5 group-button create-group-button`}
            title={'Create group'}
            onClick={() => setAddGroup(true)}
          >
            Create group
          </button>
        )}
      </div>
      {mode === ModeEnum.SEQUENCE && (
        <div
          className={`w-full mt-[30px] ${
            grouping === GroupingEnum.OFF ? 'regular-list' : 'group-list'
          }`}
        >
          {grouping === GroupingEnum.OFF
            ? renderElementList()
            : elements && (
                <LogicalGroupsList
                  elements={elements}
                  cut={cut}
                  deleteElement={deleteElement}
                  select={select}
                  setSelect={setSelect}
                  enable={enable}
                />
              )}
        </div>
      )}
      {mode === ModeEnum.PROJECT && (
        <div style={{ padding: '25px 0 0 0' }}>
          <div className={'sequence-title'}>{projectName}</div>
        </div>
      )}
      <AddGroupModal
        show={isAddGroup}
        onClose={() => setAddGroup(false)}
        addGroup={handleAddGroup}
      />
    </div>
  );
};

export default ElementList;
