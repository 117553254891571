import './style.scss';

import { useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { VscTrash } from 'react-icons/vsc';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import Tabs from '../../atoms/Tabs/Tabs';
import { addSceneElement } from '../../core/api/ElementsAPI';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { getProjectDuration } from '../../helpers/timelineUtil';
import useRoleAccess from '../../hooks/useRoleAccess';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { RolesEnum } from '../../model/enums/RolesEnum';
import SceneDurationModal from '../../molecules/sceneDurationModal/SceneDurationModal';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import styles from '../../pages/playground/Playground.module.scss';
import { ActiveDef, setElement, setScene, setTime } from '../../store/slices/active-slice';
import { setSceneDuration } from '../../store/slices/duration-slice';
import {
  addScene,
  editScene as editCurrentScene,
  insertScene,
} from '../../store/slices/project-slice';
import { RootState, store } from '../../store/store';
import ElementList from '../timeline/elementList/ElementList';
import TimeLine from '../timeline/Timeline';
import DeleteSceneModal from './DeleteSceneModal';
import TimelineActions from './TimelineActions';

interface TimeProps {
  skip?: (e: number) => void;
  addElement?: () => void;
  stop?: () => void;
}

const TimelineContainer = ({ skip, addElement, stop }: TimeProps) => {
  const dispatch = useDispatch();
  const project = useSelector<RootState>((state) => state.project.present.project) as C9ProjectDef;
  const { activeZoom, mode, activeScene } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const roleAccess = useRoleAccess(
    [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER, RolesEnum.ROLE_FORECASTER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const roleProducer = useRoleAccess(
    [RolesEnum.ROLE_PRODUCER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const [c9Project, setC9Project] = useState<C9ProjectDef>(project);
  const timelineRef = useRef<HTMLDivElement>(null);
  const { time, setWidth } = useContext(PlayerContext);
  const [newSceneDuration, setNewSceneDuration] = useState<number>();
  const [addSceneModal, setAddSceneModal] = useState<boolean>(false);
  const [helperActive, setHelperActive] = useState<string | number>('');
  const [hiddenPanels, setHiddenPanels] = useState<Array<SceneKeys<SceneDef>>>([]);
  const [sceneName, setSceneName] = useState<string>('');
  const [sceneEdit, setSceneEdit] = useState<SceneDef | undefined>();
  useHotkeys('shift+ctrl+insert', (ev) => {
    ev.preventDefault();
    setAddSceneModal(true);
  });
  useEffect(() => {
    if (timelineRef.current && time > (timelineRef.current.offsetWidth / activeZoom + 400) * 10)
      timelineRef.current.scrollLeft = (time / 50) * activeZoom - 400;
    else if (timelineRef.current && time === 0) timelineRef.current.scrollLeft = 0;
  }, [time]);
  useEffect(() => {
    const elem = timelineRef.current;
    const parentWidth = elem?.offsetWidth;
    const left = elem?.offsetLeft;
    let width = 0;
    if (parentWidth && left) width = parentWidth - left - 250;
    setWidth(width);
  }, [activeZoom, mode, timelineRef.current?.offsetWidth]);
  const editHiddenElements = (e: SceneKeys<SceneDef>) => {
    if (hiddenPanels.indexOf(e) < 0) {
      const newPanels = [...hiddenPanels];
      newPanels.push(e);
      setHiddenPanels(newPanels);
    } else {
      let newPanels = [...hiddenPanels];
      newPanels = newPanels.filter((item) => item !== e);
      setHiddenPanels(newPanels);
    }
  };
  useEffect(() => {
    setC9Project(project);
    project.sceneDefs &&
      project.sceneDefs.length &&
      !store.getState().active.activeScene &&
      dispatch(setScene({ activeScene: project.sceneDefs[0].id }));
  }, [project]);
  const renderTabs = () => {
    return c9Project.sceneDefs?.map(
      (scene: SceneDef) =>
        scene && (
          <Tabs.Item title={scene?.name} itemKey={scene?.id} key={scene?.id}>
            <div className={styles.timelineHolder} ref={timelineRef}>
              <ElementList
                project={project}
                elements={scene}
                projectName={project.name}
                hide={hiddenPanels}
                setHide={(e) => editHiddenElements(e)}
              />
              <div className={styles.laneWrapper}>
                <TimeLine
                  addElement={addElement}
                  duration={scene.durationInMS}
                  elements={scene}
                  skip={skip}
                  hide={hiddenPanels}
                />
              </div>
            </div>
          </Tabs.Item>
        ),
    );
  };
  const addNewScene = async (data: SceneDef) => {
    dispatch(
      addScene({
        scene: {
          ...data,
          timeControl: new TimeControlDef(0, 0),
        },
      }),
    );
    dispatch(setSceneDuration({ scene: data.id, duration: newSceneDuration! * 1000 }));
    return data.id;
  };
  const sceneMutation = useMutation(addSceneElement, {
    onSuccess: (data) => {
      addNewScene(data).then((id) => dispatch(setScene({ activeScene: id })));
    },
  });
  const confirmAddingScene = (e: number) => {
    setNewSceneDuration(e);
    sceneMutation.mutate({
      name: sceneName && sceneName.length > 0 ? sceneName : `Scene ${project.sceneDefs.length + 1}`,
      description: '',
      duration: e * 1000,
    });
  };
  const confirmEditingScene = (e: number, scene: SceneDef) => {
    dispatch(
      editCurrentScene({
        id: scene.id,
        name: sceneName,
        duration: e * 1000,
        timeControl: new TimeControlDef(0, 0),
      }),
    );
    setSceneEdit(undefined);
  };
  const addSceneTab = () => {
    setAddSceneModal(true);
  };
  const copyScene = async (sceneId: string) => {
    const clonedScene = project.sceneDefs.find((scene) => scene.id === sceneId);
    const clonedSceneIndex = project.sceneDefs.findIndex((scene) => scene.id === sceneId);
    const res =
      clonedScene &&
      (await addSceneElement({
        name: clonedScene.name,
        description: clonedScene.description ? clonedScene.description : '',
        duration: clonedScene.durationInMS,
      }));
    clonedScene &&
      res &&
      dispatch(insertScene({ index: clonedSceneIndex, scene: clonedScene, id: res.id }));
  };
  const editScene = (sceneId: string) => {
    const scene = project.sceneDefs.find((scene) => scene.id === sceneId);
    setSceneEdit(scene);
    setAddSceneModal(true);
  };
  const [deleteSceneModal, setDeleteSceneModal] = useState(false);
  return (
    <div className="timeline-container">
      {mode === ModeEnum.SEQUENCE && (
        <>
          <Tabs
            onClick={(e: any) => {
              e.itemKey && setHelperActive(e.itemKey);
              e.itemKey && dispatch(setTime({ activeTime: 0 }));
              e.itemKey && dispatch(setScene({ activeScene: e.itemKey }));
              e.itemKey && stop && stop();
              e.itemKey && dispatch(setElement({ activeElement: '', activeProp: '' }));
            }}
            active={activeScene ? activeScene : helperActive}
            addItem={addSceneTab}
            deleteSceneModal={
              project.sceneDefs?.length > 1 &&
              roleAccess && (
                <button
                  title="Delete scene"
                  onClick={() => setDeleteSceneModal(true)}
                  className="text-red-500 flex items-center delete-scene"
                >
                  <VscTrash />
                </button>
              )
            }
            copy={(e) => copyScene(e)}
            edit={(e) => editScene(e)}
            timelineActions={<TimelineActions />}
            style={roleAccess ? {} : { pointerEvents: 'none', opacity: 0.3 }}
            roleAccess={roleAccess}
          >
            {renderTabs()}
          </Tabs>
        </>
      )}
      {mode === ModeEnum.PROJECT && (
        <div
          className={`${roleProducer ? '' : 'pointer-events-none opacity-30'} ${
            styles.timelineHolderProject
          }`}
          ref={timelineRef}
        >
          <div
            className={styles.laneWrapper}
            style={{
              position: 'relative',
              overflow: 'clip',
              overflowY: 'auto',
              height: '100%',
            }}
          >
            <TimeLine
              duration={getProjectDuration(project)}
              elements={undefined}
              project={project}
              skip={skip}
            />
          </div>
        </div>
      )}
      {addSceneModal && (
        <SceneDurationModal
          project={project}
          sceneName={sceneName}
          setSceneName={setSceneName}
          show={addSceneModal}
          onClose={() => {
            setAddSceneModal(false);
            setSceneEdit(undefined);
          }}
          scene={sceneEdit}
          onConfirm={(duration, scene) =>
            scene ? confirmEditingScene(duration, scene) : confirmAddingScene(duration)
          }
        />
      )}
      {deleteSceneModal && <DeleteSceneModal onClose={() => setDeleteSceneModal(false)} />}
    </div>
  );
};

export default TimelineContainer;
