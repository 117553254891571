import './style.scss';

import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import { Map } from 'ol';
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { IFileWithMeta } from 'react-dropzone-uploader';
import {
  AiOutlineArrowRight,
  AiOutlineAudio,
  AiOutlineFileImage,
  AiOutlineVideoCamera,
} from 'react-icons/ai';
import { BsChatText, BsCheck2Square, BsMap } from 'react-icons/bs';
import { TbView360 } from 'react-icons/tb';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Button from '../../atoms/button/Button';
import LoadingIndicator from '../../atoms/loadingIndicator/LoadingIndicator';
import { useAddPointDateElement } from '../../core/api/elements/useAddPointDateElement';
import { addMapElement, addTextElement, FormValues } from '../../core/api/ElementsAPI';
import { useGetForecastElement } from '../../core/api/forecastData/useGetForecastElement';
import { useGetObservedElement } from '../../core/api/observedData/useGetObservedElement';
import { ElementsEnum } from '../../core/ui/enums/ElementsEnum';
import { fromBoundsToGeoBox } from '../../helpers/boundsManage';
import { fitToCanvasHelper } from '../../helpers/fitToCanvas';
import { findMaxInArray, getMaxZindex } from '../../helpers/timelineUtil';
import useRoleAccess from '../../hooks/useRoleAccess';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_TYPE,
  DEFAULT_FONT_VARIANT_ID,
  MAX_ZINDEX_VALUE,
} from '../../model/constants/constants';
import { AudioElement } from '../../model/definitions/AudioElement';
import { AudioElementTemplate } from '../../model/definitions/AudioElementTemplate';
import { BaseMapDef } from '../../model/definitions/BaseMapDef';
import { BorderDef } from '../../model/definitions/BorderDef';
import { BoxDef } from '../../model/definitions/BoxDef';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { CustomBackgroundImageDef } from '../../model/definitions/CustomBackgroundImageDef';
import { ForecastLocationDef } from '../../model/definitions/ForecastLocationDef';
import { ForecastWDElementDef } from '../../model/definitions/ForecastWDElementDef';
import { ForecastWDLocationDef } from '../../model/definitions/ForecastWDLocationDef';
import { ImagePanelDef } from '../../model/definitions/ImagePanelDef';
import { ImagePanelDefTemplate } from '../../model/definitions/ImagePanelDefTemplate';
import { LogicalGroupParent } from '../../model/definitions/LogicalGroupParent';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { MapPositionControlDef } from '../../model/definitions/MapPositionControlDef';
import { MapStyleDef } from '../../model/definitions/MapStyleDef';
import { ObservedWDElementDef } from '../../model/definitions/ObservedWDElementDef';
import { ObservedWDStationDef } from '../../model/definitions/ObservedWDStationDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { SupportedProjection } from '../../model/definitions/SupportedProjection';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import { VideoPanelDefTemplate } from '../../model/definitions/VideoPanelDefTemplate';
import { WeatherDataSpaceDef } from '../../model/definitions/WeatherDataSpaceDef';
import { ForecastElementRequestDTO } from '../../model/DTO/ForecastElementRequestDTO';
import { ObservedWDElementRequestDTO } from '../../model/DTO/ObservedWDElementRequestDTO';
import { PointLabel } from '../../model/enums/PointLabel';
import { RolesEnum } from '../../model/enums/RolesEnum';
import { ForecastElementForm } from '../../model/UI/ForecastElementForm';
import { transformAbsoluteToPercent } from '../../molecules/canvasElements/utils';
import { getProjections } from '../../molecules/mapElement/getProjections';
import {
  // correctLongitude,
  extentMercBoundingBox,
  getLatitudeFromCenterToTopMap,
  getProjectionStringForUtm,
  utmZoneFromLonLat,
} from '../../molecules/mapElement/helpers';
import Modal from '../../molecules/modal/Modal';
import playerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef, setDuration, setElement, setSyncSpace } from '../../store/slices/active-slice';
import {
  addAudioLayer,
  addForecastElements,
  addImageLayer,
  addMapLayer,
  addOWDLayer,
  addTextLayer,
  addVideoLayer,
} from '../../store/slices/project-slice';
import { RootState, store } from '../../store/store';
import { AddForecastData } from './AddForecastData';
import AddMapElement from './AddMapElement';
import AddMultimediaElement from './AddMultimediaElement';
import AddObservedData from './AddObservedData';
import AddTextElement from './AddTextElement';

interface Interface {
  isOpen: boolean;
  setAddElementModal?: any;
  onClose: () => void;
  filterTab?: string;
  duration?: number;
}
const AddElementModalDropdown = ({
  isOpen,
  setAddElementModal,
  onClose,
  duration = 45000,
  filterTab,
}: Interface) => {
  const [selectedElement, setSelectedElement] = useState<ElementsEnum>();
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const { activeAspectRatio, activeScene, previewSize, activeElement } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  useEffect(() => {
    activeElement && dispatch(setElement({ activeElement: '', activeProp: '' }));
  }, [activeElement]);
  const mapRef = useRef<Map | null>(null);
  const { time: playerTime } = useContext(playerContext);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const roleMaintainer = useRoleAccess(
    [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const roleForecaster = useRoleAccess(
    [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_FORECASTER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const { mutate: getObserved } = useGetObservedElement();
  const { mutate: getForecast } = useGetForecastElement();
  const { mutate: addDateLayer } = useAddPointDateElement();
  const [displayStatus, setDisplayStatus] = useState<string>('');
  const [file, setFile] = useState<IFileWithMeta | undefined>();
  const disableAdd = () => {
    switch (selectedElement) {
      case ElementsEnum.IMAGE:
        return !formValues.name || !formValues.media;
      case ElementsEnum.VIDEO:
        return !formValues.name || !formValues.media;
      case ElementsEnum.AUDIO:
        if (!formValues.name || !formValues.media) {
          return true;
        }
        break;
      case ElementsEnum.TEXT:
        if (!formValues.name || !formValues.value) {
          return true;
        }
        break;
      case ElementsEnum.MAP:
        if (
          !formValues.name ||
          !formValues.center ||
          !formValues.projection ||
          !formValues.mapType ||
          !formValues.bounds
        ) {
          return true;
        }
        break;
      case ElementsEnum.OBSERVED_WD: {
        return !formValues?.elements?.length;
      }
      case ElementsEnum.FORECAST_WD: {
        return !formValues?.elements?.length;
      }
      default:
        return true;
    }
  };

  /****/
  const [mapStep, setMapStep] = useState<1 | 2 | 3>(1);
  const [forecastStep, setForecastStep] = useState<number>(0);
  const [observeStep, setObserveStep] = useState<number>(0);
  const [basic, setBasic] = useState<{ name: string; description?: string; groupDate: boolean }>({
    name: '',
    description: '',
    groupDate: true,
  });
  const [timestamp, setTimestamp] = useState<number>(0);
  /****/
  const createPoint = (
    data: ObservedWDElementDef[] | ForecastWDElementDef[],
    hasGroupDate?: boolean,
    hasGroupLocation?: boolean,
  ) => {
    let name = '';
    let groupLocation: ObservedWDStationDef | ForecastWDLocationDef | null;
    let groupDate: string;
    let pointGroup: string | undefined;
    if (selectedElement === ElementsEnum.FORECAST_WD) {
      const itemForDate = data[0] as ForecastWDElementDef;
      groupLocation = itemForDate.forecastWDSource.location;
      name = groupLocation.name;
      groupDate = dayjs
        .utc(itemForDate.forecastWDSource.utcDate)
        .tz(project.properties.timezone)
        .format('DD-MM-YYYY HH:mm:ss');
      pointGroup = itemForDate.pointWDGroupId;
    } else if (selectedElement === ElementsEnum.OBSERVED_WD) {
      const itemForDate = data[0] as ObservedWDElementDef;
      groupLocation = itemForDate.observedWDSource.station;
      name = groupLocation.fullName;
      groupDate = dayjs
        .utc(itemForDate.observedWDSource.utcDate)
        .tz(project.properties.timezone)
        .format('DD-MM-YYYY HH:mm:ss');
      pointGroup = itemForDate.pointWDGroupId;
    } else {
      groupDate = '';
      pointGroup = '';
    }
    const formDataDate = {
      name: groupDate,
      description: groupDate,
      value: groupDate,
    } as FormValues;
    const formDataLocation = {
      name: name,
      description: name,
      value: name,
    } as FormValues;
    data.forEach((item, index) => {
      item.timeControls[0] = new TimeControlDef(playerTime, duration);
      item.positionControl = new PositionControlDef(
        transformAbsoluteToPercent(50, activeAspectRatio, 'width', previewSize),
        transformAbsoluteToPercent(50, activeAspectRatio, 'height', previewSize),
        transformAbsoluteToPercent(50 * index, activeAspectRatio, 'width', previewSize),
        //Math.round(index / 5) * 50,
      );
      item.fontSize = 5;
      item.fontColor = 'rgba(255, 255, 255, 255)';
      item.strokeColor = 'rgba(255, 255, 255, 255)';
      item.strokeWidth = 0;
      item.boxDef = new BoxDef();
      item.fontFamily = DEFAULT_FONT_FAMILY;
      item.fontType = DEFAULT_FONT_TYPE;
      item.fontVariantId = DEFAULT_FONT_VARIANT_ID;
      item.positionControl.zindex = getMaxZindex(activeScene as string, project);
      item.parentGroups = [new LogicalGroupParent()];
      item.displayUnitOfMeasurement = true;
      if (selectedElement === ElementsEnum.OBSERVED_WD)
        dispatch(
          addOWDLayer({
            owdLayer: item as ObservedWDElementDef,
            activeScene,
          }),
        );
      if (selectedElement === ElementsEnum.FORECAST_WD) {
        dispatch(
          addForecastElements({
            forecastElements: item as ForecastWDElementDef,
            activeScene,
          }),
        );
      }
    });
    hasGroupLocation &&
      pointGroup &&
      addDateLayer({
        data: formDataLocation,
        aspectRatio: activeAspectRatio,
        previewSize,
        duration,
        zIndex: getMaxZindex(activeScene as string, project),
        groupId: pointGroup,
        activeScene,
        pointLabel: PointLabel.LOCATION,
      });
    hasGroupDate &&
      pointGroup &&
      addDateLayer({
        data: formDataDate,
        aspectRatio: activeAspectRatio,
        previewSize,
        duration,
        zIndex: getMaxZindex(activeScene as string, project),
        groupId: pointGroup,
        activeScene,
        pointLabel: PointLabel.DATE,
      });
  };
  useEffect(() => {
    !filterTab
      ? setSelectedElement(undefined)
      : setSelectedElement(Object.values(ElementsEnum).find((e) => e === filterTab));
  }, [filterTab, isOpen]);
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (!disableAdd()) {
          if (
            selectedElement === ElementsEnum.AUDIO ||
            selectedElement === ElementsEnum.IMAGE ||
            (selectedElement === ElementsEnum.VIDEO && !formValues.media)
          ) {
            return;
          }
          formRef.current &&
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, selectedElement]);
  const [fullModalVisible, setFullModalVisible] = useState(false);
  const dispatch = useDispatch();
  const active = useSelector<RootState>((state) => state.active) as ActiveDef;
  const formRef = useRef<HTMLFormElement>(null);
  const onElementClick = (elementType: ElementsEnum) => {
    setFormValues({
      name: `${String(elementType).charAt(0) + String(elementType).slice(1).toLowerCase()} ${nanoid(
        10,
      )}`,
      description: '',
    });
    formRef && formRef.current?.reset();
    setSelectedElement(elementType);
    setFullModalVisible(true);
  };
  const isActive = (element: ElementsEnum) => {
    if (element === selectedElement) return '#28334a';
  };
  const editInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const addFile = (res: any) => {
    res
      ? setFormValues({
          ...formValues,
          media: { ...res, name: formValues.name },
        })
      : setFormValues({
          ...formValues,
          media: undefined,
        });
  };
  const createOWDForm = (data: ObservedWDElementRequestDTO) => {
    setFormValues(data);
  };
  const createForecastForm = (location: ForecastLocationDef) => {
    setFormValues({ location, elements: [] });
  };
  const addImagePanel = (media: ImagePanelDefTemplate) => {
    const { width, height, x, y } = fitToCanvasHelper(media, activeAspectRatio, previewSize);
    const layer = new ImagePanelDef();
    layer.imagePanelDefTemplate = media;
    layer.id = v4();
    layer.name = media.name;
    layer.timeControls[0].endMS = duration;
    layer.positionControl.h = height;
    layer.positionControl.w = width;
    layer.positionControl.x = x;
    layer.positionControl.y = y;
    layer.parentGroups = [new LogicalGroupParent()];
    layer.positionControl.zindex = getMaxZindex(activeScene as string, project);
    layer.enabled = true;

    dispatch(
      addImageLayer({
        imageLayer: layer,
        activeScene: store.getState().active.activeScene,
      }),
    );
    onClose();
  };
  const addVideoPanel = (media: VideoPanelDefTemplate) => {
    const { width, height, x, y } = fitToCanvasHelper(media, activeAspectRatio, previewSize);
    media.file.baseAppUrl = process.env.REACT_APP_API_BASE_URL!;
    const layer = new VideoPanelDef();
    layer.videoPanelDefTemplate = media;
    layer.id = v4();
    layer.name = media.name;
    layer.timeControls[0].endMS = media.durationInMS;
    layer.positionControl.h = height;
    layer.positionControl.w = width;
    layer.positionControl.x = x;
    layer.positionControl.y = y;
    layer.parentGroups = [new LogicalGroupParent()];
    layer.positionControl.zindex = getMaxZindex(activeScene as string, project);
    layer.enabled = true;

    dispatch(
      addVideoLayer({
        videoLayer: layer,
        activeScene: store.getState().active.activeScene,
      }),
    );
    onClose();
  };
  const addAudioPanel = (media: AudioElementTemplate) => {
    const layer = new AudioElement();
    media.file.baseAppUrl = process.env.REACT_APP_API_BASE_URL!;
    layer.audioElementTemplate = media;
    layer.id = v4();
    layer.name = media.name;
    layer.parentGroups = [new LogicalGroupParent()];
    layer.timeControls[0].endMS = media.durationInMS;
    layer.enabled = true;

    dispatch(
      addAudioLayer({
        audioLayer: layer,
        activeScene: store.getState().active.activeScene,
      }),
    );
    onClose();
  };
  const textMutation = useMutation(addTextElement, {
    onSuccess: (data: TextPanelDef) => {
      const res: TextPanelDef = JSON.parse(JSON.stringify(data));
      const { x, y, w, h, zindex, rotation } = res.positionControl;
      res.positionControl = {
        x: transformAbsoluteToPercent(x, activeAspectRatio, 'width', previewSize),
        w: transformAbsoluteToPercent(w, activeAspectRatio, 'width', previewSize),
        y: transformAbsoluteToPercent(y, activeAspectRatio, 'height', previewSize),
        h: transformAbsoluteToPercent(h, activeAspectRatio, 'height', previewSize),
        zindex,
        rotation,
      };
      const time = [new TimeControlDef()];
      time[0].endMS = duration;
      res.timeControls = time;
      res.fontSize = 4;
      res.boxDef.background.color = 'rgba(255, 255, 255, 0)';
      res.boxDef.borderBottom = new BorderDef();
      res.boxDef.borderRight = new BorderDef();
      res.boxDef.borderTop = new BorderDef();
      res.boxDef.borderLeft = new BorderDef();
      res.fontColor = 'rgba(255, 255, 255, 255)';
      res.strokeColor = 'rgba(255, 255, 255, 255)';
      res.strokeWidth = 0;
      res.positionControl.zindex = getMaxZindex(activeScene as string, project);
      res.parentGroups = [new LogicalGroupParent()];
      res.enabled = true;
      res.richText = {
        type: 'paragraph',
        children: [
          {
            text: res.value,
            fontFamily: DEFAULT_FONT_FAMILY,
            fontType: DEFAULT_FONT_TYPE,
            fontVariantId: DEFAULT_FONT_VARIANT_ID,
          },
        ],
      };
      dispatch(
        addTextLayer({
          textLayer: res,
          activeScene: store.getState().active.activeScene,
        }),
      );
      const end = findMaxInArray(data.timeControls, 'endMS');
      end.endMS > active.activeDuration &&
        dispatch(
          setDuration({
            duration: end.endMS,
          }),
        );
      setFormValues({});
      onClose();
    },
  });
  const mapMutation = useMutation(addMapElement, {
    mutationKey: 'addMapElement',
    onSuccess: async (data: MapPanelDef) => {
      const res: MapPanelDef = JSON.parse(JSON.stringify(data));
      /***************************************************************** */
      const projectionCalc =
        formValues.projection === 'UTM'
          ? `EPSG:${32600 + utmZoneFromLonLat(formValues.center)}`
          : formValues.projection;
      const time = [new TimeControlDef()];
      time[0].endMS = duration;
      res.timeControls = time;
      res.mapPositionControl.longitude = formValues.center[0];
      res.mapPositionControl.latitude = formValues.center[1];
      res.mapPositionControl.zoom = 0;
      res.baseMapSetup.projection.name = projectionCalc;
      res.baseMapSetup.mapStyle.name = formValues.mapType;
      res.baseMapSetup.hidden = false;
      res.properties.projectionCenterLat = formValues.center[1];
      res.properties.projectionCenterLon = formValues.center[0];
      res.properties.boundingBox = JSON.stringify(fromBoundsToGeoBox(formValues.bounds));
      res.properties.boundingBoxMerc = JSON.stringify(formValues.transformedRawExt);
      res.properties.north = formValues.north;
      res.properties.resolution = formValues.resolution;
      res.positionControl.zindex = getMaxZindex(activeScene as string, project);
      res.parentGroups = [new LogicalGroupParent()];
      res.isNew = true;
      dispatch(setSyncSpace({ spaceId: res.wdSpace[0].id }));
      dispatch(
        addMapLayer({
          mapLayer: res,
          activeScene: store.getState().active.activeScene,
        }),
      );
      dispatch(
        setElement({
          activeElement: res.id,
          activeProp: 'mapPanels',
        }),
      );
      onClose();
    },
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    selectedElement === ElementsEnum.TEXT && textMutation.mutate(formValues);
    // @ts-ignore
    selectedElement === ElementsEnum.IMAGE && addImagePanel(formValues.media);
    selectedElement === ElementsEnum.VIDEO && addVideoPanel(formValues.media);
    selectedElement === ElementsEnum.AUDIO && addAudioPanel(formValues.media);
    if (selectedElement === ElementsEnum.MAP) {
      const mapTimeControl = [new TimeControlDef(0, duration)];
      const mapDefToSend = new MapPanelDef();
      mapDefToSend.name = formValues.name;
      mapDefToSend.description = formValues.description;
      mapDefToSend.timeControls = mapTimeControl;
      mapDefToSend.positionControl = new PositionControlDef(100, 100);
      mapDefToSend.positionControl.zindex = getMaxZindex(activeScene as string, project);
      mapDefToSend.mapPositionControl = new MapPositionControlDef(
        Number(formValues.center[0]),
        Number(formValues.center[1]),
      );
      mapDefToSend.enterpriseAccountId = project.enterpriseAccountId;
      mapDefToSend.wdSpace = [new WeatherDataSpaceDef()];
      mapDefToSend.wdSpace[0].mapTimeframeTextIndicator.positionControl.zindex = MAX_ZINDEX_VALUE;
      mapDefToSend.wdSpace[0].mapTimeframeTextIndicator.fontFamily = DEFAULT_FONT_FAMILY;
      mapDefToSend.wdSpace[0].mapTimeframeTextIndicator.fontType = DEFAULT_FONT_TYPE;
      mapDefToSend.wdSpace[0].mapTimeframeTextIndicator.fontVariantId = DEFAULT_FONT_VARIANT_ID;
      mapDefToSend.wdSpace[0].timeControls = mapTimeControl;
      const projections = getProjections({
        center: formValues.center,
        north: formValues.north,
      });
      let projString: string | undefined;
      let projName: string;
      if (formValues.projection === 'UTM') {
        const zoneNum = utmZoneFromLonLat(formValues.center);
        projString = getProjectionStringForUtm(zoneNum);
        projName = `UTM:${zoneNum}`;
      } else {
        const projFound = projections.find((p) => p.plainCode === formValues.projection);
        projString =
          projFound?.projectionString ||
          '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs';
        projName = projFound ? projFound.name : 'EPSG:3857';
      }
      /*************************************************************************************************** */

      const topLat = getLatitudeFromCenterToTopMap(mapRef.current!, formValues.center[1]);
      const isNorthHemisphere = Number(formValues.center[1]) > 0;
      const bottomLat = getLatitudeFromCenterToTopMap(mapRef.current!, formValues.center[1], true);
      let transformedRawExt = cloneDeep(formValues.transformedRawExt);

      /**
       * Calculate correct bounding box for NSPER manually
       * Open layers returns NaN for all values when it's zoomed out enough.
       */
      const isGVNSP = projString.includes('+proj=nsper');
      if (isGVNSP && !transformedRawExt[0]) {
        transformedRawExt[0] = -180;
        transformedRawExt[1] = -89.99;
        transformedRawExt[2] = 180;
        transformedRawExt[3] = 89.99;
      }

      if (isGVNSP) {
        mapDefToSend.customBackgroundImage = new CustomBackgroundImageDef();
        mapDefToSend.customBackgroundImage.fromColor = formValues.fromColor ?? 'black';
        mapDefToSend.customBackgroundImage.toColor = formValues.toColor ?? 'black';
        mapDefToSend.customBackgroundImage.width = formValues.ringWidth ?? 30;
      }

      if (!isGVNSP) {
        if (isNorthHemisphere) transformedRawExt[3] = topLat;
        else transformedRawExt[1] = bottomLat;

        transformedRawExt = extentMercBoundingBox(transformedRawExt);
      }

      const transformedExt = {
        upperLeft: { longitude: transformedRawExt[0], latitude: transformedRawExt[3] },
        bottomLeft: { longitude: transformedRawExt[0], latitude: transformedRawExt[1] },
        bottomRight: { longitude: transformedRawExt[2], latitude: transformedRawExt[1] },
        upperRight: { longitude: transformedRawExt[2], latitude: transformedRawExt[3] },
      };
      const baseMapToSend = new BaseMapDef();
      baseMapToSend.mapStyleId = formValues.mapStyleId;
      const mapStyleToSend = new MapStyleDef();
      mapStyleToSend.id = formValues.mapStyleId;
      mapStyleToSend.name = formValues.mapType;
      baseMapToSend.mapStyle = mapStyleToSend;
      baseMapToSend.mapStyle.name =
        formValues.mapType === 'SATELLITE' ? 'po-satellite' : formValues.mapType;
      baseMapToSend.boundingBox = transformedExt;
      baseMapToSend.projection = new SupportedProjection();
      /******************************************************************************** */
      baseMapToSend.projection.name = projName;
      baseMapToSend.projectionParams = projString;
      baseMapToSend.osmId = formValues.osmId;
      mapDefToSend.baseMapSetup = baseMapToSend;
      mapMutation.mutate(mapDefToSend);
    }
    if (selectedElement === ElementsEnum.OBSERVED_WD) {
      getObserved(formValues as ObservedWDElementRequestDTO, {
        onSuccess: (data, args: ObservedWDElementRequestDTO) => {
          createPoint(data, args.groupDate, args.groupLocation);
          onClose();
        },
      });
    }
    if (selectedElement === ElementsEnum.FORECAST_WD) {
      getForecast(formValues as ForecastElementRequestDTO, {
        onSuccess: (data, args: any) => {
          createPoint(data, args.groupDate, args.groupLocation);
          onClose();
        },
      });
    }
  };
  const wrapperRef = useRef(null);
  function useOutsideClick(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setAddElementModal(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideClick(wrapperRef);
  useEffect(() => {
    if (formValues.media) {
      formRef.current &&
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [formValues.media]);
  return (
    <>
      {!fullModalVisible && isOpen && (
        <div className={'add-element-drop-down'} ref={wrapperRef}>
          <Button
            label="Image"
            icon={<AiOutlineFileImage />}
            buttonType="link"
            style={{ backgroundColor: isActive(ElementsEnum.IMAGE) }}
            onClick={() => onElementClick(ElementsEnum.IMAGE)}
            disabled={!roleMaintainer}
          />
          <Button
            buttonType="link"
            label="Video"
            icon={<AiOutlineVideoCamera />}
            style={{ backgroundColor: isActive(ElementsEnum.VIDEO) }}
            onClick={() => onElementClick(ElementsEnum.VIDEO)}
            disabled={!roleMaintainer}
          />
          <Button
            label="Audio"
            icon={<AiOutlineAudio />}
            buttonType="link"
            style={{ backgroundColor: isActive(ElementsEnum.AUDIO) }}
            onClick={() => onElementClick(ElementsEnum.AUDIO)}
            disabled={!roleMaintainer}
          />
          <Button
            label="Text"
            icon={<BsChatText />}
            buttonType="link"
            style={{ backgroundColor: isActive(ElementsEnum.TEXT) }}
            onClick={() => onElementClick(ElementsEnum.TEXT)}
            disabled={!roleMaintainer}
          />
          <Button
            label="Map"
            icon={<BsMap />}
            buttonType="link"
            style={{ backgroundColor: isActive(ElementsEnum.MAP) }}
            onClick={() => onElementClick(ElementsEnum.MAP)}
            disabled={!roleForecaster}
          />
          <Button
            label="Observed"
            icon={<TbView360 />}
            buttonType="link"
            style={{
              backgroundColor: isActive(ElementsEnum.OBSERVED_WD),
            }}
            onClick={() => onElementClick(ElementsEnum.OBSERVED_WD)}
            disabled={!roleForecaster}
          />
          <Button
            label="Forecast"
            icon={<TbView360 />}
            buttonType="link"
            style={{
              backgroundColor: isActive(ElementsEnum.FORECAST_WD),
            }}
            onClick={() => onElementClick(ElementsEnum.FORECAST_WD)}
            disabled={!roleForecaster}
          />
        </div>
      )}
      <Modal
        isOpen={fullModalVisible}
        onClose={onClose}
        header={`Add ${selectedElement} element`}
        className={`add-element-modal-wrapper ${selectedElement}`}
        footer={
          <div className={'modal-footer'}>
            <div className={'footnote'}>
              {selectedElement &&
                [ElementsEnum.AUDIO, ElementsEnum.VIDEO, ElementsEnum.IMAGE].includes(
                  selectedElement,
                ) &&
                '*Element will be automatically added to multimedia repository'}
              {selectedElement &&
                [ElementsEnum.MAP, ElementsEnum.TEXT].includes(selectedElement) &&
                '*Element will be automatically added to template repository'}
            </div>
            <div className={'button-holder'}>
              <div className="w-full flex items-center mt-5 next-back-btn">
                {selectedElement && selectedElement === ElementsEnum.FORECAST_WD && (
                  <>
                    <Button
                      buttonType="secondary"
                      disabled={forecastStep === 0}
                      onClick={() => setForecastStep(0)}
                      label="Previous"
                    />

                    <Button
                      disabled={!basic.name || forecastStep === 1}
                      buttonType="primary"
                      onClick={() => setForecastStep(1)}
                      icon={<AiOutlineArrowRight />}
                      label="Next step"
                    />
                  </>
                )}
                {selectedElement && selectedElement === ElementsEnum.OBSERVED_WD && (
                  <>
                    <Button
                      type="button"
                      disabled={observeStep === 0}
                      onClick={() => setObserveStep(0)}
                      buttonType="secondary"
                      label="Previous"
                    />

                    <Button
                      type="button"
                      disabled={!basic.name || !timestamp || observeStep === 1}
                      onClick={() => setObserveStep(1)}
                      icon={<AiOutlineArrowRight />}
                      label="Next step"
                    />
                  </>
                )}

                {selectedElement && selectedElement === ElementsEnum.MAP && mapStep === 3 ? (
                  <>
                    <Button buttonType="secondary" onClick={() => setMapStep(2)} label="Previous" />
                    <Button disabled icon={<AiOutlineArrowRight />} label="Next step" />
                  </>
                ) : (
                  <div />
                )}
                {selectedElement && selectedElement === ElementsEnum.MAP && mapStep === 2 ? (
                  <Button buttonType="secondary" onClick={() => setMapStep(1)} label="Previous" />
                ) : (
                  <div />
                )}
                {selectedElement && selectedElement === ElementsEnum.MAP && mapStep === 2 && (
                  <Button
                    disabled={!formValues.mapType}
                    onClick={() => setMapStep(3)}
                    icon={<AiOutlineArrowRight />}
                    label="Next step"
                  />
                )}
                {selectedElement && selectedElement === ElementsEnum.MAP && mapStep === 1 && (
                  <>
                    <Button buttonType="secondary" disabled label="Previous" />
                    <Button
                      label="Next step"
                      disabled={!formValues.name || !formValues.projection || !formValues.center}
                      onClick={() => setMapStep(2)}
                      icon={<AiOutlineArrowRight />}
                    />
                  </>
                )}
              </div>

              {file ? (
                <Button
                  onClick={() => file.restart()}
                  disabled={displayStatus !== 'ready'}
                  label="Add Element"
                  icon={
                    displayStatus === 'getting_upload_params' ? (
                      <LoadingIndicator />
                    ) : (
                      <BsCheck2Square />
                    )
                  }
                ></Button>
              ) : (
                <Button
                  onClick={() =>
                    formRef.current &&
                    formRef.current.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true }),
                    )
                  }
                  disabled={
                    disableAdd() ||
                    mapMutation.isLoading ||
                    (formValues.center &&
                      (isNaN(formValues.center[0]) || isNaN(formValues.center[1])))
                  }
                  label="Add Element"
                  icon={mapMutation.isLoading ? <LoadingIndicator /> : <BsCheck2Square />}
                />
              )}
            </div>
          </div>
        }
      >
        <div className={'add-element-modal'}>
          <div className="add-element-modal-body">
            <div className={'form-wrapper'}>
              <form onSubmit={handleSubmit} ref={formRef}>
                {(selectedElement === 'AUDIO' ||
                  selectedElement === 'VIDEO' ||
                  selectedElement === 'IMAGE') && (
                  <AddMultimediaElement
                    editInput={editInput}
                    formValues={formValues}
                    onFile={(e) => addFile(e)}
                    type={selectedElement}
                    displayStatus={displayStatus}
                    setDisplayStatus={setDisplayStatus}
                    setFile={setFile}
                    file={file}
                  />
                )}
                {selectedElement === 'TEXT' && (
                  <AddTextElement editInput={editInput} formValues={formValues} />
                )}
                {selectedElement === 'MAP' && (
                  <AddMapElement
                    editInput={editInput}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    step={mapStep}
                    mapRef={mapRef}
                  />
                )}
                {selectedElement === ElementsEnum.OBSERVED_WD && (
                  <AddObservedData
                    basic={basic}
                    setBasic={setBasic}
                    timestamp={timestamp}
                    setTimestamp={setTimestamp}
                    step={observeStep}
                    onSelect={createOWDForm}
                    formValues={formValues as ObservedWDElementRequestDTO}
                    setFormValues={setFormValues}
                  />
                )}
                {selectedElement === ElementsEnum.FORECAST_WD && (
                  <AddForecastData
                    basic={basic}
                    setBasic={setBasic}
                    step={forecastStep}
                    onLocationSelect={createForecastForm}
                    setFormData={setFormValues}
                    formData={formValues as ForecastElementForm}
                    onElementAdd={setFormValues}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddElementModalDropdown;
