import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { useCreatePalette } from '../../../../core/api/palette/useCreatePalette';
import { useClearPaletteCache, usePaletteById } from '../../../../core/api/palette/usePalettebyId';
import { useUpdatePalette } from '../../../../core/api/palette/useUpdatePalette';
import { ColorPaletteDef } from '../../../../model/definitions/ColorPaletteDef';
import { CustomPaletteDTO } from '../../../../model/DTO/CustomPaletteDTO';
import { ColorPaletteParamTypeEnum } from '../../../../model/enums/ColorPaletteParamTypeEnum';
import { DataProductTypeEnum } from '../../../../model/enums/DataProductTypeEnum';
import {
  VisualisationTypeEnum,
  VisualisationTypeName,
} from '../../../../model/enums/VisualisationTypeEnum';
import { PaletteColor } from '../../../playground/properties/mapLayersProperties/PaletteColor';
import { PaletteColorPicker } from '../../../playground/properties/mapLayersProperties/PalettecolorPicker';
import { units } from '../../../playground/properties/mapLayersProperties/units';
import { ParameterMappingInterface, VisualizationTypeInterface } from '../../pages/Products';
import { CustomPaletteV2 } from './CustomPaletteV2';

interface CustomPaletteProp {
  onOk: (e: ColorPaletteDef) => void;
  paletteTypes: ColorPaletteParamTypeEnum[];
  paletteToEdit: string | undefined;
  setPaletteToEdit: (e: string | undefined) => void;
  source: string;
  type: string;
  parameterMappings: ParameterMappingInterface[];
  dataProductId?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
export const CustomPalette = ({
  onOk,
  paletteTypes,
  paletteToEdit,
  setPaletteToEdit,
  source,
  type,
  parameterMappings,
  dataProductId,
  isOpen,
  setIsOpen,
}: CustomPaletteProp) => {
  const { mutate: create } = useCreatePalette();
  const { mutate: update } = useUpdatePalette();
  const { data: editing, isSuccess: gotPalette } = usePaletteById(paletteToEdit);
  const clearPaletteCache = useClearPaletteCache();
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [noOfSteps, setNoOfSteps] = useState<number>(0);
  const [palette, setPalette] = useState<CustomPaletteDTO | ColorPaletteDef>(
    editing ?? new CustomPaletteDTO(),
  );
  const [visualisation, setVisualisation] = useState<VisualisationTypeEnum>(
    VisualisationTypeEnum.HEATMAP,
  );
  const [visualisationTypes, setVisualisationTypes] = useState<VisualisationTypeEnum[]>();
  const heatmap = visualisation === VisualisationTypeEnum.HEATMAP;

  const isEditingHeatmap =
    Boolean(paletteToEdit) && visualisation !== VisualisationTypeEnum.ISOLINE;
  const [isolineColor, setIsolineColor] = useState<string>('255,255,255,255');
  useEffect(() => {
    if (palette.paletteParamType && type === 'MODEL') {
      const parameter = parameterMappings.find(
        (paramType: ParameterMappingInterface) =>
          paramType.colorPaletteParameterGroup?.value === palette.paletteParamType,
      );
      if (parameter) {
        const visualizationTypes = parameter.visualizationTypes?.map(
          (visualizationType: VisualizationTypeInterface) => visualizationType.visualizationType,
        );
        const defaultVisualisation = parameter.visualizationTypes?.find(
          (visualizationType: VisualizationTypeInterface) => visualizationType.isDefault,
        );
        if (visualizationTypes) {
          setVisualisationTypes(visualizationTypes);
        }
        if (defaultVisualisation) {
          setVisualisation(defaultVisualisation.visualizationType);
        }
      }
    }
  }, [palette.paletteParamType]);
  useEffect(() => {
    function findPropertyValue(obj: any) {
      for (const key in obj) {
        return obj[key];
      }
    }
    const color = findPropertyValue(palette.colorStops.pallet);
    setIsolineColor(color);
    if (type === ColorPaletteParamTypeEnum.RADAR && !palette.paletteParamType && paletteTypes[0]) {
      setPalette({
        ...palette,
        paletteParamType: paletteTypes[0],
      });
    }
  }, [palette]);
  useEffect(() => {
    if (paletteToEdit) {
      setIsOpen(true);
    }
  }, [paletteToEdit]);
  useEffect(() => {
    if (palette.type === 'SYSTEM')
      setPalette({
        ...palette,
        type: 'CUSTOM',
        name: '',
      });
  }, [palette]);
  const setPaletteScale = useCallback(
    (newKey: number, oldKey: number | string) => {
      const o = cloneDeep(palette.colorStops.pallet);
      //@ts-ignore
      const keepColor = cloneDeep(o[oldKey]);
      o[newKey] = keepColor;
      //@ts-ignore
      delete o[oldKey];
      const palletNew = { ...o, [newKey]: keepColor };
      setPalette({
        ...palette,
        colorStops: {
          ...palette.colorStops,
          pallet: palletNew,
        },
      });
    },
    [palette],
  );
  useEffect(() => {
    setNoOfSteps(Object.keys(palette.colorStops.pallet).length);
  }, [palette.paletteParamType]);
  useEffect(() => {
    if (paletteToEdit && editing) {
      setMin(Math.min(...Object.keys(editing.colorStops?.pallet).map((key) => Number(key))));
      setMax(Math.max(...Object.keys(editing.colorStops?.pallet).map((key) => Number(key))));
      setNoOfSteps(Object.keys(editing.colorStops?.pallet).length);
      setPalette(editing);
    }
  }, [editing, gotPalette, paletteToEdit]);
  useEffect(() => {
    if (!isOpen) {
      setMin(0);
      setMax(0);
      setNoOfSteps(0);
      setPalette(new CustomPaletteDTO());
      setPaletteToEdit(undefined);
    }
  }, [isOpen]);
  const changeColorsNumber = (e: number) => {
    const newPalette = cloneDeep(palette);
    newPalette.colorStops.pallet = {};
    const palletNew: Record<number, string> = {};
    const step = Math.ceil(Number(((max - min) / (e - 1)).toFixed(2)) * 100) / 100;
    for (let i = 0; i < e - 1; i++) {
      const key = heatmap ? Math.ceil((min + i * step) * 100) / 100 : Math.round(min + i * step);
      palletNew[Number(key)] = '0, 0, 0, 255';
    }
    palletNew[Number(max)] = '0, 0, 0, 255';
    const ordered = Object.keys(palletNew)
      .sort((a, b) => Number(a) - Number(b))
      // @ts-ignore
      .reduce((obj, key: number) => {
        // @ts-ignore
        obj[key] = palletNew[key];
        return obj;
      }, {});
    setNoOfSteps(e);
    setPalette({
      ...newPalette,
      colorStops: {
        ...newPalette.colorStops,
        pallet: ordered,
        interval: heatmap ? 10 : null,
      },
    });
  };
  const setPaletteColor = (color: string, value: string) => {
    setPalette({
      ...palette,
      colorStops: {
        ...palette.colorStops,
        pallet: { ...palette.colorStops.pallet, [color]: value },
      },
    });
  };
  const editIsolineColor = (e: string) => {
    const pallet = palette.colorStops.pallet;
    for (const step in pallet) {
      pallet[step] = e;
    }
    setPalette(palette);
  };
  const onAddPalletValue = (value: string, colorValue: string) => {
    const numberValue = Number(value);
    if (!palette?.colorStops?.pallet) return;
    const sortedValues = Object.keys(palette?.colorStops?.pallet)
      .map(Number)
      .sort((a, b) => a - b);
    const currentIndex = sortedValues.findIndex((v) => v === Number(value));
    if (currentIndex === -1) return;
    let newValue;
    if (sortedValues[currentIndex + 1]) {
      newValue = (sortedValues[currentIndex] + sortedValues[currentIndex + 1]) / 2;
    } else if (sortedValues[currentIndex - 1]) {
      newValue = numberValue + (sortedValues[currentIndex] - sortedValues[currentIndex - 1]);
    } else {
      newValue = numberValue + 1;
    }
    let interpolatedColor = colorValue;
    const color1 = palette?.colorStops?.pallet[sortedValues[currentIndex]];
    const color2 = palette?.colorStops?.pallet[sortedValues[currentIndex + 1]];

    if (color1 && color2) {
      interpolatedColor = interpolateColor(color1, color2);
    }
    setPaletteColor(`${newValue}`, interpolatedColor);
  };

  const interpolateColor = (color1: string, color2: string) => {
    const rgba1 = color1.split(',').map(Number);
    const rgba2 = color2.split(',').map(Number);
    const interpolatedR = Math.round((rgba1[0] + rgba2[0]) / 2);
    const interpolatedG = Math.round((rgba1[1] + rgba2[1]) / 2);
    const interpolatedB = Math.round((rgba1[2] + rgba2[2]) / 2);
    const interpolatedA = (rgba1[3] + rgba2[3]) / 2;
    return `rgba(${interpolatedR},${interpolatedG},${interpolatedB},${interpolatedA})`;
  };
  const onDeletePalletValue = (color: any) => {
    const o = cloneDeep(palette.colorStops.pallet);
    delete o[color];
    setPalette({ ...palette, colorStops: { ...palette.colorStops, pallet: o } });
  };
  const renderUnits = () => {
    return <label>{palette.paletteParamType && units[palette.paletteParamType][0]}</label>;
  };
  const renderPickers = () => {
    const palColors = palette.colorStops.pallet;
    const elements = [];
    const sortedValues = Object.keys(palColors).sort((a, b) => Number(a) - Number(b));
    for (const color in palColors) {
      const currentIndex = sortedValues.findIndex((v) => Number(v) == Number(color));
      const hideAdd =
        Object.keys(palColors).length > 1
          ? Number(sortedValues[currentIndex]) + 0.01 < Number(sortedValues[currentIndex + 1])
          : true;
      elements.push(
        <PaletteColor
          key={Number(color)}
          color={color}
          colorValue={`rgba(${palColors[color]})`}
          setPaletteColor={setPaletteColor}
          setPaletteScale={setPaletteScale}
          onAddPalletValue={isEditingHeatmap ? onAddPalletValue : undefined}
          onDeletePalletValue={isEditingHeatmap ? onDeletePalletValue : undefined}
          paletteLength={Object.keys(palColors).length}
          hideAdd={hideAdd}
        />,
      );
    }
    return elements.sort((a, b) => Number(a.key) - Number(b.key));
  };
  const isolineRanges = () => {
    const palColors = palette.colorStops.pallet;
    const elements = [];
    for (const color in palColors) {
      elements.push(
        <Input
          key={Number(color)}
          value={color}
          onChange={(e) => setPaletteScale(Number(e.target.value), Number(color))}
        />,
      );
    }
    return elements.sort((a, b) => Number(a.key) - Number(b.key));
  };
  const editPalette = (key: keyof ColorPaletteDef, val: string) => {
    setPalette({ ...palette, [key]: val });
  };
  const createPalette = () => {
    paletteToEdit !== undefined && editing?.type !== 'SYSTEM'
      ? update(palette as ColorPaletteDef, {
          onSuccess: (data) => {
            onOk(data);
            setPaletteToEdit(undefined);
            setIsOpen(false);
            clearPaletteCache();
            toast.success('Successfully updated palette');
          },
        })
      : palette.paletteParamType &&
        create(
          {
            ...palette,
            paletteParamType: palette.paletteParamType,
            visualisationType: visualisation,
            type: 'CUSTOM',
            colorStops: {
              ...palette.colorStops,
              unit: (palette.paletteParamType && units[palette.paletteParamType][0]) ?? '',
            },
            source: source,
            dataProductId,
          },
          {
            onSuccess: (data) => {
              onOk(data);
              setPaletteToEdit(undefined);
              setIsOpen(false);
              clearPaletteCache();
              toast.success('Successfully added palette');
            },
          },
        );
  };
  return (
    <>
      {paletteTypes.length > 0 && (
        <Button
          type="primary"
          className={`${isOpen ? 'hidden' : 'flex items-center m-auto mt-4'}`}
          icon={<AiOutlinePlus />}
          onClick={() => setIsOpen(true)}
        >
          Add palette
        </Button>
      )}
      {isOpen && (
        <>
          <div className={`flex gap-5 justify-center ${paletteToEdit && 'mt-5'}`}>
            <Form.Item label="Name" style={{ width: '50%' }} required>
              <Input
                name="name"
                value={palette.name}
                onChange={(e) => editPalette('name', e.target.value)}
                type={'text'}
                className="border-[#D9D9D9] rounded-md"
              />
            </Form.Item>
            <Form.Item label="Description" style={{ width: '50%' }}>
              <TextArea
                name="description"
                value={palette.description}
                onChange={(e) => editPalette('description', e.target.value)}
                cols={2}
              />
            </Form.Item>
          </div>
          <div className="flex gap-5 justify-center">
            <Form.Item label="Parameter" style={{ width: '50%' }} required>
              {type === ColorPaletteParamTypeEnum.RADAR ? (
                <Input value={palette.paletteParamType} disabled={true} />
              ) : (
                <Select
                  value={palette.paletteParamType}
                  options={Object.values(paletteTypes).map((o) => {
                    return {
                      value: o,
                      label: o.replaceAll('_', ' '),
                    };
                  })}
                  onChange={(e) => editPalette('paletteParamType', e)}
                  disabled={isEditingHeatmap}
                />
              )}
            </Form.Item>
            <Form.Item label="Visualisation" style={{ width: '50%' }} required>
              {type === DataProductTypeEnum.MODEL ? (
                <Select
                  value={VisualisationTypeName[visualisation]}
                  options={visualisationTypes?.map((visualisationType) => {
                    return {
                      value: visualisationType,
                      label: VisualisationTypeName[visualisationType] as VisualisationTypeEnum,
                    };
                  })}
                  disabled={!palette.paletteParamType || isEditingHeatmap}
                  onChange={(e) => setVisualisation(e as VisualisationTypeEnum)}
                />
              ) : (
                <Input value={VisualisationTypeName[visualisation]} disabled />
              )}
            </Form.Item>
          </div>

          {heatmap ? (
            <CustomPaletteV2
              palette={palette}
              isEdit={isEditingHeatmap}
              min={min}
              max={max}
              setPalette={setPalette}
              setMin={setMin}
              setMax={setMax}
            />
          ) : (
            <>
              <div className="flex gap-5 justify-center">
                <Form.Item label="Min value" style={{ width: '50%' }} required>
                  <Input
                    value={min}
                    onChange={(e) => setMin(Number(e.target.value))}
                    type="number"
                    disabled={isEditingHeatmap}
                  />
                </Form.Item>
                <Form.Item label="Max value" style={{ width: '50%' }} required>
                  <Input
                    value={max}
                    onChange={(e) => setMax(Number(e.target.value))}
                    type="number"
                    disabled={isEditingHeatmap}
                  />
                </Form.Item>
              </div>

              <div className="flex gap-5 justify-center">
                <Form.Item label={'No of steps:'} style={{ width: '50%' }} required>
                  <Input
                    value={noOfSteps}
                    disabled={min === max || !palette.paletteParamType || isEditingHeatmap}
                    onChange={(e) => changeColorsNumber(Number(e.target.value))}
                    type="number"
                    min={0}
                  />
                </Form.Item>
                <Form.Item label="Unit" style={{ width: '50%' }}>
                  {renderUnits()}
                </Form.Item>
              </div>
              <div className="mb-5">Colors</div>
              <div className={`w-fit mx-auto`}>
                {visualisation === VisualisationTypeEnum.ISOLINE ? (
                  <>
                    <PaletteColorPicker
                      value={`rgba(${isolineColor})`}
                      key={isolineColor}
                      isPalette
                      onChange={editIsolineColor}
                      outline
                    />

                    <div className={'grid grid-cols-10 gap-2 mt-3'}>{isolineRanges()}</div>
                  </>
                ) : (
                  renderPickers()
                )}
              </div>
            </>
          )}
          <div className="flex justify-center gap-4 mt-5">
            <Button
              onClick={() => {
                setPaletteToEdit(undefined);
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="flex items-center"
              icon={!paletteToEdit && <AiOutlinePlus />}
              onClick={createPalette}
              disabled={
                !palette.name ||
                (!paletteToEdit && !palette.paletteParamType) ||
                (!heatmap && noOfSteps === 0) ||
                (heatmap && palette.setup?.scale.length === 0) ||
                (noOfSteps > 1 && min === max)
              }
            >
              {paletteToEdit ? 'Update palette' : 'Create palette'}
            </Button>
          </div>
        </>
      )}
    </>
  );
};
