/**Studio Panel and Canvas height constant */
// export const STUDIO_PANEL_HEIGHT = 100;

export const MAP_TILER_API_KEY = 'cnJbT4Cqv8Z32hDdg38c';

export const INITIATE_FRAMES_CHUNK = 10;

export const MIN_ZOOM_WD = 0;
export const MAX_ZOOM_WD = 3;
// Map picker
export const STUDIO_PANEL_HEIGHT = 350;

// Max full screen height

export const MAX_FULLSCREEN_HEIGHT = 720;

export const MAX_ZINDEX_VALUE = 1000;
// TODO: temporarily set to 50 for testing purposes
export const MAX_LAYERS_PER_MAP = 50;

export const NO_THUMBNAIL_URL =
  'https://media.istockphoto.com/vectors/no-thumbnail-image-vector-graphic-vector-id1147544806?k=20&m=1147544806&s=170667a&w=0&h=5rN3TBN7bwbhW_0WyTZ1wU_oW5Xhan2CNd-jlVVnwD0=';

// TODO: temporarily set to 1500 for testing purposes
export const MAX_FRAMES_PER_LAYER = 1500;
/**For 60 fps */
export const MIN_FRAME_DURATION = 1000 / 60;

export const DEFAULT_FONT_FAMILY = 'Arial';
export const DEFAULT_FONT_TYPE = 'Regular';
export const DEFAULT_FONT_VARIANT_ID = '652df005-06c6-4149-88d8-d0ce17e1b59e';

export const HELVETICA_FONT_FAMILY = 'Helvetica';
export const HELVETICA_FONT_TYPE = 'Regular';
export const HELVETICA_FONT_VARIANT_ID = 'c1635273-f0b0-4181-ab34-417fd3727914';
export const CURRENT_TIME_DATE_FORMAT = 'DD-MM-YYYY HH:mm';

export const EARTH_RADIUS = 3585000;

export const GVNSP_RING_WIDTH_MARKS = {
  30: 30,
  60: 60,
  90: 90,
  120: 120,
  150: 150,
  180: 180,
  210: 210,
  240: 240,
  270: 270,
  300: 300,
};

// Video assembly refetch interval
export const VIDEO_ASSEMLBY_PING_INTERVAL = 30000;

export const OCEAN_NSPER_COORDINATE = [-305910.19412356056, 2483874.8900401657];
export const SOUTH_POLE_NSPER_COORDINATE = [100950.73859379254, -2279284.266823472];
