import { chunk } from 'lodash';
import { Extent } from 'ol/extent';
import Layer from 'ol/layer/Layer.js';
import { FrameState } from 'ol/Map.js';
import WebGLLayerRenderer from 'ol/renderer/webgl/Layer.js';
import { create as createTransform } from 'ol/transform.js';
import ViewHint from 'ol/ViewHint';
import { DefaultUniform } from 'ol/webgl/Helper.js';

import { AnimationsEnum } from '../../../model/enums/AnimationsEnum';
import { PostProcessingTypeEnum } from '../../../model/enums/FilteringTypeEnum';
import { VisualisationTypeEnum } from '../../../model/enums/VisualisationTypeEnum';
import { WDLayerTypeEnum } from '../../../model/enums/WDLayerTypeEnum';
import { GlobalPlayerControl } from '../../../pages/playground/GlobalPlayerControl';
import { ModeEnum } from '../../ui/enums/ModeEnum';
import { ColorPaletteTexture } from '../ColorPaletteTexture';
import { bindAttribute, bindFramebuffer, bindTexture, createTexture } from '../helpers/util';
import { FrameLoadingResultData } from '../WeatherDataLoaderTypes';
import {
  CONTOURING_FRAG,
  DEFAULT_VERT,
  FILTER_EMBOSS_FRAG,
  HEATMAP_FRAG,
  RADAR_FRAG,
  WIND_DRAW_FRAG,
  WIND_DRAW_VERT,
  WIND_PREPROCESSING_HEATMAP_FRAG,
  WIND_PREPROCESSING_HEATMAP_VERT,
  WIND_PREPROCESSING_MASK_FRAG,
  WIND_PREPROCESSING_MASK_VERT,
  WIND_QUAD_VERT,
  WIND_SCREEN_FRAG,
  WIND_UPDATE_FRAG,
} from '../webgl/shaders/index';
import { WeatherDataGLLayer } from './WeatherDataGLLayer';
import WindWebGLHelper from './WindWebGLHelper';

export class WeatherDataGLLayerRenderer extends WebGLLayerRenderer<Layer> {
  private preparedProgram: WebGLProgram | null = null;
  private scaleMin: number;
  private scaleMax: number;
  private prevIsContouring?: boolean;

  /**
   * @param {import("../../layer/Layer.js").default} layer Layer.
   * @param {Options} options Options.
   */
  constructor(layer: Layer, options: any) {
    const uniforms = options.uniforms || {};
    const projectionMatrixTransform = createTransform();
    uniforms[DefaultUniform.PROJECTION_MATRIX] = projectionMatrixTransform;

    super(layer, {
      uniforms: uniforms,
      postProcesses: options.postProcesses,
    });
  }

  coordinatesQuadFloatBuffer: WebGLBuffer | null = null;
  setCoordinatesBuffer() {
    // provide texture coordinates for the rectangle.
    const gl = this.helper.getGL();
    if (!this.coordinatesQuadFloatBuffer) {
      this.coordinatesQuadFloatBuffer = gl.createBuffer();
    }
    gl.bindBuffer(gl.ARRAY_BUFFER, this.coordinatesQuadFloatBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.getWDLayer().coordsPositions.positions, gl.STATIC_DRAW);

    if (this.getWDLayer().wdLayer.layerSetup.visualisationType === VisualisationTypeEnum.PARTICLE) {
      this.windMaskTextureStarted = false;
      this.currentPreprocessedFrameId = '';
      this.resetWindEmptyTextures();
    }
  }

  getWDLayer() {
    return this.getLayer() as WeatherDataGLLayer;
  }

  shouldRecreateColorPalette() {
    return (
      !this.getWDLayer().colorPalette ||
      this.getWDLayer().colorPaletteName !=
        this.getWDLayer().wdLayer.layerSetup.colorPaletteDef?.name ||
      this.getWDLayer().colorPaletteInterpolate !=
        this.getWDLayer().wdLayer.layerSetup.interpolation ||
      this.getWDLayer().colorPaletteLastUpdate !=
        this.getWDLayer().wdLayer.layerSetup.colorPaletteDef?.updateTime
    );
  }

  previousTime = -1;

  setOpacityFromLayer() {
    const canvas = this.helper.getCanvas();

    // Calculating opacity for fade in/out transitions
    let fadeOpacity = 1;
    const timeControls = this.getWDLayer().wdLayer.timeControls[0];
    const sceneTimeOffset =
      this.getWDLayer().mode === ModeEnum.SEQUENCE ? 0 : this.getWDLayer().sceneStartMs;
    const currentTime = GlobalPlayerControl.getTime() - sceneTimeOffset;
    if (
      timeControls.inAnimationDef === AnimationsEnum.FADE_IN &&
      currentTime < timeControls.startMS + timeControls.inAnimationDuration
    ) {
      fadeOpacity = (currentTime - timeControls.startMS) / timeControls.inAnimationDuration;

      const finalTime = (timeControls.startMS ?? 0) + timeControls.inAnimationDuration;
      const remaining = finalTime - currentTime;
      fadeOpacity = Math.min(
        Math.max((100 - (remaining * 100) / timeControls.inAnimationDuration) / 100, 0),
        1,
      );
    }
    if (
      timeControls.outAnimationDef === AnimationsEnum.FADE_OUT &&
      currentTime > timeControls.endMS - timeControls.outAnimationDuration
    ) {
      let remaining = (timeControls.endMS ?? 0) - currentTime;
      remaining = (remaining * 100) / timeControls.outAnimationDuration / 100;
      fadeOpacity = Math.min(Math.max(remaining, 0), 1);
    }

    if (this.getWDLayer().wdLayer.layerSetup.visualisationType == VisualisationTypeEnum.PARTICLE) {
      const data = this.getWDLayer().getFirstFrame();

      if (!data.result) {
        canvas.style.opacity = '0';
      } else {
        canvas.style.opacity = String(this.getLayer().getOpacity() * fadeOpacity);
      }
    } else {
      canvas.style.opacity = String(this.getLayer().getOpacity() * fadeOpacity);
    }
  }

  /**
   * Render the layer.
   * @param {import("../../Map.js").FrameState} frameState Frame state.
   * @return {HTMLElement} The rendered element.
   */
  renderFrame(frameState: FrameState) {
    const gl = this.helper.getGL();

    const canvas = this.helper.getCanvas();
    this.preRender(gl, frameState);

    const size = frameState.size;
    const pixelRatio = frameState.pixelRatio;

    canvas.width = size[0] * pixelRatio;
    canvas.height = size[1] * pixelRatio;
    canvas.style.width = size[0] + 'px';
    canvas.style.height = size[1] + 'px';

    const isWind =
      this.getWDLayer().wdLayer.layerSetup.visualisationType === VisualisationTypeEnum.PARTICLE;

    this.setOpacityFromLayer();

    // Temporary blur to prevent artifacts - might be included again
    // if (this.getWDLayer().wdLayerType != WDLayerTypeEnum.radar && !isWind) {
    // canvas.style.filter = 'blur(3px)';
    // }

    const viewMoving =
      frameState.viewHints[ViewHint.ANIMATING] || frameState.viewHints[ViewHint.INTERACTING];

    // if (GlobalPlayerControl.getTime() != this.previousTime) {
    if (!viewMoving) {
      if (isWind) {
        this.drawWindFrameAsync(gl, size, canvas.width, canvas.height, canvas, frameState.extent);
      } else {
        // Handle transparency - premultiply alpha when loading textures
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
        gl.enable(gl.BLEND);
        gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

        this.drawFrameAsync(gl, size, canvas.width, canvas.height, canvas, frameState.extent);
      }
    } else {
      gl.clear(gl.COLOR_BUFFER_BIT);
    }
    // }

    // this.postRender(gl, frameState);
    return canvas;
  }

  prepareProgram(gl: WebGLRenderingContext) {
    gl.clear(gl.COLOR_BUFFER_BIT);

    if (this.preparedProgram) {
      gl.deleteProgram(this.preparedProgram);
    }

    // Create a vertex shader object
    const vertShader = gl.createShader(gl.VERTEX_SHADER)!;

    const isIsoline =
      this.getWDLayer().wdLayer.layerSetup.visualisationType === VisualisationTypeEnum.ISOLINE;

    // Attach vertex shader source code
    if (isIsoline) {
      // gl.shaderSource(vertShader, VERT_SHADER_CODE_ISOLINE);
    } else {
      gl.shaderSource(vertShader, DEFAULT_VERT);
    }

    // Compile the vertex shader
    gl.compileShader(vertShader);

    // Create fragment shader object
    const fragShader = gl.createShader(gl.FRAGMENT_SHADER)!;

    // Attach fragment shader source code
    if (isIsoline) {
      // gl.shaderSource(fragShader, FRAGMENT_SHADER_CODE_ISOLINE);
    } else {
      const contouringFilter = this.getWDLayer().wdLayer.isContouring;
      gl.shaderSource(
        fragShader,
        this.getWDLayer().wdLayerType == WDLayerTypeEnum.radar
          ? contouringFilter
            ? CONTOURING_FRAG
            : RADAR_FRAG
          : HEATMAP_FRAG,
      );
    }

    // Compile the fragmentt shader
    gl.compileShader(fragShader);

    // Create a shader program object to store
    // the combined shader program
    const program = gl.createProgram()!;

    // Attach a vertex shader
    gl.attachShader(program, vertShader);

    // Attach a fragment shader
    gl.attachShader(program, fragShader);

    // Link both the programs
    gl.linkProgram(program);

    // Use the combined shader program object
    gl.useProgram(program);

    this.preparedProgram = program;

    this.postProcessProgram = this.helper.getProgram(FILTER_EMBOSS_FRAG, WIND_QUAD_VERT);

    this.windQuadFloatBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.windQuadFloatBuffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]),
      gl.STATIC_DRAW,
    );

    this.framebuffer = gl.createFramebuffer()!;

    const emptyPixels = new Uint8Array(gl.canvas.width * gl.canvas.height * 4);
    this.screenTexture = createTexture(
      gl,
      gl.NEAREST,
      emptyPixels,
      gl.canvas.width,
      gl.canvas.height,
    );

    // bindFramebuffer(gl, this.framebuffer, this.screenTexture);
  }

  postProcessProgram: WebGLProgram;

  setScale() {
    // default for radar
    this.scaleMin = 0;
    this.scaleMax = 150;

    if (this.getWDLayer().wdLayerType == WDLayerTypeEnum.satellite) {
      this.scaleMax = 300.0;
    }

    if (this.getWDLayer().wdLayerType == WDLayerTypeEnum.model) {
      const [minVal, maxVal] = this.getWDLayer().getMinAndMaxChannelValues();
      this.scaleMin = minVal;
      this.scaleMax = maxVal;
    }

    if (this.getWDLayer().wdLayer.layerSetup.visualisationType === VisualisationTypeEnum.PARTICLE) {
      const pallet = this.getWDLayer().wdLayer.layerSetup.colorPaletteDef?.colorStops.pallet || {
        0: 0,
        50: 50,
      };
      const colorStops = Object.keys(pallet)
        .map(Number)
        .sort((a, b) => a - b);
      this.scaleMin = colorStops[0];
      this.scaleMax = colorStops[colorStops.length - 1];
    }
  }

  pressures: number[] = [];
  setPressures() {
    // if (this.getWDLayer().wdLayer.layerSetup.visualisationType === VisualisationTypeEnum.ISOLINE) {
    //   for (const [key, value] of Object.entries(
    //     this.getWDLayer().wdLayer.layerSetup.colorPaletteDef.colorStops.pallet,
    //   )) {
    //     const pressure = parseFloat(key);
    //     if (pressure < this.scaleMax && pressure > this.scaleMin) {
    //       this.pressures.push(pressure);
    //     }
    //   }
    // }
  }

  async drawFrameAsync(
    gl: WebGLRenderingContext,
    size: number[],
    canvasWidth: number,
    canvasHeight: number,
    canvas: HTMLCanvasElement,
    extent: Extent | null,
  ) {
    await this.getWDLayer().recalculateCoordinates(extent);

    const data = this.getWDLayer().getFirstFrame();
    if (!data.result || !data.result.data) {
      gl.clear(gl.COLOR_BUFFER_BIT);
      return;
    }
    const data2 = this.getWDLayer().getSecondFrame();
    const prevIsContouring = this.prevIsContouring !== undefined ? this.prevIsContouring : null;
    const currentIsContouring = this.getWDLayer().wdLayer.isContouring;
    const isRadar = this.getWDLayer().wdLayerType == WDLayerTypeEnum.radar;
    if (!this.preparedProgram || (isRadar && prevIsContouring !== currentIsContouring)) {
      if (isRadar) {
        this.prevIsContouring = currentIsContouring;
      }
      this.setScale();
      this.prepareProgram(gl);
      this.setPressures();
    }

    const img = data ? data.result.data?.image : null;
    if (!img) {
      return canvas;
    }

    if (!this.getWDLayer().coordsPositions || this.getWDLayer().coordsPositions?.loading) return;

    // const start = Date.now();

    const imgBlob = data.result.data.imageBlob!;

    const img2 = data2 ? data2.data?.image : null;

    const imgBlob2 = img2 ? data2.data?.imageBlob! : imgBlob;

    // const end = Date.now();
    // console.log(`IMAGE Execution time: ${end - start} ms`);
    if (this.shouldRecreateColorPalette()) {
      const generator = new ColorPaletteTexture(
        this.getWDLayer().wdLayer.layerSetup.colorPaletteDef,
        this.scaleMin,
        this.scaleMax,
        this.getWDLayer().wdLayer.layerSetup.interpolation,
      );
      this.getWDLayer().colorPaletteName =
        this.getWDLayer().wdLayer.layerSetup.colorPaletteDef?.name ?? '';
      this.getWDLayer().colorPaletteLastUpdate =
        this.getWDLayer().wdLayer.layerSetup.colorPaletteDef?.updateTime ?? 0;
      this.getWDLayer().colorPaletteInterpolate =
        this.getWDLayer().wdLayer.layerSetup.interpolation;
      this.getWDLayer().colorPalette = await generator.generateTexture();
    }

    const hasPostProcessing =
      this.getWDLayer().wdLayer.layerSetup.postprocessing == PostProcessingTypeEnum.EMBOSS;

    gl.useProgram(this.preparedProgram);
    if (hasPostProcessing) {
      bindFramebuffer(gl, this.framebuffer, this.screenTexture);
    } else {
      bindFramebuffer(gl, null);
    }
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    // const startDraw = Date.now();
    this.drawQuad(
      gl,
      this.preparedProgram!,
      canvasWidth,
      canvasHeight,
      imgBlob,
      imgBlob2,
      data.result.data,
      data2 && data2.data ? data2.data : data.result.data,
      this.getWDLayer().colorPalette!,
      data.elapsedTime,
      data.lastingTime,
    );

    if (hasPostProcessing) {
      this.drawPostProcessing();
    }

    // const endDraw = Date.now();
    // console.log(`DRAW Execution time: ${endDraw - startDraw} ms`);
    this.previousTime = GlobalPlayerControl.getTime();
  }

  drawPostProcessing() {
    const gl = this.helper.getGL();
    const program = this.postProcessProgram;
    gl.useProgram(program);
    bindFramebuffer(gl, null);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    // texture
    // resolution
    // emboss_fact
    const resolution = [gl.canvas.width, gl.canvas.height];
    const emboss_fact = this.getWDLayer().wdLayer.layerSetup.embossEffect;

    const uResLocation = gl.getUniformLocation(program, 'resolution');
    gl.uniform2fv(uResLocation, resolution);

    const embossLocation = gl.getUniformLocation(program, 'emboss_fact');
    gl.uniform1f(embossLocation, emboss_fact);

    const isSatelliteLoc = gl.getUniformLocation(program, 'u_is_satellite');
    gl.uniform1i(
      isSatelliteLoc,
      this.getWDLayer().wdLayerType == WDLayerTypeEnum.satellite ? 1 : 0,
    );

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, this.screenTexture);
    const textureLoc = gl.getUniformLocation(program, 'texture');
    gl.uniform1i(textureLoc, 0);

    // draw a single quad
    bindAttribute(gl, this.windQuadFloatBuffer, gl.getAttribLocation(program, 'a_pos'), 2);
    gl.drawArrays(gl.TRIANGLES, 0, 6);

    gl.disable(gl.BLEND);
  }

  async drawQuad(
    gl: WebGLRenderingContext,
    program: WebGLProgram,
    width: number,
    height: number,
    frameImage: HTMLImageElement,
    nextFrameImage: HTMLImageElement,
    frame: FrameLoadingResultData,
    nextFrame: FrameLoadingResultData,
    colorPalette: HTMLImageElement,
    elapsedTime: number,
    lastingTime: number,
  ) {
    const isIsoline =
      this.getWDLayer().wdLayer.layerSetup.visualisationType === VisualisationTypeEnum.ISOLINE;

    // frameCount / interval = percentage of current data frame
    const interval = lastingTime;
    const frameCount = elapsedTime;

    const uWindRes = [frameImage.width, frameImage.height];

    let valueChannel = 0;
    let uWindMin = frame.frameInfo.values.min_val_ch_1;
    let uWindMax = frame.frameInfo.values.max_val_ch_1;
    let uWindMinNext = nextFrame.frameInfo.values.min_val_ch_1;
    let uWindMaxNext = nextFrame.frameInfo.values.max_val_ch_1;

    if (frame.frameInfo.values.unit == 'WDirWSpeed') {
      uWindMin = frame.frameInfo.values.min_val_ch_2!;
      uWindMax = frame.frameInfo.values.max_val_ch_2!;
      uWindMinNext = nextFrame.frameInfo.values.min_val_ch_2!;
      uWindMaxNext = nextFrame.frameInfo.values.max_val_ch_2!;
      valueChannel = 1;
    }

    const resolution = [frameImage.width, frameImage.height];

    const dataResolutionX = this.getWDLayer().coordsPositions.width;
    const dataResolutionY = this.getWDLayer().coordsPositions.height;

    const numParticles = dataResolutionX * dataResolutionY;

    const dataResolution = [dataResolutionX, dataResolutionY];

    const scaleMin = this.scaleMin;
    const scaleMax = this.scaleMax;

    const valueChannelLoc = gl.getUniformLocation(program, 'u_value_channel');
    gl.uniform1i(valueChannelLoc, valueChannel);

    const uInterpolate = this.getWDLayer().shouldInterpolate() ? 1 : 0;

    const frameCountLocation = gl.getUniformLocation(program, 'frame_count');
    const intervalLocation = gl.getUniformLocation(program, 'interval');

    const uWindResLocation = gl.getUniformLocation(program, 'u_wind_res');
    const uWindMinLocation = gl.getUniformLocation(program, 'u_wind_min');
    const uWindMaxLocation = gl.getUniformLocation(program, 'u_wind_max');
    const uWindMinNextLocation = gl.getUniformLocation(program, 'u_wind_min_next');
    const uWindMaxNextLocation = gl.getUniformLocation(program, 'u_wind_max_next');

    const resolutionLocation = gl.getUniformLocation(program, 'resolution');
    const dataResolutionLocation = gl.getUniformLocation(program, 'dataResolution');

    const scaleMinLocation = gl.getUniformLocation(program, 'scale_min');
    const scaleMaxLocation = gl.getUniformLocation(program, 'scale_max');

    const uInterpolateLocation = gl.getUniformLocation(program, 'u_interpolate');

    if (isIsoline) {
      const isoValueLocation = gl.getUniformLocation(program, 'isoValue');
      gl.uniform1fv(isoValueLocation, this.pressures);

      // const valArrayLocation = gl.getUniformLocation(program, 'valarray');
      // gl.uniform1fv(valArrayLocation, new Float32Array(5));

      const lineWidthLocation = gl.getUniformLocation(program, 'lineWidth');
      gl.uniform1f(lineWidthLocation, 1);

      const smoothnessLocation = gl.getUniformLocation(program, 'smoothness');
      gl.uniform1f(smoothnessLocation, 0.002);
    }

    gl.uniform1f(frameCountLocation, frameCount);
    gl.uniform1f(intervalLocation, interval);

    gl.uniform2fv(uWindResLocation, uWindRes);
    gl.uniform1f(uWindMinLocation, uWindMin);
    gl.uniform1f(uWindMaxLocation, uWindMax);
    gl.uniform1f(uWindMinNextLocation, uWindMinNext);
    gl.uniform1f(uWindMaxNextLocation, uWindMaxNext);

    gl.uniform2fv(resolutionLocation, resolution);

    gl.uniform2fv(dataResolutionLocation, dataResolution);

    gl.uniform1f(scaleMinLocation, scaleMin);
    gl.uniform1f(scaleMaxLocation, scaleMax);

    gl.uniform1i(uInterpolateLocation, uInterpolate);

    gl.activeTexture(gl.TEXTURE0);
    const texture1 = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture1);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, frameImage);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    const uWindLocation = gl.getUniformLocation(program, 'u_wind');
    gl.uniform1i(uWindLocation, 0); // Set the texture unit to 1

    gl.activeTexture(gl.TEXTURE1);
    const texture2 = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture2);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, nextFrameImage);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    const uWindNextLocation = gl.getUniformLocation(program, 'u_wind_next');
    gl.uniform1i(uWindNextLocation, 1); // Set the texture unit to 1

    gl.activeTexture(gl.TEXTURE2);
    const texture3 = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture3);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, colorPalette);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    const uColorRampLocation = gl.getUniformLocation(program, 'u_color_ramp');
    gl.uniform1i(uColorRampLocation, 2); // Set the texture unit to 2

    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.coordinatesQuadFloatBuffer);
    // Set the view port
    gl.viewport(0, 0, width, height);
    const vertLoc = gl.getAttribLocation(program, 'a_texCoord');
    gl.enableVertexAttribArray(vertLoc);
    gl.vertexAttribPointer(vertLoc, 4, gl.FLOAT, false, 0, 0);
    gl.drawArrays(gl.TRIANGLES, 0, 6 * numParticles);
    gl.disableVertexAttribArray(vertLoc);
  }

  /**
   * Determine whether renderFrame should be called.
   * @param {import("../../Map.js").FrameState} frameState Frame state.
   * @return {boolean} Layer is ready to be rendered.
   */
  prepareFrameInternal(frameState: FrameState) {
    // const layer = this.getLayer();
    // const vectorSource = layer.getSource();
    // const viewState = frameState.viewState;
    // const viewNotMoving =
    //   !frameState.viewHints[ViewHint.ANIMATING] &&
    //   !frameState.viewHints[ViewHint.INTERACTING];
    // const extentChanged = !equals(this.previousExtent_, frameState.extent);
    // const sourceChanged = this.sourceRevision_ < vectorSource.getRevision();

    return true;
  }

  preprocessHeatmapProgram: WebGLProgram;
  preprocessMaskProgram: WebGLProgram;
  drawProgram: WebGLProgram;
  screenProgram: WebGLProgram;
  updateProgram: WebGLProgram;

  particleIndexBuffer: WebGLBuffer | null;
  windQuadFloatBuffer: WebGLBuffer | null;
  framebuffer: WebGLFramebuffer;

  backgroundTexture: WebGLTexture;
  screenTexture: WebGLTexture;

  particleStateTexture0: WebGLTexture;
  particleStateTexture1: WebGLTexture;
  colorRampTexture: WebGLTexture;

  preprocessedTexture: WebGLTexture;

  fadeOpacity = 0.996;
  speedFactor = 0.8;
  dropRate = 0.005;
  dropRateBump = 0.05;
  particleRes = 70;

  numParticles = 70 * 70;

  propertyValueMap(value: number, start1: number, stop1: number, start2: number, stop2: number) {
    return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
  }

  windInitializePrograms(gl: WebGLRenderingContext) {
    this.preprocessHeatmapProgram = this.helper.getProgram(
      WIND_PREPROCESSING_HEATMAP_FRAG,
      WIND_PREPROCESSING_HEATMAP_VERT,
    );
    this.preprocessMaskProgram = this.helper.getProgram(
      WIND_PREPROCESSING_MASK_FRAG,
      WIND_PREPROCESSING_MASK_VERT,
    );
    this.drawProgram = this.helper.getProgram(WIND_DRAW_FRAG, WIND_DRAW_VERT);
    this.screenProgram = this.helper.getProgram(WIND_SCREEN_FRAG, WIND_QUAD_VERT);
    this.updateProgram = this.helper.getProgram(WIND_UPDATE_FRAG, WIND_QUAD_VERT);

    this.windQuadFloatBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.windQuadFloatBuffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]),
      gl.STATIC_DRAW,
    );

    this.framebuffer = gl.createFramebuffer()!;

    this.resetWindEmptyTextures();

    const vertices = new Float32Array(this.numParticles);
    for (let i = 0; i < this.numParticles; i++) vertices[i] = i; //dot-vertex-id
    this.particleIndexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.particleIndexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

    const particleState = new Uint8Array(this.numParticles * 4);
    for (let i = 0; i < particleState.length; i++) {
      particleState[i] = Math.floor(Math.random() * 256); // randomize the initial particle positions
    }
    // textures to hold the particle state for the current and the next frame
    this.particleStateTexture0 = createTexture(
      gl,
      gl.NEAREST,
      particleState,
      this.particleRes,
      this.particleRes,
    );
    this.particleStateTexture1 = createTexture(
      gl,
      gl.NEAREST,
      particleState,
      this.particleRes,
      this.particleRes,
    );

    this.colorRampTexture = createTexture(gl, gl.LINEAR, this.getWDLayer().colorPalette!);

    // const settings = this.getWindProperties();

    // // const fo = settings.fadeOpacity > 1 ? 1 : settings.fadeOpacity;
    // const sf = settings.speedFactor > 1 ? 1 : settings.speedFactor;
    // const dr = settings.dropRate > 1 ? 1 : settings.dropRate;
    // const drb = settings.dropRateBump > 1 ? 1 : settings.dropRateBump;
    // const pn = settings.particleNumber > 50000 ? 50000 : settings.particleNumber;

    // this.fadeOpacity = 0.99; //this.propertyValueMap(fo, 0, 1, 0.96, 0.999);
    // this.speedFactor = this.propertyValueMap(sf, 0, 1, 0.45, 1);
    // this.dropRate = this.propertyValueMap(dr, 0, 1, 0, 0.1);
    // this.dropRateBump = this.propertyValueMap(drb, 0, 1, 0, 0.2);
    // this.particleRes = Math.floor(Math.sqrt(this.propertyValueMap(pn, 0, 50000, 0, 20000)));
    // this.numParticles = this.particleRes * this.particleRes;
  }

  resetWindEmptyTextures() {
    const gl = this.helper.getGL();
    const emptyPixels = new Uint8Array(gl.canvas.width * gl.canvas.height * 4);
    this.backgroundTexture = createTexture(
      gl,
      gl.NEAREST,
      emptyPixels,
      gl.canvas.width,
      gl.canvas.height,
    );
    this.screenTexture = createTexture(
      gl,
      gl.NEAREST,
      emptyPixels,
      gl.canvas.width,
      gl.canvas.height,
    );
    this.windMaskTexture = createTexture(
      gl,
      gl.NEAREST,
      emptyPixels,
      gl.canvas.width,
      gl.canvas.height,
    );
    this.preprocessedTexture = createTexture(
      gl,
      gl.NEAREST,
      emptyPixels,
      gl.canvas.width,
      gl.canvas.height,
    );
  }

  windMaskTexture: WebGLTexture;
  windMaskTextureStarted = false;
  windMaskTextureCompleted = false;
  createMaskTexture() {
    if (this.windMaskTextureStarted) return;
    if (!this.getWDLayer().coordsPositions || this.getWDLayer().coordsPositions.loading) return;

    this.windMaskTextureStarted = true;
    const program = this.preprocessMaskProgram;
    const gl = this.helper.getGL();

    bindFramebuffer(gl, this.framebuffer, this.windMaskTexture);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.useProgram(program);

    const dataResolutionX = this.getWDLayer().coordsPositions.width;
    const dataResolutionY = this.getWDLayer().coordsPositions.height;

    const poslist = this.getWDLayer().coordsPositions.positions as number[];

    // this is supposed to shrink the mask texture a tiny bit
    // to remove the artefacts that show up on the edge of the mask
    const positions = new Float32Array(
      chunk(chunk(chunk(poslist, 4), 6), dataResolutionX)
        .map((row) => {
          // removes 2 X coordinates
          return row.slice(0, -2);
        })
        // removes 1 Y coordinate
        .filter((row, idx) => idx + 1 !== dataResolutionY)
        .flat()
        .flat()
        .flat(),
    );

    const coordsBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, coordsBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

    // activate the coordinates buffer
    gl.bindBuffer(gl.ARRAY_BUFFER, coordsBuffer);

    const vertLoc = gl.getAttribLocation(program, 'a_texCoord');
    gl.enableVertexAttribArray(vertLoc);
    gl.vertexAttribPointer(vertLoc, 4, gl.FLOAT, false, 0, 0);
    // since we shrank the mask by 2 pixels horizontal and 1 vertical, subtract it from resolution
    gl.drawArrays(gl.TRIANGLES, 0, 6 * Math.ceil((dataResolutionX - 2) * (dataResolutionY - 1)));
    gl.disableVertexAttribArray(vertLoc);

    this.windMaskTextureCompleted = true;
  }

  getWindInitializedPrograms() {
    return (
      this.preprocessHeatmapProgram &&
      this.preprocessMaskProgram &&
      this.drawProgram &&
      this.screenProgram &&
      this.updateProgram
    );
  }

  // @ts-ignore
  async drawWindFrameAsync(gl, size, canvasWidth, canvasHeight, canvas, extent) {
    await this.getWDLayer().recalculateCoordinates(extent);
    if (!this.getWDLayer().coordsPositions || this.getWDLayer().coordsPositions?.loading) return;

    const particleDensity = this.getWDLayer().wdLayer.layerSetup.windParticles.particleDensity;
    if (particleDensity === 0) {
      this.particleRes = 30;
      this.numParticles = 30 * 30;
    }
    if (particleDensity === 1) {
      this.particleRes = 50;
      this.numParticles = 50 * 50; // for VR this is 5000 particles
    }
    if (particleDensity === 2) {
      this.particleRes = 60;
      this.numParticles = 60 * 60;
    }

    if (this.shouldRecreateColorPalette()) {
      this.setScale();
      const generator = new ColorPaletteTexture(
        this.getWDLayer().wdLayer.layerSetup.colorPaletteDef,
        this.scaleMin,
        this.scaleMax,
        this.getWDLayer().wdLayer.layerSetup.interpolation,
      );
      this.getWDLayer().colorPaletteName =
        this.getWDLayer().wdLayer.layerSetup.colorPaletteDef?.name ?? '';
      this.getWDLayer().colorPaletteLastUpdate =
        this.getWDLayer().wdLayer.layerSetup.colorPaletteDef?.updateTime ?? 0;
      this.getWDLayer().colorPaletteInterpolate =
        this.getWDLayer().wdLayer.layerSetup.interpolation;
      this.getWDLayer().colorPalette = await generator.generateTexture();
    }

    if (!this.isWindAnimationRunning) {
      this.runWindAnimation();
    }

    // done
    this.previousTime = GlobalPlayerControl.getTime();
  }

  isWindAnimationRunning = false;

  runWindAnimation() {
    this.isWindAnimationRunning = true;

    const fps = 30;
    const interval = 1000 / fps;
    let then = performance.now();

    // @ts-ignore
    const animate = async (timestamp) => {
      const delta = timestamp - then;

      if (delta > interval) {
        then = timestamp - (delta % interval);
      } else {
        // wait until next frame - for monitors over 30fps
        requestAnimationFrame(animate);
        return;
      }

      const gl = this.helper.getGL();

      const data = this.getWDLayer().getFirstFrame();
      if (!data.result || !data.result.data) {
        gl.clear(gl.COLOR_BUFFER_BIT);
        requestAnimationFrame(animate);
        return;
      }

      const img = data ? data.result.data?.image : null;
      if (!img) {
        requestAnimationFrame(animate);
        return;
      }
      const imgBlob = data.result.data.imageBlob!;

      // #1: do the setup - create all shaders and textures
      if (!this.getWindInitializedPrograms()) {
        this.windInitializePrograms(gl);
      }
      this.createMaskTexture();
      // #2: on actual draw - do the preprocessing step - once per dataframe
      this.windPreprocessStep(imgBlob, data.result.data?.frameId);

      await this.windDraw(gl, data.result.data);

      requestAnimationFrame(animate);
    };

    animate(then);
  }

  async windUpdateParticles(
    gl: WebGLRenderingContext,
    frame: FrameLoadingResultData,
    drawnScreen: HTMLCanvasElement,
  ) {
    bindFramebuffer(gl, this.framebuffer, this.particleStateTexture1);

    gl.viewport(0, 0, this.particleRes, this.particleRes);

    const program = this.updateProgram;
    gl.useProgram(program);

    bindAttribute(gl, this.windQuadFloatBuffer, gl.getAttribLocation(program, 'a_pos'), 2);

    const uWindLocation = gl.getUniformLocation(program, 'u_wind');
    gl.uniform1i(uWindLocation, 0); // Set the texture unit to 1

    const uParticlesLocation = gl.getUniformLocation(program, 'u_particles');
    gl.uniform1i(uParticlesLocation, 1); // Set the texture unit to 1

    // const numParticles = 150 * 150;
    const resolution = [drawnScreen.width, drawnScreen.height];

    const resolutionLocation = gl.getUniformLocation(program, 'resolution');
    gl.uniform2fv(resolutionLocation, resolution);

    const windRes = [drawnScreen.width, drawnScreen.height];
    const uWindResLocation = gl.getUniformLocation(program, 'u_wind_res');
    gl.uniform2fv(uWindResLocation, windRes);

    const particleResLocation = gl.getUniformLocation(program, 'u_particles_res');
    gl.uniform1f(particleResLocation, this.particleRes);

    const randSeedLocation = gl.getUniformLocation(program, 'u_rand_seed');
    gl.uniform1f(randSeedLocation, Math.random());

    const speedFactorLocation = gl.getUniformLocation(program, 'u_speed_factor');

    gl.uniform1f(speedFactorLocation, this.speedFactor);

    const dropRateLocation = gl.getUniformLocation(program, 'u_drop_rate');
    gl.uniform1f(dropRateLocation, this.dropRate);

    const dropRateBumpLocation = gl.getUniformLocation(program, 'u_drop_rate_bump');
    gl.uniform1f(dropRateBumpLocation, this.dropRateBump);

    const uWindMin = frame.frameInfo.values.min_val_ch_1;
    const uWindMin2 = frame.frameInfo.values.min_val_ch_2!;
    const uWindMax = frame.frameInfo.values.max_val_ch_1;
    const uWindMax2 = frame.frameInfo.values.max_val_ch_2!;
    const uWindMinLocation = gl.getUniformLocation(program, 'u_wind_min');
    const uWindMaxLocation = gl.getUniformLocation(program, 'u_wind_max');
    gl.uniform2fv(uWindMinLocation, [uWindMin, uWindMin2]);
    gl.uniform2fv(uWindMaxLocation, [uWindMax, uWindMax2]);

    gl.drawArrays(gl.TRIANGLES, 0, 6);

    // swap the particle state textures so the new one becomes the current one
    const temp = this.particleStateTexture0;
    this.particleStateTexture0 = this.particleStateTexture1;
    this.particleStateTexture1 = temp;
  }

  currentPreprocessedFrameId: string;
  windPreprocessStep(frameImage: HTMLImageElement, frameId: string) {
    if (this.currentPreprocessedFrameId === frameId) return;

    const program = this.preprocessHeatmapProgram;
    const gl = this.helper.getGL();
    gl.useProgram(program);

    bindFramebuffer(gl, this.framebuffer, this.preprocessedTexture);

    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    const uWindRes = [frameImage.width, frameImage.height];
    const resolution = [gl.canvas.width, gl.canvas.height];
    const dataResolutionX = this.getWDLayer().coordsPositions.width;
    const dataResolutionY = this.getWDLayer().coordsPositions.height;

    const uWindResLocation = gl.getUniformLocation(program, 'u_wind_res');
    const resolutionLocation = gl.getUniformLocation(program, 'resolution');
    const dataResolutionLocation = gl.getUniformLocation(program, 'dataResolution');

    gl.uniform2fv(uWindResLocation, uWindRes);
    gl.uniform2fv(resolutionLocation, resolution);
    gl.uniform2fv(dataResolutionLocation, [dataResolutionX, dataResolutionY]);

    gl.activeTexture(gl.TEXTURE0);
    const texture1 = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture1);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, frameImage);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    const uWindLocation = gl.getUniformLocation(program, 'u_wind');
    gl.uniform1i(uWindLocation, 0); // Set the texture unit to 1

    gl.bindBuffer(gl.ARRAY_BUFFER, this.coordinatesQuadFloatBuffer);

    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    const vertLoc = gl.getAttribLocation(program, 'a_texCoord');
    gl.enableVertexAttribArray(vertLoc);
    gl.vertexAttribPointer(vertLoc, 4, gl.FLOAT, false, 0, 0);
    gl.drawArrays(gl.TRIANGLES, 0, 6 * Math.ceil(dataResolutionX * dataResolutionY));
    gl.disableVertexAttribArray(vertLoc);

    this.currentPreprocessedFrameId = frameId;
  }

  async windDraw(gl: WebGLRenderingContext, frame: FrameLoadingResultData) {
    gl.disable(gl.DEPTH_TEST);
    gl.disable(gl.STENCIL_TEST);

    bindTexture(gl, this.preprocessedTexture, 0);
    bindTexture(gl, this.particleStateTexture0, 1);

    this.windDrawScreen(gl, frame);
    await this.windUpdateParticles(gl, frame, gl.canvas as HTMLCanvasElement);
  }

  windDrawScreen(gl: WebGLRenderingContext, frame: FrameLoadingResultData) {
    bindFramebuffer(gl, this.framebuffer, this.screenTexture);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    this.drawTexture(gl, this.backgroundTexture, this.fadeOpacity);
    this.drawParticles(gl, frame);

    bindFramebuffer(gl, null);
    // enable blending to support drawing on top of an existing background (e.g. a map)
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    this.drawTexture(gl, this.screenTexture, 1.0);
    gl.disable(gl.BLEND);

    const temp = this.backgroundTexture;
    this.backgroundTexture = this.screenTexture;
    this.screenTexture = temp;
  }

  drawTexture(gl: WebGLRenderingContext, texture: WebGLTexture, opacity: number) {
    const program = this.screenProgram;
    gl.useProgram(program);

    bindAttribute(gl, this.windQuadFloatBuffer, gl.getAttribLocation(program, 'a_pos'), 2);
    bindTexture(gl, texture, 2);

    gl.uniform1i(gl.getUniformLocation(program, 'u_screen'), 2);
    gl.uniform1f(gl.getUniformLocation(program, 'u_opacity'), opacity);

    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }

  drawParticles(gl: WebGLRenderingContext, frame: FrameLoadingResultData) {
    const canvas = gl.canvas;
    const program = this.drawProgram;
    gl.useProgram(program);

    const windTextureLocation = gl.getUniformLocation(program, 'u_wind');
    gl.uniform1i(windTextureLocation, 0);

    const particlesLocation = gl.getUniformLocation(program, 'u_particles');
    gl.uniform1i(particlesLocation, 1);

    bindAttribute(gl, this.particleIndexBuffer, gl.getAttribLocation(program, 'a_index'), 1);

    bindTexture(gl, this.colorRampTexture, 2);
    const uColorRampLocation = gl.getUniformLocation(program, 'u_color_ramp');
    gl.uniform1i(uColorRampLocation, 2);

    // const { particleSize } = this.getWindProperties();
    const particleSizeLocation = gl.getUniformLocation(program, 'u_particle_size');
    gl.uniform1f(particleSizeLocation, 2);

    const resolution = [canvas.width, canvas.height];

    const resolutionLocation = gl.getUniformLocation(program, 'resolution');
    gl.uniform2fv(resolutionLocation, resolution);

    const particleResLocation = gl.getUniformLocation(program, 'u_particles_res');
    gl.uniform1f(particleResLocation, this.particleRes);

    const uWindMin = frame.frameInfo.values.min_val_ch_1;
    const uWindMin2 = frame.frameInfo.values.min_val_ch_2!;
    const uWindMax = frame.frameInfo.values.max_val_ch_1;
    const uWindMax2 = frame.frameInfo.values.max_val_ch_2!;
    const uWindMinLocation = gl.getUniformLocation(program, 'u_wind_min');
    const uWindMaxLocation = gl.getUniformLocation(program, 'u_wind_max');
    gl.uniform2fv(uWindMinLocation, [uWindMin, uWindMin2]);
    gl.uniform2fv(uWindMaxLocation, [uWindMax, uWindMax2]);

    const scaleMinLocation = gl.getUniformLocation(program, 'u_scale_min');
    const scaleMaxLocation = gl.getUniformLocation(program, 'u_scale_max');
    gl.uniform1f(scaleMinLocation, this.scaleMin);
    gl.uniform1f(scaleMaxLocation, this.scaleMax);

    // Create the particleStateTexture0 - use another canvas
    gl.activeTexture(gl.TEXTURE3);
    gl.bindTexture(gl.TEXTURE_2D, this.windMaskTexture);
    const maskTextureLocation = gl.getUniformLocation(program, 'maskTexture');
    gl.uniform1i(maskTextureLocation, 3);

    gl.drawArrays(gl.POINTS, 0, this.numParticles);
  }

  /**
   * Determine whether renderFrame should be called.
   * @param {import("../../Map.js").FrameState} frameState Frame state.
   * @return {boolean} Layer is ready to be rendered.
   */
  prepareFrame(frameState: FrameState) {
    if (this.getLayer().getRenderSource()) {
      let incrementGroup = true;
      // let groupNumber = -1;
      let className;
      for (let i = 0, ii = frameState.layerStatesArray.length; i < ii; i++) {
        const layer = frameState.layerStatesArray[i].layer;
        // eslint-disable-next-line testing-library/render-result-naming-convention
        const renderer = layer.getRenderer();
        if (!(renderer instanceof WebGLLayerRenderer)) {
          incrementGroup = true;
          continue;
        }
        const layerClassName = layer.getClassName();
        if (incrementGroup || layerClassName !== className) {
          // groupNumber += 1;
          incrementGroup = false;
        }
        className = layerClassName;
        if (renderer === this) {
          break;
        }
      }

      const canvasCacheKey = 'map/' + frameState.mapId + '/group/' + className;

      if (!this.helper || !this.helper.canvasCacheKeyMatches(canvasCacheKey)) {
        this.removeHelper();

        // @ts-ignore
        this.helper = new WindWebGLHelper({
          // @ts-ignore
          postProcesses: this.postProcesses_,
          // @ts-ignore
          uniforms: this.uniforms_,
          canvasCacheKey: canvasCacheKey,
        });

        if (className) {
          this.helper.getCanvas().className = className;
        }

        this.afterHelperCreated();
      }
    }

    return this.prepareFrameInternal(frameState);
  }

  compileShader(gl: WebGLRenderingContext, source: string, type: number) {
    const shader = gl.createShader(type)!;
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    return shader;
  }

  getProgram(gl: WebGLRenderingContext, fragmentShaderSource: string, vertexShaderSource: string) {
    const fragmentShader = this.compileShader(gl, fragmentShaderSource, gl.FRAGMENT_SHADER)!;

    const vertexShader = this.compileShader(gl, vertexShaderSource, gl.VERTEX_SHADER)!;

    const program = gl.createProgram()!;
    gl.attachShader(program, fragmentShader);
    gl.attachShader(program, vertexShader);
    gl.linkProgram(program);

    if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
      const message = `Fragment shader compilation failed: ${gl.getShaderInfoLog(fragmentShader)}`;
      throw new Error(message);
    }
    gl.deleteShader(fragmentShader);

    if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
      const message = `Vertex shader compilation failed: ${gl.getShaderInfoLog(vertexShader)}`;
      throw new Error(message);
    }
    gl.deleteShader(vertexShader);

    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      const message = `GL program linking failed: ${gl.getShaderInfoLog(vertexShader)}`;
      throw new Error(message);
    }

    return program;
  }
}
