import { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Modal, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { BsCloudSun } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';

import Chart from '../atoms/Chart';
import { useGetDataByParameterType } from '../hooks/useGetDataByParameterType';
import { useGetFiles } from '../hooks/useGetFiles';
import { useGetFilesPerHour } from '../hooks/useGetFilesPerHour';
import { useGetMonitoring } from '../hooks/useGetMonitoring';
import { useGetParameters } from '../hooks/useGetParameters';

const { RangePicker } = DatePicker;

interface TypeData {
  valueId: number;
  time: string;
  leftLon: number;
  rightLon: number;
  lowerLat: number;
  upperLat: number;
}

interface Parameter {
  id: string;
  name: string;
  lastCreatedAt: string;
  timeDiff: string;
}

function Monitoring() {
  const startDate = dayjs().subtract(5, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
  const endDate = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const { id } = useParams();
  const { data: monitoring } = useGetMonitoring(id);
  const [dataSeries, setDataSeries] = useState<ApexAxisChartSeries>([]);
  const [dataSeriesForHour, setDataSeriesForHour] = useState<ApexAxisChartSeries>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState<[string | null, string | null]>([startDate, endDate]);
  const [filesDateRange, setFilesDateRange] = useState<[string | null, string | null]>([
    startDate,
    endDate,
  ]);
  const [filesPerHourDateRange, setFilesPerHourDateRange] = useState<
    [string | null, string | null]
  >([startDate, endDate]);
  const [activeItem, setActiveItem] = useState<string>('');
  const [loadingChart, setLoadingChart] = useState(true);
  const { data: files } = useGetFiles(filesDateRange, id);
  const { data: filesPerHour } = useGetFilesPerHour(filesPerHourDateRange, id);
  const { data: parameters, isLoading: loadingParameters } = useGetParameters(id);
  const { data: typeData, isLoading: loadingTypeData } = useGetDataByParameterType(
    dateRange,
    activeItem,
    id,
  );
  const [sortTypeData, setSortTypeData] = useState<TypeData[]>();
  const options = {
    chart: {
      animations: {
        enabled: false,
      },
    },
    yaxis: [
      {
        opposite: true,
      },
    ],
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: 'No data',
    },
  };

  function compareDates(dateA: string, dateB: string, descending: boolean): number {
    const timestampA = new Date(dateA).getTime();
    const timestampB = new Date(dateB).getTime();
    if (descending) {
      return timestampB - timestampA;
    } else {
      return timestampA - timestampB;
    }
  }

  useEffect(() => {
    if (activeItem) {
      setDateRange([startDate, endDate]);
    }
  }, [activeItem]);

  useEffect(() => {
    const dataSeries = [];
    if (files) {
      dataSeries.push({
        name: 'Files',
        data: files
          ?.map((file: { date: string; fileCount: number }) => {
            return {
              x: file.date,
              y: file.fileCount,
            };
          })
          .sort((a: { x: string; y: number }, b: { x: string; y: number }) => {
            return compareDates(a.x, b.x, false);
          }),
      });
      setLoadingChart(false);
    }
    setDataSeries(dataSeries);
  }, [files]);

  useEffect(() => {
    const dataSeriesForHour = [];
    if (filesPerHour) {
      dataSeriesForHour.push({
        name: 'Files',
        data: filesPerHour
          ?.map((file: { hour: string; fileCount: number }) => {
            return {
              x: file.hour.toString(),
              y: file.fileCount,
            };
          })
          .sort((a: { x: string; y: number }, b: { x: string; y: number }) => {
            return Number(a.x) - Number(b.x);
          }),
      });
      setLoadingChart(false);
    }
    setDataSeriesForHour(dataSeriesForHour);
  }, [filesPerHour]);

  useEffect(() => {
    if (typeData) {
      const sortTypeData = typeData.sort((a: TypeData, b: TypeData) => {
        return compareDates(a.time, b.time, true);
      });
      setSortTypeData(sortTypeData);
    }
  }, [typeData]);
  const showStatus = (status: string) => {
    switch (status) {
      case 'FAILED':
        return 'error';
      case 'WARNING':
        return 'warning';
      case 'PENDING':
        return 'info';
      case 'ACTIVE':
        return 'success';
    }
  };

  const handleParameter = (id: string) => {
    setIsOpen(true);
    setActiveItem(id);
  };

  const handleClose = () => {
    setActiveItem('');
    setDateRange([null, null]);
    setIsOpen(!isOpen);
  };

  const handleCalendarChange = (
    values: any,
    setDateRange: (value: [string | null, string | null]) => void,
  ) => {
    if (values) {
      if (values[0] && values[1]) {
        setDateRange([
          values[0].format('YYYY-MM-DD HH:mm:ss'),
          values[1].format('YYYY-MM-DD HH:mm:ss'),
        ]);
      } else if (values[0]) {
        setDateRange([values[0].format('YYYY-MM-DD HH:mm:ss'), null]);
      } else {
        setDateRange([null, null]);
      }
    } else {
      setDateRange([null, null]);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Last received data',
      render: (item: Parameter) => (
        <span>
          {moment(item.lastCreatedAt).format('DD-MM-YYYY H:mm:ss')} ({item.timeDiff})
        </span>
      ),
    },
    {
      title: '',
      render: (item: Parameter) => {
        return <Button onClick={() => handleParameter(item.id)}>Show more info</Button>;
      },
    },
  ];

  const typeColumns = [
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (time: string) => <span>{moment(time).format('DD-MM-YYYY H:mm:ss')}</span>,
    },
    {
      title: 'Left Longitude',
      dataIndex: 'leftLon',
      key: 'leftLon',
    },
    {
      title: 'Right Longitude',
      dataIndex: 'rightLon',
      key: 'rightLon',
    },
    {
      title: 'Lower Latitude',
      dataIndex: 'lowerLat',
      key: 'lowerLat',
    },
    {
      title: 'Upper Latitude',
      dataIndex: 'upperLat',
      key: 'upperLat',
    },
  ];

  const items = [
    {
      key: '1',
      label: (
        <div className="flex items-center gap-1 text-lg">
          <AiOutlineFileText />
          <span>File Level Monitoring</span>
        </div>
      ),
      children: (
        <div className="flex gap-5">
          <Chart
            title="Files per day"
            dateRange={filesDateRange}
            options={options}
            dataSeries={dataSeries}
            isLoading={loadingChart}
            handleCalendarChange={(values) => handleCalendarChange(values, setFilesDateRange)}
            setDateRange={setFilesDateRange}
          />
          <Chart
            title="Frequency of files per hour"
            dateRange={filesPerHourDateRange}
            options={options}
            dataSeries={dataSeriesForHour}
            isLoading={loadingChart}
            handleCalendarChange={(values) =>
              handleCalendarChange(values, setFilesPerHourDateRange)
            }
            setDateRange={setFilesPerHourDateRange}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className="flex items-center gap-1 text-lg">
          <BsCloudSun />
          <span>Parameter Level Monitoring</span>
        </div>
      ),
      children: (
        <Table
          rowKey="name"
          dataSource={parameters?.sort((a: Parameter, b: Parameter) =>
            compareDates(a.lastCreatedAt, b.lastCreatedAt, true),
          )}
          columns={columns}
          loading={loadingParameters}
          pagination={false}
        />
      ),
    },
  ];

  return (
    <>
      <Link to="/workspace/dataprovider/products">
        <Button className="!px-3 !h-8" size="small" icon={<ArrowLeftOutlined />}>
          Go back
        </Button>
      </Link>
      <h1>Monitoring</h1>
      {monitoring && (
        <div className="flex gap-5 mb-2">
          <div>
            <p className="text-[#06152b] opacity-75 text-lg font-semibold mb-1">ETL Status</p>
            <Alert
              message={`${monitoring.etlStatus.message}`}
              type={showStatus(monitoring.etlStatus.status)}
              showIcon
              className="mb-1"
            />
            {monitoring.timeOfLastEtlFile &&
              `Time of last ETL file: ${moment(monitoring.timeOfLastEtlFile).format(
                'DD-MM-YYYY HH:mm:ss',
              )}`}
          </div>
          <div>
            <p className="text-[#06152b] opacity-75 text-lg font-semibold mb-1">Ingestion Status</p>
            <Alert
              message={`${monitoring.ingestionStatus.message}`}
              type={showStatus(monitoring.ingestionStatus.status)}
              showIcon
              className="mb-1"
            />
            {monitoring.timeOfLastProcessedFile &&
              `Time of last ingested file: ${moment(monitoring.timeOfLastProcessedFile).format(
                'DD-MM-YYYY HH:mm:ss',
              )}`}
          </div>
        </div>
      )}
      <Tabs defaultActiveKey="1" items={items} />
      <Modal
        open={isOpen}
        onCancel={() => handleClose()}
        footer={null}
        destroyOnClose
        width="1000px"
        style={{ top: 50 }}
      >
        <label className="block mb-2 text-lg font-semibold">Date</label>
        <RangePicker
          showTime
          value={[
            dateRange[0] !== null ? dayjs(dateRange[0]) : null,
            dateRange[1] !== null ? dayjs(dateRange[1]) : null,
          ]}
          onChange={(values, formatString) => {
            if (values) {
              setDateRange(formatString);
            }
          }}
          className="mb-4"
          onCalendarChange={(values) => handleCalendarChange(values, setDateRange)}
        />
        {dateRange && (
          <Table
            bordered
            rowKey="valueId"
            dataSource={sortTypeData}
            columns={typeColumns}
            loading={loadingTypeData}
          />
        )}
      </Modal>
    </>
  );
}

export default Monitoring;
