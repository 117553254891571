import { useKeycloak } from '@react-keycloak/web';
import { Button, Form, Input, Select } from 'antd';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCreateEnterprisePalette } from '../../../core/api/palette/useCreateEnterprisePalette';
import { useClearPaletteCache, usePaletteById } from '../../../core/api/palette/usePalettebyId';
import { useUpdateEnterprisePalette } from '../../../core/api/palette/useUpdateEnterprisePalette';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { CustomPaletteDTO } from '../../../model/DTO/CustomPaletteDTO';
import {
  VisualisationTypeEnum,
  VisualisationTypeName,
} from '../../../model/enums/VisualisationTypeEnum';
import { useGetDataProductsPaletteTypes } from '../../dataprovider/hooks/useGetDataProductsPaletteTypes';
import { useGetProvider } from '../../dataprovider/hooks/useGetProvider';
import { CustomPaletteV2 } from '../../dataprovider/molecules/Palette/CustomPaletteV2';
import { ColorPaletteParameterGroupInterface } from '../../dataprovider/pages/Products';
import { PaletteColor } from '../../playground/properties/mapLayersProperties/PaletteColor';
import { PaletteColorPicker } from '../../playground/properties/mapLayersProperties/PalettecolorPicker';
import { units } from '../../playground/properties/mapLayersProperties/units';

export enum TypeEnum {
  MODEL = 'MODEL',
  SATELLITE = 'SATELLITE',
  RADAR = 'RADAR',
}

function EnterprisePalette() {
  const { keycloak } = useKeycloak();
  const { data: provider } = useGetProvider('enterprise', keycloak?.tokenParsed?.email);
  const enterpriseAccountId = provider?.id;
  const navigate = useNavigate();
  const [type, setType] = useState(TypeEnum.MODEL);
  const [palette, setPalette] = useState<CustomPaletteDTO | ColorPaletteDef>(
    new CustomPaletteDTO(),
  );
  const [visualisation, setVisualisation] = useState<VisualisationTypeEnum>(
    VisualisationTypeEnum.HEATMAP,
  );
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [noOfSteps, setNoOfSteps] = useState<number>(0);
  const [isolineColor, setIsolineColor] = useState<string>('255,255,255,255');
  const { id } = useParams();
  const paletteToEdit = id;
  const { data: paletteTypes, refetch } = useGetDataProductsPaletteTypes(type);
  const { mutate: create } = useCreateEnterprisePalette();
  const { mutate: update } = useUpdateEnterprisePalette();
  const { data: editing, isSuccess: gotPalette } = usePaletteById(paletteToEdit);
  const clearPaletteCache = useClearPaletteCache();
  const heatmap = visualisation === VisualisationTypeEnum.HEATMAP;
  const isEditingHeatmap =
    Boolean(paletteToEdit) && visualisation !== VisualisationTypeEnum.ISOLINE;
  useEffect(() => {
    refetch();
  }, [type]);

  useEffect(() => {
    function findPropertyValue(obj: any) {
      for (const key in obj) {
        return obj[key];
      }
    }
    const color = findPropertyValue(palette.colorStops.pallet);
    setIsolineColor(color);
    if (type === TypeEnum.RADAR && !palette.paletteParamType && paletteTypes[0]) {
      setPalette({
        ...palette,
        paletteParamType: paletteTypes[0],
      });
    }
  }, [palette]);

  const onAddPalletValue = (value: string, colorValue: string) => {
    const numberValue = Number(value);
    if (!palette?.colorStops?.pallet) return;
    const sortedValues = Object.keys(palette?.colorStops?.pallet)
      .map(Number)
      .sort((a, b) => a - b);
    const currentIndex = sortedValues.findIndex((v) => v === Number(value));
    if (currentIndex === -1) return;
    let newValue;
    if (sortedValues[currentIndex + 1]) {
      newValue = (sortedValues[currentIndex] + sortedValues[currentIndex + 1]) / 2;
    } else if (sortedValues[currentIndex - 1]) {
      newValue = numberValue + (sortedValues[currentIndex] - sortedValues[currentIndex - 1]);
    } else {
      newValue = numberValue + 1;
    }
    let interpolatedColor = colorValue;
    const color1 = palette?.colorStops?.pallet[sortedValues[currentIndex]];
    const color2 = palette?.colorStops?.pallet[sortedValues[currentIndex + 1]];

    if (color1 && color2) {
      interpolatedColor = interpolateColor(color1, color2);
    }
    setPaletteColor(`${newValue}`, interpolatedColor);
  };
  const interpolateColor = (color1: string, color2: string) => {
    const rgba1 = color1.split(',').map(Number);
    const rgba2 = color2.split(',').map(Number);
    const interpolatedR = Math.round((rgba1[0] + rgba2[0]) / 2);
    const interpolatedG = Math.round((rgba1[1] + rgba2[1]) / 2);
    const interpolatedB = Math.round((rgba1[2] + rgba2[2]) / 2);
    const interpolatedA = (rgba1[3] + rgba2[3]) / 2;
    return `rgba(${interpolatedR},${interpolatedG},${interpolatedB},${interpolatedA})`;
  };

  const editPalette = (key: keyof ColorPaletteDef, val: string) => {
    setPalette({ ...palette, [key]: val });
  };

  const editIsolineColor = (e: string) => {
    const pallet = palette.colorStops.pallet;
    for (const step in pallet) {
      pallet[step] = e;
    }
    setPalette(palette);
  };
  const setPaletteColor = (color: string, value: string) => {
    setPalette({
      ...palette,
      colorStops: {
        ...palette.colorStops,
        pallet: { ...palette.colorStops.pallet, [color]: value },
      },
    });
  };
  const onDeletePalletValue = (color: any) => {
    const o = cloneDeep(palette.colorStops.pallet);
    delete o[color];
    setPalette({ ...palette, colorStops: { ...palette.colorStops, pallet: o } });
  };
  const renderUnits = () => {
    return <label>{palette.paletteParamType && units[palette.paletteParamType][0]}</label>;
  };
  const renderPickers = () => {
    const palColors = palette.colorStops.pallet;
    const elements = [];
    const sortedValues = Object.keys(palColors).sort((a, b) => Number(a) - Number(b));
    for (const color in palColors) {
      const currentIndex = sortedValues.findIndex((v) => Number(v) == Number(color));
      const hideAdd =
        Object.keys(palColors).length > 1
          ? Number(sortedValues[currentIndex]) + 0.01 < Number(sortedValues[currentIndex + 1])
          : true;
      elements.push(
        <PaletteColor
          key={Number(color)}
          color={color}
          colorValue={`rgba(${palColors[color]})`}
          setPaletteColor={setPaletteColor}
          setPaletteScale={setPaletteScale}
          onAddPalletValue={isEditingHeatmap ? onAddPalletValue : undefined}
          onDeletePalletValue={isEditingHeatmap ? onDeletePalletValue : undefined}
          paletteLength={Object.keys(palColors).length}
          hideAdd={hideAdd}
        />,
      );
    }
    return elements?.sort((a, b) => Number(a.key) - Number(b.key));
  };
  const setPaletteScale = useCallback(
    (newKey: number, oldKey: number | string) => {
      const o = cloneDeep(palette.colorStops.pallet);
      //@ts-ignore
      const keepColor = cloneDeep(o[oldKey]);
      o[newKey] = keepColor;
      //@ts-ignore
      delete o[oldKey];
      const palletNew = { ...o, [newKey]: keepColor };
      setPalette({
        ...palette,
        colorStops: {
          ...palette.colorStops,
          pallet: palletNew,
        },
      });
    },
    [palette],
  );
  useEffect(() => {
    setNoOfSteps(Object.keys(palette.colorStops.pallet).length);
  }, [palette.paletteParamType]);
  useEffect(() => {
    if (palette.type === 'SYSTEM')
      setPalette({
        ...palette,
        type: 'CUSTOM',
        name: '',
      });
  }, [palette]);
  useEffect(() => {
    if (paletteToEdit && editing) {
      setMin(Math.min(...Object.keys(editing.colorStops?.pallet).map((key) => Number(key))));
      setMax(Math.max(...Object.keys(editing.colorStops?.pallet).map((key) => Number(key))));
      setNoOfSteps(Object.keys(editing.colorStops?.pallet).length);
      setPalette(editing);
    }
  }, [editing, gotPalette, paletteToEdit]);
  const isolineRanges = () => {
    const palColors = palette.colorStops.pallet;
    const elements = [];
    for (const color in palColors) {
      elements.push(
        <Input
          key={Number(color)}
          value={color}
          onChange={(e) => setPaletteScale(Number(e.target.value), Number(color))}
        />,
      );
    }
    return elements.sort((a, b) => Number(a.key) - Number(b.key));
  };

  const changeColorsNumber = (e: number) => {
    const newPalette = cloneDeep(palette);
    newPalette.colorStops.pallet = {};
    const palletNew: Record<number, string> = {};
    const step = Math.ceil(Number(((max - min) / (e - 1)).toFixed(2)) * 100) / 100;
    for (let i = 0; i < e - 1; i++) {
      const key = heatmap ? Math.ceil((min + i * step) * 100) / 100 : Math.round(min + i * step);
      palletNew[Number(key)] = '0, 0, 0, 255';
    }
    palletNew[Number(max)] = '0, 0, 0, 255';
    const ordered = Object.keys(palletNew)
      .sort((a, b) => Number(a) - Number(b))
      // @ts-ignore
      .reduce((obj, key: number) => {
        // @ts-ignore
        obj[key] = palletNew[key];
        return obj;
      }, {});
    setNoOfSteps(e);
    setPalette({
      ...newPalette,
      colorStops: {
        ...newPalette.colorStops,
        pallet: ordered,
        interval: heatmap ? 10 : null,
      },
    });
  };

  const createPalette = () => {
    paletteToEdit !== undefined && editing?.type !== 'SYSTEM'
      ? update(palette as ColorPaletteDef, {
          onSuccess: () => {
            clearPaletteCache();
            toast.success('Successfully updated palette');
            navigate('/workspace/enterprise-properties', { state: { tabKey: '5' } });
          },
        })
      : palette.paletteParamType &&
        create(
          {
            ...palette,
            paletteParamType: palette.paletteParamType,
            visualisationType: visualisation,
            type: 'ENTERPRISE',
            colorStops: {
              ...palette.colorStops,
              unit: (palette.paletteParamType && units[palette.paletteParamType][0]) ?? '',
            },
            enterpriseAccountId,
          },
          {
            onSuccess: () => {
              clearPaletteCache();
              toast.success('Successfully added palette');
              navigate('/workspace/enterprise-properties', { state: { tabKey: '5' } });
            },
          },
        );
  };

  return (
    <div>
      <div className={'flex ws-fixed-header'}>
        <div className={'ws-temp-title'}>
          <h1>Palette</h1>
        </div>
      </div>
      <div className="w-3/5">
        <div className={`flex gap-5 justify-center mb-4`}>
          <div className="w-full">
            <Form.Item label="Name" required>
              <Input
                name="name"
                value={palette.name}
                onChange={(e) => editPalette('name', e.target.value)}
                type={'text'}
                className="border-[#D9D9D9] rounded-md"
              />
            </Form.Item>
          </div>
          <div className="w-full">
            <Form.Item label="Description">
              <Input
                name="description"
                value={palette.description}
                onChange={(e) => editPalette('description', e.target.value)}
                type={'text'}
                className="border-[#D9D9D9] rounded-md"
              />
            </Form.Item>
          </div>
        </div>
        <div className={`flex gap-5 mb-4`}>
          {!paletteToEdit && (
            <div className="w-full">
              <Form.Item label="Type" required>
                <Select
                  value={type}
                  options={Object.entries(TypeEnum).map((o) => {
                    return {
                      value: o[0],
                      label: o[1],
                    };
                  })}
                  onChange={(e) => setType(e)}
                />
              </Form.Item>
            </div>
          )}

          <div className={`${paletteToEdit ? 'w-1/2 pr-2.5' : 'w-full'}`}>
            <Form.Item label="Parameter" required>
              <Select
                value={palette.paletteParamType}
                options={paletteTypes?.map((type: ColorPaletteParameterGroupInterface) => {
                  return {
                    value: type.value,
                    label: type.name,
                  };
                })}
                onChange={(e: string) => editPalette('paletteParamType', e)}
                disabled={!!paletteToEdit}
              />
            </Form.Item>
          </div>
        </div>
        <div className="w-1/2 pr-2.5">
          <Form.Item label="Visualization" required>
            <Select
              value={VisualisationTypeName[visualisation]}
              options={Object.values(VisualisationTypeEnum).map((visualisation: string) => {
                return {
                  value: visualisation,
                  label: VisualisationTypeName[visualisation as VisualisationTypeEnum],
                };
              })}
              onChange={(e) => setVisualisation(e as VisualisationTypeEnum)}
              disabled={!!paletteToEdit}
            />
          </Form.Item>
        </div>

        {heatmap ? (
          <CustomPaletteV2
            palette={palette}
            isEdit={false}
            min={min}
            max={max}
            setPalette={setPalette}
            setMin={setMin}
            setMax={setMax}
          />
        ) : (
          <>
            <div className="flex gap-5 justify-center">
              <Form.Item label="Min value" style={{ width: '50%' }} required>
                <Input
                  value={min}
                  onChange={(e) => setMin(Number(e.target.value))}
                  type="number"
                  disabled={isEditingHeatmap}
                />
              </Form.Item>
              <Form.Item label="Max value" style={{ width: '50%' }} required>
                <Input
                  value={max}
                  onChange={(e) => setMax(Number(e.target.value))}
                  type="number"
                  disabled={isEditingHeatmap}
                />
              </Form.Item>
            </div>

            <Form.Item label={'No of steps:'} style={{ width: '50%' }} required>
              <Input
                value={noOfSteps}
                disabled={min === max || !palette.paletteParamType || isEditingHeatmap}
                onChange={(e) => changeColorsNumber(Number(e.target.value))}
                type="number"
                min={0}
              />
            </Form.Item>
            <Form.Item label="Unit" style={{ width: '50%' }}>
              {renderUnits()}
            </Form.Item>
            <div className="mb-5">Colors</div>
            <div className={`w-fit mx-auto`}>
              {visualisation === VisualisationTypeEnum.ISOLINE ? (
                <>
                  <PaletteColorPicker
                    value={`rgba(${isolineColor})`}
                    key={isolineColor}
                    isPalette
                    onChange={editIsolineColor}
                    outline
                  />

                  <div className={'grid grid-cols-10 gap-2 mt-3'}>{isolineRanges()}</div>
                </>
              ) : (
                renderPickers()
              )}
            </div>
          </>
        )}

        <div className="flex justify-center gap-4 mt-5">
          <Button
            onClick={() => {
              navigate('/workspace/enterprise-properties', { state: { tabKey: '5' } });
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="flex items-center"
            icon={<AiOutlinePlus />}
            onClick={createPalette}
            disabled={
              !palette.name ||
              (!paletteToEdit && !palette.paletteParamType) ||
              (!heatmap && noOfSteps === 0) ||
              (heatmap && palette.setup?.scale.length === 0) ||
              (noOfSteps > 1 && min === max)
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EnterprisePalette;
