import { cloneDeep } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ElementsEnum } from '../../../../../core/ui/enums/ElementsEnum';
import { TimeControlDef } from '../../../../../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../../../../../model/enums/AnimationsEnum';
import { TimeInput } from '../../../../../molecules/timeInput/TimeInput';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../../store/slices/active-slice';
import {
  updateElementTime,
  updateMapLayerTime,
  updatePosterLayer,
} from '../../../../../store/slices/project-slice';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';
import { AnimationPicker } from './AnimationPicker';

interface Props {
  elementTimes: Array<TimeControlDef>;
  timeControl: TimeControlDef;
  active: ActiveDef;
  index: number;
  type: ElementsEnum;
  mapId?: string; // if is map Poster
  drawMapProps?: { mapId: string; drawElementId: string }; // if is drawingElement of mapPanel
  showAnimationPicker?: boolean;
  layerType?: 'observedDataLayers' | 'forecastDataLayers' | 'drawingElements';
}
const TimeControls: React.FC<Props> = ({
  timeControl,
  elementTimes,
  active,
  index,
  mapId,
  type,
  drawMapProps,
  showAnimationPicker = true,
  layerType,
}) => {
  const dispatch = useDispatch();
  const { startMS, endMS } = timeControl;
  const { activeElement, activeScene, activeFramerate, activePoster } = active;

  function onChange(e: number | AnimationsEnum, name: keyof TimeControlDef) {
    const newTimes: Array<TimeControlDef> = cloneDeep(elementTimes);
    console.log(mapId && !activePoster && layerType);
    console.log(mapId, activePoster, layerType);
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: `[${index}].${name}` }));
    newTimes[index] = { ...newTimes[index], [name]: e };
    if (newTimes[index].startMS <= newTimes[index].endMS) {
      if (mapId && activePoster)
        dispatch(
          updatePosterLayer({
            time: newTimes[0],
            activeScene,
            mapId,
            index,
          }),
        );
      if (mapId && !activePoster && layerType)
        dispatch(
          updateMapLayerTime({
            time: newTimes[0],
            activeElement,
            activeScene,
            mapId,
            layerType,
            index,
          }),
        );
      else
        dispatch(
          updateElementTime({
            time: newTimes[index].startMS <= newTimes[index].endMS ? newTimes : elementTimes,
            elementId: type === ElementsEnum.DRAWING ? drawMapProps!.drawElementId : activeElement,
            elementType: type,
            activeScene,
            drawMapId: drawMapProps?.mapId,
          }),
        );
    }
  }

  return (
    <GridWrapper>
      <GridItem
        label="Start:"
        item={
          <TimeInput
            time={startMS}
            relative={endMS}
            relation={'lower'}
            framerate={activeFramerate}
            onChange={(e) => onChange(e, 'startMS')}
            index={index}
            startEnd="start"
          />
        }
      />
      <GridItem
        label="End:"
        item={
          <TimeInput
            time={endMS}
            relative={startMS}
            relation={'higher'}
            framerate={activeFramerate}
            onChange={(e) => onChange(e, 'endMS')}
            index={index}
            startEnd="end"
          />
        }
      />
      {showAnimationPicker && (
        <AnimationPicker
          type={type}
          timeControls={timeControl}
          onChange={(value, name) => onChange(value, name)}
        />
      )}
    </GridWrapper>
  );
};

export default TimeControls;
