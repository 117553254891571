import { Checkbox, Col, Collapse, Input, InputNumber, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Label, Modal, Select } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import { getNewProject } from '../../../core/api/ProjectsAPI';
import { getAspectRatioFromExportFormat } from '../../../helpers/getAspectRatioFromExportFormat';
import { getFpsFromExportFormat } from '../../../helpers/getFpsFromExportFormat';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { C9ProjectCreateRequestDTO } from '../../../model/DTO/C9ProjectCreateRequestDTO';
import { Broadcast2550 } from '../../../model/enums/Broadcast2550';
import { Broadcast3060 } from '../../../model/enums/Broadcast3060';
import { FormatsEnum } from '../../../model/enums/FormatsEnum';
import { HistoricalTypeEnum } from '../../../model/enums/HistoricalTypeEnum';
import { SocialMedia2550 } from '../../../model/enums/SocialMedia2550';
import { SocialMedia3060 } from '../../../model/enums/SocialMedia3060';
import { TransitionsEnum } from '../../../model/enums/TransitionsEnum';
import { setActiveAspectRatio, setActiveFrameRate } from '../../../store/slices/active-slice';
import { setSceneDuration } from '../../../store/slices/duration-slice';
import Button from '../../marketplace-new/atoms/Button/Button';

const { Panel } = Collapse;

interface Props {
  opened: boolean;
  onClose: () => void;
  onConfirm: (newProject: C9ProjectDef) => void;
}

const NewProjectModal: React.FC<Props> = ({ opened, onClose, onConfirm }) => {
  const navigate = useNavigate();
  const [newProject, setNewProject] = useState<C9ProjectCreateRequestDTO>({
    name: `My project ${v4().slice(0, 8)}`,
    historicalType: HistoricalTypeEnum.RELATIVE,
    sceneTransition: TransitionsEnum.FADE_IN_FADE_OUT,
    sceneDuration: 10,
    numberOfScenes: 1,
    projectFormat: 'BROADCAST_25_50',
    exportFormat: 'HD_1920x1080_50i',
    sftpExports: [
      {
        name: '',
        type: 'SFTP',
        sftpUrl: '',
        sftpUsername: '',
        sftpPassword: '',
        folderPath: '',
      },
    ],
    renderQuality: {
      gammaCorrection: 0.8,
      saturation: 1,
    },
  });
  const dispatch = useDispatch();

  const mutation = useMutation(getNewProject, {
    onSuccess: (data: C9ProjectDef) => {
      const fps = getFpsFromExportFormat(newProject?.exportFormat!);
      const aspectRatio = getAspectRatioFromExportFormat(newProject?.exportFormat!);
      dispatch(setActiveFrameRate({ fps }));
      dispatch(setActiveAspectRatio({ aspectRatio }));
      dispatch(
        setSceneDuration({
          scene: data.sceneDefs[0]!.id,
          duration: Number(newProject.sceneDuration) * 1000,
        }),
      );
      onConfirm(data);
      navigate(`/studio?projectId=${data.id}`);
    },
  });

  function onChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    prop: keyof C9ProjectCreateRequestDTO,
  ) {
    if (prop === 'projectFormat') {
      let exportFormat = '';
      switch (e.target.value as keyof FormatsEnum) {
        case 'BROADCAST_25_50':
          exportFormat = 'HD_1920x1080_50i';
          break;
        case 'BROADCAST_30_60':
          exportFormat = 'HD_1920x1080_60i';
          break;
        case 'SOCIAL_MEDIA_25_50':
          exportFormat = 'INSTAGRAM_9_16_25p';
          break;
        case 'SOCIAL_MEDIA_30_60':
          exportFormat = 'INSTAGRAM_9_16_30p';
          break;
        case 'NONE':
          exportFormat = '';
      }
      setNewProject((p) => ({ ...p, [prop]: e.target.value, exportFormat }));
      return;
    }
    setNewProject((p) => ({ ...p, [prop]: e.target.value }));
  }

  function onNumberChange(e: number, prop: keyof C9ProjectCreateRequestDTO) {
    setNewProject((p) => ({ ...p, [prop]: e }));
  }

  function onHistoricalChange(e: CheckboxChangeEvent) {
    if (e.target.checked) {
      setNewProject((p) => ({ ...p, historicalType: HistoricalTypeEnum.HISTORICAL }));
    } else {
      setNewProject((p) => ({ ...p, historicalType: HistoricalTypeEnum.RELATIVE }));
    }
  }
  function onExportDefChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    prop: string,
  ) {
    const sftpExports = newProject.sftpExports;
    if (!sftpExports) return;
    sftpExports[0] = { ...sftpExports[0], [prop]: e.target.value };
    setNewProject((p) => ({ ...newProject, sftpExports: sftpExports }));
  }
  function hasError(prop?: keyof C9ProjectCreateRequestDTO) {
    if (prop) return !newProject[prop];
    return !(
      Boolean(newProject.name) &&
      Boolean(newProject.sceneTransition) &&
      Boolean(newProject.numberOfScenes) &&
      Boolean(newProject.exportFormat) &&
      Boolean(newProject.projectFormat) &&
      Boolean(newProject.sftpExports)
    );
  }
  function onCreate() {
    const project = { ...newProject };
    project.sceneDuration = project.sceneDuration! * 1000;
    mutation.mutate(project);
  }

  useEffect(() => {
    const project = { ...newProject };
    project.sceneDuration = project.sceneDuration! * 1000;
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Enter' && opened) {
        e.preventDefault();
        mutation.mutate(project);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [opened, newProject, mutation]);
  return (
    <Modal show={opened} onClose={onClose} style={{ zIndex: 999 }}>
      <div className="create-project-modal">
        <Modal.Header>
          <div className="add-project-modal-header">Create new project?</div>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'auto', maxHeight: '60vh' }}>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <span style={{ color: 'red' }}>*</span> Project name
              <Input
                onChange={(e) => onChange(e, 'name')}
                value={newProject.name}
                placeholder="Type here..."
              />
            </Col>
            <Col span={24}>
              <span style={{ color: 'red' }}>*</span> Scene duration
              <InputNumber
                style={{ width: '100%' }}
                addonAfter="sec"
                value={newProject.sceneDuration}
                onChange={(e) => onNumberChange(e ?? 0, 'sceneDuration')}
              />
            </Col>
            <Col span={24}>
              <span style={{ color: 'red' }}>*</span> Fixed time
              <Checkbox
                className="ml-2"
                checked={newProject.historicalType === HistoricalTypeEnum.HISTORICAL}
                onChange={onHistoricalChange}
              />
            </Col>
          </Row>
          <div className="mt-2">
            <div className="mb-2 block mt-2">
              <Label htmlFor="" value="Select format" />
            </div>
            <Select
              id="projectFormat"
              value={newProject.projectFormat}
              onChange={(e) => onChange(e, 'projectFormat')}
            >
              {Object.keys(FormatsEnum).map((k) => (
                <option key={k} value={k}>
                  {k}
                </option>
              ))}
            </Select>
          </div>
          {newProject.projectFormat !== '' && (
            <div className="mt-2">
              <div className="mb-2 block mt-2">
                <Label htmlFor="" value="Select export format" />
              </div>
              <Select
                id="exportFormat"
                value={newProject.exportFormat}
                onChange={(e) => onChange(e, 'exportFormat')}
              >
                {newProject.projectFormat === 'BROADCAST_25_50' &&
                  Object.keys(Broadcast2550).map((k) => (
                    <option key={k} value={k}>
                      {k}
                    </option>
                  ))}
                {newProject.projectFormat === 'BROADCAST_30_60' &&
                  Object.keys(Broadcast3060).map((k) => (
                    <option key={k} value={k}>
                      {k}
                    </option>
                  ))}
                {newProject.projectFormat === 'SOCIAL_MEDIA_25_50' &&
                  Object.keys(SocialMedia2550).map((k) => (
                    <option key={k} value={k}>
                      {k}
                    </option>
                  ))}
                {newProject.projectFormat === 'SOCIAL_MEDIA_30_60' &&
                  Object.keys(SocialMedia3060).map((k) => (
                    <option key={k} value={k}>
                      {k}
                    </option>
                  ))}
              </Select>
            </div>
          )}
          <Collapse style={{ marginTop: '21px' }}>
            <Panel header="SFTP Export" key="1">
              <Row gutter={[16, 12]}>
                <Col span={24}>
                  <span style={{ color: 'red' }}>*</span> Name
                  {newProject.sftpExports && (
                    <Input
                      onChange={(e) => onExportDefChange(e, 'name')}
                      value={newProject.sftpExports[0].name}
                      placeholder="Type here..."
                    />
                  )}
                </Col>
                <Col span={24}>
                  <span style={{ color: 'red' }}>*</span> URL
                  {newProject.sftpExports && (
                    <Input
                      onChange={(e) => onExportDefChange(e, 'sftpUrl')}
                      value={newProject.sftpExports[0].sftpUrl}
                      placeholder="Type here..."
                    />
                  )}
                </Col>
                <Col span={24}>
                  <span style={{ color: 'red' }}>*</span> Username
                  {newProject.sftpExports && (
                    <Input
                      onChange={(e) => onExportDefChange(e, 'sftpUsername')}
                      value={newProject.sftpExports[0].sftpUsername}
                      placeholder="Type here..."
                    />
                  )}
                </Col>
                <Col span={24}>
                  <span style={{ color: 'red' }}>*</span> Password
                  {newProject.sftpExports && (
                    <Input.Password
                      onChange={(e) => onExportDefChange(e, 'sftpPassword')}
                      value={newProject.sftpExports[0].sftpPassword}
                      placeholder="Type here..."
                    />
                  )}
                </Col>
                <Col span={24}>
                  <span style={{ color: 'red' }}>*</span> Folder path
                  {newProject.sftpExports && (
                    <Input
                      onChange={(e) => onExportDefChange(e, 'folderPath')}
                      value={newProject.sftpExports[0].folderPath}
                      placeholder="Type here..."
                    />
                  )}
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Modal.Body>
        <Modal.Footer>
          <div className="add-project-modal-footer">
            <Button buttonType="primary" disabled={hasError()} onClick={onCreate}>
              Create
            </Button>
            <Button buttonType="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default NewProjectModal;
