/* eslint-disable */
import { KeycloakTokenParsed } from 'keycloak-js';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import { SceneDef } from '../../model/definitions/SceneDef';
import { addFrames, setLoadingState, setMapLoading } from '../../store/slices/map-loading.slice';
import { WeatherDataLoader } from '../../core/weather-data/WeatherDataLoader';
import { SymbolLayerDef, SymbolSourceType } from '../../model/definitions/SymbolLayerDef';

export const loadFrames = (
  scenes: SceneDef[],
  dispatch: Dispatch<AnyAction>,
  idTokenParsed: KeycloakTokenParsed,
  layer?: RadarMapLayer | GribMapLayer | SatelliteMapLayer | SymbolLayerDef,
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const projectId = urlParams.get('projectId')!;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve) => {
    const layerFilter = (l: RadarMapLayer | GribMapLayer | SatelliteMapLayer) => {
      return (
        l.dataFrames.length > 0 &&
        l.enabled &&
        l.dataFrames.some((f) => WeatherDataLoader.getByFrameId(f.frameId, l.id) == undefined)
      );
    };

    const symbolLayerFilter = (l: SymbolLayerDef) => {
      if (l.symbolSource.sourceType === SymbolSourceType.PointData) {
        return (
          l.symbolSource.pointDataFrames &&
          l.symbolSource.pointDataFrames.length > 0 &&
          l.enabled &&
          l.symbolSource.pointDataFrames.some(
            (f) => WeatherDataLoader.getByFrameId(f.dateString, l.id) == undefined,
          )
        );
      } else {
        return (
          l.dataFrames.length > 0 &&
          l.enabled &&
          l.dataFrames.some((f) => WeatherDataLoader.getByFrameId(f.frameId, l.id) == undefined)
        );
      }
    };

    const shouldLoadFrames = layer
      ? true
      : scenes.some((sceneDef) =>
          (sceneDef?.mapPanels || []).some(
            (mapPanel) =>
              mapPanel.enabled &&
              (mapPanel.wdSpace[0].radarMapLayers.some(layerFilter) ||
                mapPanel.wdSpace[0].gribMapLayers.some(layerFilter) ||
                mapPanel.wdSpace[0].satelliteMapLayers.some(layerFilter) ||
                mapPanel.wdSpace[0].symbolLayers.some(symbolLayerFilter)),
          ),
        );
    if (!shouldLoadFrames) {
      resolve();
    } else {
      // TODO: reset loading state
      // WeatherDataLoader.resetLoadingState();
      dispatch(setMapLoading(true));

      const layersToLoad: any[] = [];

      scenes.forEach((sceneDef) => {
        sceneDef?.mapPanels.forEach((mapPanel) => {
          const layers = layer
            ? [layer]
            : mapPanel.enabled
            ? [
                ...mapPanel.wdSpace[0].radarMapLayers.filter(
                  (x) => x.dataFrames.length > 0 && x.enabled,
                ),
                ...mapPanel.wdSpace[0].gribMapLayers.filter(
                  (x) => x.dataFrames.length > 0 && x.enabled,
                ),
                ...mapPanel.wdSpace[0].satelliteMapLayers.filter(
                  (x) => x.dataFrames.length > 0 && x.enabled,
                ),
                ...mapPanel.wdSpace[0].symbolLayers.filter((x) => {
                  if (x.symbolSource.sourceType === SymbolSourceType.PointData) {
                    return (
                      x.symbolSource.pointDataFrames &&
                      x.symbolSource.pointDataFrames.length > 0 &&
                      x.enabled
                    );
                  }
                  return x.dataFrames.length > 0 && x.enabled;
                }),
              ]
            : [];

          if (layers.length > 0) {
            layersToLoad.push({
              layers,
              mapPanel,
              idTokenParsed,
              dispatch,
              sceneDefId: sceneDef.id,
            });
          }
        });
      });

      if (layersToLoad.length > 0) {
        for (let i = 0; i < layersToLoad.length; i++) {
          const { layers, mapPanel } = layersToLoad[i];
          for (const grib of layers) {
            // LayerLoadingCache.startLoadingFrames(mapPanel.id, grib.id, grib.unLoadedDataFrames);
          }
        }
      }

      const interval = setInterval(() => {
        const allLoaded = WeatherDataLoader.areAllFramesLoaded();
        dispatch(addFrames(WeatherDataLoader.getFramesState()));
        dispatch(setLoadingState(WeatherDataLoader.getLoadingState()));

        if (allLoaded) {
          dispatch(setMapLoading(false));
          clearInterval(interval);
          resolve();
        }
      }, 100);

      if (layersToLoad.length > 0) {
        for (let i = 0; i < layersToLoad.length; i++) {
          // @ts-ignore
          // eslint-disable-next-line unused-imports/no-unused-vars
          const { layers, mapPanel, idTokenParsed, dispatch, sceneDefId } = layersToLoad[i];
          // LayerLoadingCache.setMapLoadingZoomLevel(mapPanel.id, mapPanel.mapPositionControl?.zoom);
          //await loadRestLayers(layers, mapPanel, idTokenParsed, dispatch, sceneDefId);

          // Call web worker to do the loading of the frames
          const worker = WeatherDataLoader.getInstance();

          worker.loadWeatherData(projectId, layers, mapPanel, false);
        }
      }
    }
  });
};
