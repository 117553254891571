import { ExclamationCircleFilled } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Button, Modal, Space, Table, Tag } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdQueryStats } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { useDebounce } from '../../../hooks/useDebounce';
import { C9Object } from '../../../model/definitions/C9Object';
import { ColorPaletteParamTypeEnum } from '../../../model/enums/ColorPaletteParamTypeEnum';
import { ProtocolTypeEnum } from '../../../model/enums/ProtocolTypeEnum';
import { ScrapingEngineTypeEnum } from '../../../model/enums/ScrapingEngineTypeEnum';
import { SortingEnum } from '../../../model/enums/SortingEnum';
import { VisualisationTypeEnum } from '../../../model/enums/VisualisationTypeEnum';
import SortContext from '../../dashboard/templates-NEW/sortContext';
import Sorting from '../../marketplace-new/molecules/Sorting/Sorting';
import { useDeleteDataProduct } from '../hooks/useDeleteDataProduct';
import { useGetDataProductsByProvider } from '../hooks/useGetDataProductsByProvider';
import { useGetEtl } from '../hooks/useGetEtl';
import { useGetProvider } from '../hooks/useGetProvider';
import { useScraping } from '../hooks/useScraping';
import StepsAntd from '../organisms/Steps';

const { confirm } = Modal;

export interface VisualizationTypeInterface {
  visualizationType: VisualisationTypeEnum;
  isDefault: boolean;
}

export interface ColorPaletteParameterGroupInterface {
  id: string;
  layerType: string;
  name: string;
  value: ColorPaletteParamTypeEnum;
}

export interface ParameterMappingInterface {
  id?: string;
  default?: boolean;
  paramType: string;
  isInterpolationEnabled?: boolean;
  colorPaletteParameterGroup?: ColorPaletteParameterGroupInterface;
  visualizationTypes?: VisualizationTypeInterface[];
}
export interface DataProductInterface {
  id?: string;
  versionId?: string;
  providerId: string;
  baseName: string;
  name: string;
  description: string;
  productType: string;
  accessInformation: {
    username?: string;
    password?: string;
    url: string;
    protocol: ProtocolTypeEnum;
    ports: string[];
  };
  fileInformation: {
    folderName?: string;
    regexName: string;
    fileFormat?: string;
  };
  dataFilterSetup: {
    updateFrequency?: string;
    dataStartDate?: number;
    dataEndDate?: number;
    samplingTime?: number;
    futureLimitTime?: number;
    retentionTime: number | null;
  };
  params: {
    ortho?: string;
    proj?: string;
  };
  timeToBeReadFrom?: string | null;
  types?: string[];
  supportInfo?: string;
  defaultColorPaletteIds: string[];
  regExpressions: object[];
  createdOnWDConsole?: boolean;
  scrapingEngineType: string;
  base64ShFile?: string | null;
  createdAt?: number | null;
  creator?: string;
  parameterMappings: ParameterMappingInterface[];
  forecastLocationFile?: C9Object;
}

export interface EtlStatus {
  id: string;
  etl_active: string;
}

function Products() {
  const { keycloak } = useKeycloak();
  const { data: provider } = useGetProvider('provider', keycloak?.tokenParsed?.email);
  const [open, setOpen] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const [selectedProduct, setSelectedProduct] = useState<DataProductInterface>();

  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const { sortAscDesc, sortByField, handleSort } = useContext(SortContext);
  const searchKey = useDebounce(search, 300);
  const [sizeChanger, setSizeChanger] = useState<number>(20);
  useHotkeys(
    'Escape',
    (ev) => {
      ev.preventDefault();
      open && setOpen((prevOpen) => !prevOpen);
    },
    [open],
  );

  const {
    data: providerData,
    isLoading: loadingProviderData,
    refetch,
    isRefetching,
  } = useGetDataProductsByProvider(
    provider?.id,
    page,
    sizeChanger,
    sortByField + ',' + sortAscDesc,
    searchKey,
  );
  const { data: etlStatus } = useGetEtl(
    providerData ? providerData?.content?.map((obj: DataProductInterface) => obj.id) : [],
  );
  const { mutate } = useScraping();
  const deleteProduct = useDeleteDataProduct();

  const handleDelete = (id: string) => {
    confirm({
      title: 'Delete product',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this product?',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deleteProduct.mutate(id);
      },
    });
  };

  const handleEdit = (product: DataProductInterface) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleScraping = (id: string, etl_active: boolean) => {
    mutate({ id, etl_active });
  };

  useEffect(() => {
    if (page !== 0) {
      const timer = setTimeout(() => {
        setPage(0);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    if (provider) {
      refetch();
    }
  }, [page, searchKey, sortAscDesc, sortByField, sizeChanger, refetch]);

  function getProductColor(productType: string) {
    switch (productType) {
      case 'SATELLITE':
        return 'purple';
      case 'MODEL':
        return 'orange';
      case 'RADAR':
        return 'cyan';
      case 'FORECAST':
        return 'gold';
      case 'EVENT':
        return 'pink';
      default:
        return 'lime';
    }
  }

  const columns = [
    {
      title: 'Base Name',
      dataIndex: 'baseName',
      key: 'basename',
      sorter: (a: DataProductInterface, b: DataProductInterface) =>
        a.baseName.localeCompare(b.baseName),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: DataProductInterface, b: DataProductInterface) => a.name.localeCompare(b.name),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a: DataProductInterface, b: DataProductInterface) =>
        a.description.localeCompare(b.description),
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType',
      sorter: (a: DataProductInterface, b: DataProductInterface) =>
        a.productType.localeCompare(b.productType),
      render: (productType: string) => (
        <Tag color={getProductColor(productType)} key={productType}>
          {productType}
        </Tag>
      ),
    },
    {
      title: 'Scraping Status',
      dataIndex: 'scrapingStatus',
      key: 'scrapingStatus',
      render: (_: any, product: DataProductInterface) => {
        const findStatus = etlStatus?.find((etl) => etl.id === product.id);
        if (product.base64ShFile) {
          return (
            <Tag color={findStatus?.etl_active === 'True' ? 'green' : 'red'} key={findStatus?.id}>
              {product.scrapingEngineType !== ScrapingEngineTypeEnum.WITHOUT_SCRAPING &&
              findStatus?.etl_active === 'True'
                ? 'Active'
                : 'Not active'}
            </Tag>
          );
        }
      },
    },
    {
      title: 'Monitoring',
      dataIndex: 'monitoring',
      key: 'monitoring',
      width: 100,
      render: (_: any, product: DataProductInterface) => {
        return (
          <Link to={`./monitoring/${product.id}`} className="flex justify-center">
            <MdQueryStats color="#389E0D" className="text-2xl" />
          </Link>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 300,
      render: (_: any, product: DataProductInterface) => {
        const findStatus = etlStatus?.find((etl) => etl.id === product.id);
        return (
          <Space size="middle" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {product.base64ShFile ? (
              findStatus?.etl_active === 'True' ? (
                <Button
                  shape="round"
                  onClick={() => product.id && handleScraping(product.id, false)}
                >
                  Stop scraping
                </Button>
              ) : (
                <Button
                  shape="round"
                  onClick={() => product.id && handleScraping(product.id, true)}
                  style={{ background: '#389e0d', borderColor: '#389e0d', color: 'white' }}
                >
                  Start scraping
                </Button>
              )
            ) : (
              <></>
            )}
            <Button
              type="primary"
              shape="round"
              onClick={(e) => {
                e.currentTarget.blur();
                handleEdit(product);
              }}
            >
              Edit
            </Button>
            <Button danger shape="round" onClick={() => product.id && handleDelete(product.id)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className={'flex items-center mb-4'}>
        <h1>Product List</h1>
        <div className={'ws-input'} style={{ height: '32px' }}>
          <input
            ref={searchRef}
            value={search}
            autoFocus
            onChange={(e) => setSearch && setSearch(e.target.value)}
            placeholder={'Type here...'}
            className="w-full 2xl:!w-[300px]"
          />
          <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
        </div>
        {providerData?.content && providerData?.content.length > 1 && (
          <div className="ml-auto">
            <Sorting
              sortAscDesc={sortAscDesc}
              sorting={SortingEnum}
              handleSort={handleSort}
              sortByField={sortByField}
            />
          </div>
        )}
      </div>
      <Table
        rowKey="id"
        dataSource={providerData?.content}
        columns={columns}
        loading={loadingProviderData || isRefetching}
        pagination={{
          defaultPageSize: sizeChanger,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          total: providerData?.totalElements,
          onShowSizeChange(current: number, pageSize: number) {
            setSizeChanger(pageSize);
            setPage(0);
          },
          onChange(page) {
            setPage(page - 1);
          },
        }}
      />
      <Modal
        title={`Edit Product ${selectedProduct?.name}`}
        open={open}
        width="90%"
        style={{ top: 10 }}
        footer={null}
        onCancel={() => setOpen(!open)}
        destroyOnClose
      >
        <StepsAntd selectedProduct={selectedProduct} setOpen={setOpen} />
      </Modal>
    </>
  );
}

export default Products;
