import './style.scss';

import { ToggleSwitch } from 'flowbite-react';
import Slider from 'rc-slider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEditor, Descendant, Node, NodeEntry, Range, Transforms } from 'slate';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';

import Button from '../../../atoms/button/Button';
import Input from '../../../atoms/input/Input';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { RichTextDef } from '../../../model/definitions/RichTextDef';
import { TextPanelDef } from '../../../model/definitions/TextPanelDef';
import { CrawlDirectionPicker } from '../../../molecules/CrawlDirectionPicker';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { deleteTextLayer, updateTextLayer } from '../../../store/slices/project-slice';
import { selectActiveTextLayer } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import Toolbar from './components/slatejs/Toolbar';
import { BoxStyle } from './panels/BoxStyle';
import { FontProperties } from './panels/FontProperties';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import styles from './Properties.module.scss';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

const TextProperties: React.FC = () => {
  function withSingleLine(editor: ReactEditor) {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]: NodeEntry) => {
      if (path.length === 0 && editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }

      return normalizeNode([node, path]);
    };

    return editor;
  }
  const editor = useMemo(() => withSingleLine(withReact(createEditor())), []);
  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />;
  }, []);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const { activeScene, activeElement, activePoster } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const textLayer = useSelector<RootState, TextPanelDef | null | undefined>((state) =>
    selectActiveTextLayer(state),
  );
  const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.underline) {
      children = <u>{children}</u>;
    }
    if (leaf.superscript) {
      children = <sup>{children}</sup>;
    }
    if (leaf.subscript) {
      children = <sub>{children}</sub>;
    }
    if (leaf.fontFamily) {
      children = (
        <span
          style={{
            fontFamily: leaf.fontFamily + ' ' + leaf.fontType,
            textTransform: leaf.textTransform,
          }}
        >
          {children}
        </span>
      );
    }
    return <span {...attributes}>{children}</span>;
  };
  const serializeToText = (nodes: Descendant[]) => {
    return nodes.map((n: Descendant) => Node.string(n)).join('\n');
  };
  const { isOpened, lastFocused } = usePropertyGridActive([
    'textAnimation.active',
    'textAnimation.speed',
    'value',
    'description',
    'name',
  ]);
  const dispatch = useDispatch();
  function onTextLayerChange(
    propertyPath: Leaves<TextPanelDef>,
    e: boolean | string | number | RichTextDef,
  ) {
    onFocus(propertyPath);
    dispatch(
      updateTextLayer({
        newValue: e,
        activeScene,
        elementId: activeElement,
        propertyPath: propertyPath,
        parentId: activePoster,
      }),
    );
  }

  function onFocus(path: Leaves<TextPanelDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  const deleteElement = () => {
    dispatch(
      deleteTextLayer({
        activeScene,
        elementId: activeElement,
        parentId: activePoster,
      }),
    );
  };

  useEffect(() => {
    if (textLayer?.richText) {
      if (editor.children.length > 0) {
        Transforms.removeNodes(editor, { at: [0] });
      }
      Transforms.insertNodes(editor, textLayer?.richText);
    } else {
      if (textLayer?.value) {
        if (editor.children.length > 0) {
          Transforms.removeNodes(editor, { at: [0] });
        }
        Transforms.insertNodes(editor, {
          //@ts-ignore
          children: [{ text: textLayer.value, fontFamily: textLayer?.fontFamily }],
        });
        //@ts-ignore
        onTextLayerChange('richText', {
          type: 'paragaph',
          children: [
            {
              text: textLayer?.value,
              fontFamily: textLayer?.fontFamily,
            },
          ],
        });
      }
    }
  }, [activeElement]);

  useEffect(() => {
    if (editor.selection) {
      const isNodeSelected = !Range.isCollapsed(editor.selection);
      setIsSelected(isNodeSelected);
    }
  }, [editor.selection]);

  return (
    <Panel>
      <PropertySection label={'text properties'} isOpened={isOpened}>
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Name:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={textLayer?.name}
                  autoFocus={lastFocused === 'name'}
                  onChange={(e) => {
                    onTextLayerChange('name', e.target.value);
                  }}
                  className={styles.inputWrap}
                  onFocus={() => onFocus('name')}
                />
              }
            />
            <GridItem
              label={'Description:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={textLayer?.description}
                  autoFocus={lastFocused === 'description'}
                  onChange={(e) => {
                    onTextLayerChange('description', e.target.value);
                  }}
                  className={styles.inputWrap}
                  onFocus={() => onFocus('description')}
                />
              }
            />
            {textLayer && (
              <Slate
                editor={editor}
                value={[textLayer?.richText]}
                onChange={(newValue: Descendant[]) => {
                  //@ts-ignore
                  onTextLayerChange('richText', newValue[0]);
                  onTextLayerChange('value', serializeToText(newValue));
                }}
              >
                <>
                  <div className="col-span-3 col-start-2">
                    <Toolbar isSelected={isSelected} />
                  </div>

                  <div className="grid-item-label">
                    <span>Value: </span>
                  </div>
                  <Editable
                    className="bg-[#060b12] border border-[#2b3441] text-white overflow-hidden !w-full inline-block"
                    autoFocus
                    renderLeaf={renderLeaf}
                  />
                </>
              </Slate>
            )}

            <GridItem
              noBorderBg
              label={'Crawl:'}
              item={
                <ToggleSwitch
                  checked={!!textLayer?.textAnimation?.active}
                  label={''}
                  onChange={(e) => onTextLayerChange('textAnimation.active', e)}
                />
              }
            />

            <GridItem
              noBorderBg
              label={'Crawl:'}
              item={
                <>
                  <Slider
                    min={0}
                    max={50}
                    value={textLayer?.textAnimation?.speed}
                    disabled={!textLayer?.textAnimation?.active}
                    onChange={(e) => {
                      e && typeof e === 'number' && onTextLayerChange('textAnimation.speed', e);
                    }}
                  />
                  <InputNumber
                    className={styles.inputWrap}
                    max={100}
                    min={0}
                    disabled={!textLayer?.textAnimation?.active}
                    precision={2}
                    value={textLayer?.textAnimation?.speed}
                    onFocus={() => onFocus('textAnimation.speed')}
                    autoFocus={lastFocused === 'textAnimation.speed'}
                    onInputChange={(e) => {
                      e >= 0 && e <= 360 && onTextLayerChange('textAnimation.speed', e);
                    }}
                    type="number"
                  />
                </>
              }
            />

            <GridItem
              noBorderBg
              label={'Crawl direction:'}
              item={
                <CrawlDirectionPicker
                  onClick={(e) => onTextLayerChange('textAnimation.direction', e)}
                  value={textLayer?.textAnimation?.direction}
                  disabled={!textLayer?.textAnimation?.active}
                />
              }
            />

            {!activePoster && (
              <GridItem
                noBorderBg
                label={'Action'}
                item={
                  <Button label={'Delete'} buttonType={'danger'} onClick={() => deleteElement()} />
                }
              />
            )}
          </GridWrapper>
        </div>
      </PropertySection>
      <>
        {textLayer && (
          <FontProperties layer={textLayer} layerType={'textPanels'} poster={activePoster} />
        )}
        {textLayer?.positionControl && !activePoster && (
          <PositionControls
            position={textLayer?.positionControl}
            layer={'textPanels'}
            poster={activePoster}
          />
        )}
        {textLayer?.timeControls && !activePoster && (
          <TimeControlsPanel timeControls={textLayer?.timeControls} layer={ElementsEnum.TEXT} />
        )}
        {textLayer && <BoxStyle box={textLayer?.boxDef} elementType={ElementsEnum.TEXT} />}
      </>
    </Panel>
  );
};

export default TextProperties;
