import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Badge } from 'flowbite-react';
import moment from 'moment';
import { useMemo } from 'react';
import { useTable } from 'react-table';

import formatFileSize from '../../../helpers/formatFileSize';
import { SftpStatusEnum } from '../../../model/enums/SftpStatus';
import { TaskStatusEnum } from '../../../model/enums/TaskStatusEnum';

interface Props {
  data?: any;
}

const VAList: React.FC<Props> = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Video type',
        accessor: 'type',
      },
      {
        Header: 'Date time',
        accessor: 'requestDate',
        Cell: (requestDate: any) => (
          <span>{moment(requestDate?.cell?.value).format('DD/MM/yyyy HH:mm')}</span>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (status: any) => (
          <Badge
            color={`${
              status?.cell?.value === TaskStatusEnum.FINISHED
                ? 'success'
                : status?.cell?.value === TaskStatusEnum.FAILED
                ? 'failure'
                : status?.cell?.value === TaskStatusEnum.IN_PROGRESS
                ? 'gray'
                : 'info'
            }`}
            size="sm"
          >
            {status?.cell?.value}
          </Badge>
        ),
      },
      {
        Header: 'Error',
        accessor: 'errorMessage',
      },
      {
        Header: 'Render type',
        accessor: 'renderType',
      },
      {
        Header: 'Sftp name',
        accessor: 'sftpName',
      },
      {
        Header: 'Sftp status',
        accessor: 'sftpStatus',
        Cell: (status: any) => (
          <Badge
            color={`${
              status?.cell?.value === SftpStatusEnum.SUCCESS
                ? 'success'
                : status?.cell?.value === TaskStatusEnum.FAILED && 'failure'
            }`}
            size="sm"
          >
            {status?.cell?.value}
          </Badge>
        ),
      },
      {
        Header: 'SIZE',
        accessor: 'sizeInMB',
        Cell: (sizeInMB: any) => (
          <span>
            {sizeInMB?.cell?.value && formatFileSize(sizeInMB?.cell?.value * 1000 * 1000)}
          </span>
        ),
      },
      {
        id: 'actions',
        Header: '',
        accessor: 'outputVideo',
        Cell: ({ row }: { row: any }) => (
          <Tooltip title="Download">
            {row.original?.outputVideo ? (
              <a
                href={
                  process.env.REACT_APP_API_BASE_URL +
                  '/api/public/store/multimedia/download/video/' +
                  row.original?.id +
                  '?fileName=' +
                  encodeURIComponent(row.original?.name) +
                  ' - ' +
                  moment(row.original?.creationTime).format('DD.MM.yyyy HH:mm:ss') +
                  '.mp4'
                }
                target="_blank"
                download
                rel="noreferrer"
              >
                <Button size="large" type="text" icon={<DownloadOutlined />} />
              </a>
            ) : (
              <Button
                size="large"
                disabled={!row.original?.outputVideo}
                type="text"
                icon={<DownloadOutlined />}
              />
            )}
          </Tooltip>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <table
      {...getTableProps()}
      style={{
        borderRadius: '10px',
        width: ' 100%',
        boxShadow: '1px 1px 5px 1px rgba(1, 1, 1, 0.07)',
        // border: '1px solid #e5e7eb',
        overflow: 'hidden',
      }}
    >
      <thead>
        {headerGroups.map((headerGroup: any, i: number) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column: any, j: number) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  padding: '1rem 1.5rem',
                  fontWeight: 600,
                  textAlign: 'left',
                  backgroundColor: 'rgb(249, 250, 251)',
                  textTransform: 'uppercase',
                }}
                key={j}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, i: number) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={i}>
              {row.cells.map((cell: any, j: number) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '1rem 1.5rem',
                      textAlign: 'left',
                      borderBottom: '1px solid rgba(51, 51, 51, 0.08)',
                      backgroundColor: 'transparent',
                    }}
                    key={j}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default VAList;
