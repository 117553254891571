import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFontLoader } from '../../core/api/useLoadFont';
import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { getRelativeDate } from '../../helpers/date';
import { TimezoneOffsets } from '../../helpers/date/timezones';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { BorderDef } from '../../model/definitions/BorderDef';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { PointDateDef } from '../../model/definitions/PointDateDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { selectActiveScene } from '../../store/slices/selectors';
import { RootState } from '../../store/store';
// Import the custom locale
import ElementContainer from './ElementContainer';
import { transformPercentToAbsolute, translateText } from './utils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);

interface PointDateElementElementProps {
  panelProps: PointDateDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  isMapTextElement?: boolean;
  mapId?: string;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  geoPosterId?: string;
  scene: SceneDef | WeatherPosterDef;
}

export const PointDateElement = ({
  panelProps,
  canvas,
  disabled,
  mapId,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
  scene,
}: PointDateElementElementProps) => {
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { activeAspectRatio, activeScene, sceneTranslation, translation } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { properties } = project;
  const { timezone } = properties;
  const [, setForceUpdate] = useState(0);
  const {
    boxDef,
    fontSize,
    fontFamily,
    fontType,
    fontColor,
    fontAlignment,
    textAnimation,
    timeControls,
    positionControl,
    strokeWidth,
    strokeColor,
    textTransform,
  } = panelProps;
  const {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    background,
    borderRight,
    borderTop,
    borderLeft,
    borderBottom,
  } = boxDef;
  const { cnvHeight } = canvas;
  let fontUnit: number;
  if (inPoster && cnvHeight) {
    const cH = transformPercentToAbsolute(
      cnvHeight,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    fontUnit = cH / 100;
  } else {
    fontUnit = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  }
  const findCommonDate = (dates: Array<string>) => {
    if (dates.length === 0) {
      return false;
    }
    const firstDate = moment(dates[0]);

    // Check if all dates have the same day
    const hasCommonDay = dates.every((date) => moment(date).isSame(firstDate, 'day'));
    if (!hasCommonDay) {
      return false;
    }

    return firstDate.format('YYYY-MM-DD');
  };
  const findInForecast = scene.forecastWDElements.find(
    (element) => element.pointWDGroupId === panelProps.pointWDGroupId,
  )?.forecastWDSource.utcDate;
  const findInObserved = [...scene.observedWDElements].find(
    (element) => element.pointWDGroupId === panelProps.pointWDGroupId,
  )?.observedWDSource.utcDate;
  const findGeoObserved = () => {
    const mapId = panelProps.pointWDGroupId.split('_')[0];
    if (mapId) {
      const map = activeSceneDef?.mapPanels.find((map) => map.id === mapId);
      if (map) {
        const obsDates = map.geoPosters.map((poster) =>
          poster.observedWDElements.map((obs) => obs.observedWDSource.utcDate),
        );
        return findCommonDate(obsDates.flat());
      }
    }
    return false;
  };
  const findPosterObserved = () => {
    const obsDates = activeSceneDef?.weatherPosters.map((poster) =>
      poster.observedWDElements.map((obs) => obs.observedWDSource.utcDate),
    );
    if (obsDates) return findCommonDate(obsDates?.flat());
    return false;
  };
  const findPosterForecast = () => {
    const forecastDates = activeSceneDef?.weatherPosters.map((poster) =>
      poster.forecastWDElements.map((obs) => obs.forecastWDSource.utcDate),
    );
    if (forecastDates) return findCommonDate(forecastDates?.flat());
    return false;
  };
  const findGeoForecast = () => {
    const mapId = panelProps.pointWDGroupId.split('_')[0];
    if (mapId) {
      const map = activeSceneDef?.mapPanels.find((map) => map.id === mapId);
      if (map) {
        const obsDates = map.geoPosters.map((poster) =>
          poster.forecastWDElements.map((obs) => obs.forecastWDSource.utcDate),
        );
        return findCommonDate(obsDates.flat());
      }
    }
    return false;
  };
  if (inPoster && cnvHeight) {
    const cH = transformPercentToAbsolute(
      cnvHeight,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    fontUnit = cH / 100;
  } else {
    fontUnit = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  }
  const { color } = background;
  const backgroundClip = fontColor?.includes('linear') ? 'text' : 'initial';
  const bgColor = fontColor?.includes('linear-gradient')
    ? gradientOpacity(fontColor)
    : singleColorOpacity(color);
  useFontLoader(fontFamily);
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;
  const dict = sceneTranslation[activeScene]?.customWords || translation?.customWords;
  const timeFrameIndicator = panelProps.dateFormat;
  const extractTimeFormat = (inputFormat: string) => {
    const tokens = cloneDeep(inputFormat).split(/\s+/);
    const timeTokens = tokens.filter((token) => /[HhmAas]+/.test(token));
    return timeTokens.length > 0 ? timeTokens.join(' ') : '';
  };
  const getCustomTimezoneName = (offset: string): string => {
    return TimezoneOffsets[offset] || offset;
  };

  // Utility function to format date with custom timezone name
  const formatWithCustomTimezone = (date: dayjs.Dayjs, format: string): string => {
    let formattedString = date.format(format);

    if (format.includes('z')) {
      const offset = date.format('Z');
      const customTimezoneName = getCustomTimezoneName(offset);
      console.log('customTimezoneName', customTimezoneName);
      const newFormat = format.replace(new RegExp('z', 'g'), customTimezoneName);
      formattedString = date.format(newFormat);
    }

    return formattedString;
  };
  const parseLocale = useCallback(
    (trans: any): ILocale => {
      return trans
        ? {
            ...trans,
            name: 'custom',
            longDateFormat: {
              ...trans.longDateFormat,
              LT: extractTimeFormat(timeFrameIndicator),
              L: timeFrameIndicator,
            },
            meridiem: (hour: number, minute: number, isLowercase: boolean) => {
              if (hour < 12) {
                return isLowercase ? trans.meridiem.am : trans.meridiem.AM;
              } else {
                return isLowercase ? trans.meridiem.pm : trans.meridiem.PM;
              }
            },
            calendar: {
              sameDay: `[${trans.calendar?.sameDay}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              nextDay: `[${trans.calendar?.nextDay}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              nextWeek: `${timeFrameIndicator} [${trans.calendar?.nextWeek}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              lastDay: `[${trans.calendar?.lastDay}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              lastWeek: `${timeFrameIndicator} [${trans.calendar?.lastWeek}] ${
                extractTimeFormat(timeFrameIndicator) ? 'LT' : ''
              }`,
              sameElse: 'LT',
            },
          }
        : undefined;
    },
    [timeFrameIndicator],
  );
  useEffect(() => {
    if (project.translationId || sceneTranslation[activeScene]) {
      const trans = sceneTranslation[activeScene] ?? translation;
      const parsedLocale = parseLocale(trans);
      if (parsedLocale) {
        dayjs.locale(parsedLocale, undefined, true);
        dayjs.locale('custom');
      }
    } else {
      dayjs.locale('en');
    }
    setForceUpdate((prev) => prev + 1); // trigger rerender on language change
  }, [activeScene, parseLocale, sceneTranslation, timeFrameIndicator, translation]);

  const getCustomPartOfDay = (date: Moment) => {
    const hours = date.hours();
    if (hours >= 6 && hours < 12) {
      return '$Morning';
    } else if (hours >= 12 && hours < 18) {
      return '$Day';
    } else if (hours >= 18 && hours < 24) {
      return '$Evening';
    } else {
      return '$Night';
    }
  };

  const formattedDate = (date: string | null) => {
    if (!date) {
      return null;
    }
    const formattedDate = getRelativeDate(
      date,
      timezone,
      panelProps.dateFormat,
      sceneTranslation[activeScene],
    );
    try {
      return panelProps.relativeFormat
        ? formattedDate
        : formatWithCustomTimezone(dayjs.utc(date).tz(timezone), panelProps.dateFormat).replace(
            /pp/g,
            () => getCustomPartOfDay(moment(panelProps.value)),
          );
    } catch (error) {
      console.log(`Error parsing date: ${error}`);
    }
  };

  function renderStringWithPlaceholders(
    inputString?: string | null,
    valueObject: Record<string, string> = {},
  ) {
    const regex = /\$(\w+)/g;
    if (!dict)
      return inputString
        ?.replace(/\$Night/g, 'Night')
        .replace(/\$NIGHT/g, 'NIGHT')
        .replace(/\$Day/g, 'Day')
        .replace(/\$DAY/g, 'DAY')
        .replace(/\$Morning/g, 'Morning')
        .replace(/\$MORNING/g, 'MORNING')
        .replace(/\$Evening/g, 'Evening')
        .replace(/\$EVENING/g, 'EVENING');
    return inputString?.replace(regex, (match, placeholder) => {
      return (valueObject && valueObject[placeholder]) || match;
    });
  }

  const borderString = (val: BorderDef) =>
    val && `${fontUnit * val.width}px ${val.style} ${singleColorOpacity(val.color)}`;

  return (
    <ElementContainer
      visibility={panelProps.enabled}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      type={'pointDates'}
      lock={false}
      style={{
        fontFamily: fontFamily + ' ' + fontType,
        textTransform,
        color: fontColor?.includes('linear') ? 'transparent' : singleColorOpacity(fontColor),
        textAlign: fontAlignment as FontAlignment,
        fontSize: fontUnit * fontSize,
      }}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
    >
      <div
        key={fontColor + 'container'}
        style={{
          height: '100%',
          overflow: 'hidden',
          background: bgColor,
          paddingTop: fontUnit * paddingTop,
          paddingLeft: fontUnit * paddingLeft,
          paddingRight: fontUnit * paddingRight,
          paddingBottom: fontUnit * paddingBottom,
          borderLeft: borderString(borderLeft),
          borderRight: borderString(borderRight),
          borderTop: borderString(borderTop),
          borderBottom: borderString(borderBottom),
          // @ts-ignore
          WebkitBackgroundClip: backgroundClip,
        }}
      >
        <div style={{ overflow: 'hidden', width: '100%' }}>
          <div
            style={{
              height: '100%',
              minWidth: textAnimation.active ? '100%' : 'auto',
              width: textAnimation.active ? 'fit-content' : 'auto',
              whiteSpace: textAnimation.active ? 'nowrap' : 'break-spaces',
              /*** I don't know the reason behind fixing margin and line height, but it is causing issues ***/
              /*
              marginTop: fontSize * 1.15, */
              lineHeight: 1.15,
              // @ts-ignore
              WebkitBackgroundClip: backgroundClip,
              transform: textAnimation?.active
                ? translateText(
                    positionControl.w,
                    activeAspectRatio,
                    time,
                    ref,
                    textAnimation.speed,
                    timeControls[0].startMS,
                    MAX_FULLSCREEN_HEIGHT,
                    textAnimation.direction,
                  )
                : 'none',
              WebkitTextStroke: strokeWidth
                ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
                : '0px',
            }}
            ref={ref}
          >
            {renderStringWithPlaceholders(
              formattedDate(
                findInObserved ||
                  findInForecast ||
                  findGeoObserved() ||
                  findGeoForecast() ||
                  findPosterObserved() ||
                  findPosterForecast() ||
                  '',
              ),
              dict,
            )}
          </div>
        </div>
      </div>
    </ElementContainer>
  );
};
