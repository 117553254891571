import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import { C9ProjectDef } from '../../../../../model/definitions/C9ProjectDef';
import { WeatherPosterDef } from '../../../../../model/definitions/WeatherPosterDef';
import { RootState } from '../../../../../store/store';
import { ForecastSection } from './ForecastSection';

interface ForecastPageProps {
  //definition: WeatherPosterDef;
  offset: number;
  offsetMode: 'HOURS' | 'DAYS';
  step: 'observed' | 'forecast' | 'add';
  setStep: (step: 'observed' | 'forecast' | 'add') => void;
  bias: boolean;
  allPosters: Array<WeatherPosterDef>;
}
export const ForecastPage = ({ offset, step, bias, allPosters, setStep }: ForecastPageProps) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { properties } = project;
  const { timezone: projecTimezone } = properties;
  dayjs.tz.setDefault(projecTimezone);

  const [dates, setDates] = useState<Array<string>>([]);
  const [selected, setSelected] = useState<number>(Date.now());
  useEffect(() => {
    if (allPosters.length === 0 && step === 'forecast') setStep('add');
  }, [setStep, step]);
  useEffect(() => {
    const timestamps = dates.map((date) => new Date(date).getTime());
    timestamps.length && setSelected(Math.min(...timestamps));
  }, [dates]);
  const renderForecast = (definition: WeatherPosterDef) => {
    const zeroDate = Math.min(
      ...definition.forecastWDElements.map((forecast) =>
        new Date(forecast.forecastWDSource.utcDate).getTime(),
      ),
    );
    const referentDate = definition.creationTime;
    const grouped = groupBy(definition.forecastWDElements, 'forecastWDSource.location.name');
    const all = [];
    for (const point in grouped) {
      all.push(
        <ForecastSection
          id={definition.id}
          returnedValues={(e) => {}}
          bias={bias}
          selectedDate={selected}
          zeroDate={zeroDate}
          setDates={(e) => {
            e && setDates((prev: Array<string>) => [...new Set([...prev, ...e])]);
          }}
          step={step}
          key={JSON.stringify(grouped[point])}
          point={point}
          forecast={grouped[point]}
          refDate={referentDate}
          offset={offset}
        />,
      );
    }
    return all;
  };
  const ExampleCustomInput = forwardRef(
    (
      { value, onClick }: { value?: string; onClick: () => void },
      ref: ForwardedRef<HTMLButtonElement>,
    ) => (
      <button className="example-custom-input" onClick={onClick} ref={ref}>
        {dayjs(value).tz(projecTimezone).format('DD/MM/YYYY')}
      </button>
    ),
  );
  ExampleCustomInput.displayName = 'ExampleCustomInput';
  return (
    <div>
      <div className={'flex items-center'}>
        <AiOutlineInfoCircle className={'mr-3'} /> Select date of the first element in template.
        Note that there may be no values for some dates
      </div>
      {dates && (
        <DatePicker
          selected={selected ? new Date(selected) : undefined}
          placeholderText={'Select observed moment'}
          dateFormatCalendar={'MMMM yyyy'}
          showPopperArrow={false}
          shouldCloseOnSelect={true}
          withPortal={true}
          timeFormat={'HH:mm'}
          includeDates={dates ? dates.map((date) => new Date(date)) : []}
          minDate={new Date(Math.min(...dates.map((date) => new Date(date).getTime())))}
          maxDate={new Date(Math.max(...dates.map((date) => new Date(date).getTime())))}
          renderDayContents={(day, date) => {
            return <span>{dayjs(date).tz(projecTimezone).date()}</span>;
          }}
          onChange={(e) => {
            e &&
              setSelected(
                new Date(new Date(new Date(e.setHours(0)).setMinutes(0)).setSeconds(0)).getTime(),
              );
            e &&
              console.log(
                dayjs(
                  new Date(new Date(new Date(e.setHours(0)).setMinutes(0)).setSeconds(0)).getTime(),
                ).tz(projecTimezone),
              );
          }}
          openToDate={selected ? new Date(selected) : undefined}
          customInput={
            <ExampleCustomInput
              value={dayjs(selected).tz(projecTimezone).format('dd/MM/yyyy')}
              onClick={() => console.log(new Date())}
            />
          }
        />
      )}
      <div>
        {allPosters.length ? (
          allPosters.map((poster) => renderForecast(poster))
        ) : (
          <h3>No posters with forecast data. Please proceed...</h3>
        )}
      </div>
    </div>
  );
};
