import './properties.scss';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Tabs, { TabItemProp } from '../../../atoms/Tabs/Tabs';
import { ModeEnum } from '../../../core/ui/enums/ModeEnum';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { ActiveDef } from '../../../store/slices/active-slice';
import { selectActiveMapLayer } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { AnimationProperties } from './AnimationProperties';
import AudioProperties from './AudioProperties';
import CityGeoPosterProperties from './CityGeoPosterProperties';
import { DrawingProperties } from './DrawingProperties';
import { ForecastProperties } from './ForecastProperties';
import { GeoForecastProperties } from './GeoForecastProperties';
import GraphProperties from './GraphProperties';
import ImageProperties from './ImageProperties';
import { LayerLegendProperties } from './LayerLegendProperties';
import MapProperties from './MapProperties';
import { Multiselect } from './Multiselect';
import { ObservedProperties } from './observedDataProperties/ObservedProperties';
import { PointDateProperties } from './PointDateProperties';
import { PointLocationProperties } from './PointLocationProperties';
import ProjectProperties from './ProjectProperties';
import SceneProperties from './SceneProperties';
import { SpaceProperties } from './SpaceProperties/SpaceProperties';
import TextProperties from './TextProperties';
import VideoProperties from './VideoProperties';

const Properties = () => {
  const { activeProp, activeElement, activeScene, mode, activeMap, activePoster, spaceId } =
    useSelector<RootState, ActiveDef>((state) => state.active);
  const [tab, setTab] = useState<string | number>('scene');
  const mapLayer = useSelector<RootState, MapPanelDef | null | undefined>((state) =>
    selectActiveMapLayer(state),
  );
  useEffect(() => {
    setTab('element');
  }, [activeElement]);
  useEffect(() => {
    mode === ModeEnum.PROJECT && setTab('project');
    mode === ModeEnum.SEQUENCE && setTab('element');
  }, [mode]);
  if (!activeElement && !activeScene)
    return (
      <div style={{ minWidth: 300 }} className="p-4">
        No elements selected
      </div>
    );
  function onClick(e: TabItemProp) {
    if (mode !== ModeEnum.PROJECT && e.itemKey) setTab(e.itemKey);
    else setTab('project');
  }

  const isSpace = () => {
    return mapLayer?.wdSpace[0]?.id === spaceId;
  };

  return (
    <div
      className="max-h-full overflow-y-auto properties__tabs_wrapper"
      style={{ height: '-webkit-fill-available', paddingLeft: 10 }}
    >
      <Tabs
        key={'propsTabs'}
        onClick={onClick}
        active={tab}
        style={{ backgroundColor: '#05152b', height: 'fill-available' }}
      >
        <Tabs.Item
          title="element"
          itemKey={'element'}
          key={'element'}
          style={{ backgroundColor: '#05152b', height: 'fill-available' }}
        >
          {activeProp === 'textPanels' && <TextProperties />}
          {activeProp === 'imagePanels' && <ImageProperties />}
          {activeProp === 'animationPanels' && <AnimationProperties />}
          {activeProp === 'videoPanels' && <VideoProperties />}
          {activeProp === 'audioElements' && <AudioProperties />}
          {activeProp === 'mapPanels' && !spaceId && <MapProperties />}
          {activeProp === 'mapPanels' && isSpace() && <SpaceProperties />}
          {activeProp === 'weatherPosters' && <GraphProperties />}
          {activeProp === 'observedWDElements' && <ObservedProperties />}
          {activeProp === 'forecastWDElements' && <ForecastProperties />}
          {activeProp === 'pointDates' && <PointDateProperties />}
          {activeProp === 'pointLocation' && <PointLocationProperties />}
          {activeProp === 'forecastWDElements' && activeMap && activePoster && (
            <GeoForecastProperties element={activeElement} />
          )}
          {activeProp === 'cityGeoPoster' && <CityGeoPosterProperties />}
          {activeProp === 'layerLegend' && <LayerLegendProperties />}
          {activeProp === 'drawLayer' && <DrawingProperties />}
          {!activeProp && <Multiselect />}
        </Tabs.Item>
        <Tabs.Item title="scene" itemKey={'scene'} key={'scene'}>
          <SceneProperties />
        </Tabs.Item>
        <Tabs.Item title="Project" itemKey={'project'} key={'project'}>
          <ProjectProperties />
        </Tabs.Item>
      </Tabs>
    </div>
  );
};

export default Properties;
