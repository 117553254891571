import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from './useGetEnterpriseProperties';

const useAddEnterpriseProperties = () => {
  return useMutation(
    async (properties: {
      gammaCorrection: number;
      saturation: number;
      contrast: number;
      enterpriseAccountId: string;
    }) => {
      const { data } = await axiosInstance.post(`enterprise-properties`, properties);
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    },
  );
};

export default useAddEnterpriseProperties;
