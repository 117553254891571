import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import NoData from '../../assets/images/no-data.jpg';
import { LottieIcon } from '../../atoms/LottieIcon';
import { useGetIconSets } from '../../core/api/useGetIconSets';
import { useGetImageMultimedia } from '../../core/api/useGetMultimedia';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { WeatherDataLoader } from '../../core/weather-data/WeatherDataLoader';
import { PointWithValue } from '../../core/weather-data/WeatherDataLoaderTypes';
import { IconSetTemplateItemDef } from '../../model/definitions/IconSetTemplateItemDef';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { SymbolLayerDef, SymbolSourceType } from '../../model/definitions/SymbolLayerDef';
import Loader from '../../pages/dashboard/components/Loader';
// import Loader from '../../pages/dashboard/components/Loader';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { Sequence } from '../canvasElements/sequence/Sequence';
import { getCurrentSymbolLayerFrame, isWeatherTypeLayer } from './useSymbolLayers';

interface Props {
  mapPanel: MapPanelDef;
}

export const GLOBAL_DIRTY_RERENDER_PROP = 'GLOBAL_DIRTY_RERENDER_PROP';

export function callSymbolIconsRerender() {
  // @ts-ignore
  window[GLOBAL_DIRTY_RERENDER_PROP] && window[GLOBAL_DIRTY_RERENDER_PROP]();
}

export const SymbolLayerWeatherType = ({ mapPanel }: Props) => {
  const { activeFramerate, mode, projectToPlay } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const playContext = useContext(PlayerContext);
  /**Uncomment some of this dirty hacks only if absolutely necesary */
  /**Truly dirty hack */
  const [dirtyHack, setDirtyHack] = useState(0);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setDirtyHack(dirtyHack + 1);
  //   }, 16);
  // }, [dirtyHack, setDirtyHack]);

  const dirtyForceRerender = useCallback(() => {
    setDirtyHack((d) => d + 1);
  }, [setDirtyHack, dirtyHack]);

  useEffect(() => {
    // @ts-ignore
    window[GLOBAL_DIRTY_RERENDER_PROP] = dirtyForceRerender;
  }, [dirtyForceRerender]);

  const scene = projectToPlay.sceneDefs.find((sc) =>
    sc.mapPanels.some((p) => p.id === mapPanel.id),
  );
  const sceneStartMs = scene?.timeControl.startMS || 0;

  const weatherTypeLayers = mapPanel.wdSpace[0].symbolLayers.filter(isWeatherTypeLayer);

  // load icon sets
  const iconSetsToLoad = weatherTypeLayers.map((layer) => layer.symbolSource.iconPackId);
  const { data: iconSets } = useGetIconSets(iconSetsToLoad);

  const iconSetRecord: Record<string, IconSetTemplateItemDef[]> = {};
  iconSets?.forEach((x) => {
    if (x) iconSetRecord[x.id] = x.icons;
  });

  const getIconForPoint = (iconSetId: string, code: string) => {
    return iconSetRecord[iconSetId]
      ? iconSetRecord[iconSetId].find((x) => x.weatherTypeCodes.some((c) => c === code))
      : undefined;
  };

  return (
    <>
      {weatherTypeLayers
        .filter((l) => l.enabled)
        .map((layer: SymbolLayerDef) => {
          const frame = getCurrentSymbolLayerFrame(
            layer,
            mode,
            activeFramerate,
            sceneStartMs,
            playContext,
          );
          const firstFrame =
            layer.symbolSource.sourceType === SymbolSourceType.PointData
              ? layer.symbolSource &&
                layer.symbolSource.pointDataFrames &&
                layer.symbolSource.pointDataFrames?.length > 0 &&
                layer.symbolSource.pointDataFrames[0].dateString
              : layer.dataFrames.length > 0 && layer.dataFrames[0].frameId;
          let framePoints: PointWithValue[] | undefined;
          if (firstFrame && playContext.isPlaying === PlaybackEnum.STOP && playContext.time === 0) {
            framePoints = WeatherDataLoader.getByFrameIdWeatherPoints(firstFrame, layer.id)?.points;
          }
          return (
            <div key={layer.id}>
              {layer.symbolSource.points?.map((point) => {
                const key = layer.id + point.lat.toFixed(2) + point.lon.toFixed(2);
                const dataFrames =
                  layer.symbolSource.sourceType === SymbolSourceType.PointData
                    ? layer.symbolSource.pointDataFrames ?? []
                    : layer.dataFrames;
                const pointWithValue =
                  framePoints &&
                  playContext.isPlaying === PlaybackEnum.STOP &&
                  playContext.time === 0
                    ? framePoints
                      ? framePoints?.find(
                          (x) =>
                            x.lat.toFixed(2) === point.lat.toFixed(2) &&
                            x.lon.toFixed(2) === point.lon.toFixed(2),
                        )
                      : undefined
                    : frame.result?.symbolData?.points.find(
                        (x) => x.lat === point.lat && x.lon === point.lon,
                      );
                const weatherCode =
                  pointWithValue && pointWithValue.weatherType ? pointWithValue.weatherType : null;

                const icon = weatherCode
                  ? getIconForPoint(layer.symbolSource.iconPackId, weatherCode)
                  : null;

                return (
                  <div key={key}>
                    <div
                      id={key}
                      style={{
                        width: layer.symbolSource.defaultStyle.iconSize ?? 120,
                        height: layer.symbolSource.defaultStyle.iconSize ?? 120,
                      }}
                      className="overlay-weather-type"
                    >
                      {icon && (
                        <>
                          {icon.iconTemplate.jsonAnimation ? (
                            <LottieIcon versionId={icon.iconTemplate.versionId} inCanvas={true} />
                          ) : icon.iconTemplate.originalZipSequence ? (
                            <Sequence id={icon.iconTemplate.id} item={icon.iconTemplate} />
                          ) : (
                            <IconImage versionId={icon.iconTemplate.versionId} />
                          )}
                        </>
                      )}
                      {dataFrames?.length > 0 ? (
                        !pointWithValue ? (
                          <Loader color="white" />
                        ) : !weatherCode ? (
                          <img src={NoData} />
                        ) : null
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </>
  );
};

const IconImage = ({ versionId }: { versionId: string }) => {
  const { data: url } = useGetImageMultimedia(versionId, false);
  return (
    <img
      style={{ pointerEvents: 'none', height: '100%', width: '100%' }}
      src={url}
      loading={'eager'}
    />
  );
};
