import { PartialLottieComponentProps, useLottie } from 'lottie-react';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PlaybackEnum } from '../core/ui/enums/PlaybackEnum';
import { useMultimediaObjectUrl } from '../hooks/useMultimediaObjectUrl';
import { TimeControlDef } from '../model/definitions/TimeControlDef';
import playerContext from '../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../store/slices/active-slice';
import { RootState } from '../store/store';

interface LottieIconProps extends PartialLottieComponentProps {
  versionId: string;
  inCanvas?: boolean;
  time?: TimeControlDef[];
  parentStart?: number;
  frameRate?: number;
  repeat?: boolean;
}
export const LottieIconAnimation = ({
  versionId,
  inCanvas,
  time = [new TimeControlDef(0, 0)],
  parentStart = 0,
  frameRate = 25,
  repeat = false,
  ...rest
}: LottieIconProps) => {
  const { activeFramerate } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const url = useMultimediaObjectUrl(versionId, true);
  const { isPlaying, time: contextTime } = useContext(playerContext);
  const options = {
    ...rest,
    animationData: url, // Import your animation JSON file
    /**repeat null */
    loop: !!repeat,
    autoplay: true,
    frameRate: activeFramerate,
    className: 'max-h-full max-w-full contents',
  };
  const startMS = parentStart + time[0].startMS || 0;
  const { View, goToAndStop, goToAndPlay, pause, stop, animationItem } = useLottie(options);

  useEffect(() => {
    const currentFrame = Math.round(animationItem?.currentFrame ?? 0);
    if (isPlaying === PlaybackEnum.PLAYING) {
      if (contextTime >= startMS) {
        goToAndPlay(currentFrame ?? 0, true);
      } else {
        stop();
      }
    }
    if (isPlaying === PlaybackEnum.PAUSE) {
      pause();
      goToAndStop(
        Math.floor(
          ((contextTime - time[0].startMS) / activeFramerate) % (animationItem?.totalFrames ?? 0),
        ),
        true,
      );
    }
    if (isPlaying === PlaybackEnum.STOP) {
      goToAndStop(
        Math.floor(
          ((contextTime - (parentStart + time[0].startMS)) / activeFramerate) %
            (animationItem?.totalFrames ?? 0),
        ),
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextTime, frameRate, parentStart, repeat, isPlaying, startMS]);
  useEffect(() => {
    if (contextTime <= 0 && isPlaying !== PlaybackEnum.PLAYING) {
      goToAndStop(0, true);
    }
  }, [contextTime, goToAndStop, isPlaying]);
  return <>{View}</>;
};
