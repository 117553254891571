import './PaletteLegend.scss';
import './PaletteMenu.scss';

import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoDuplicateOutline } from 'react-icons/io5';
import { RiDeleteBin5Line } from 'react-icons/ri';

import { useGetPalette } from '../../core/api/mapLayers/useGetPalette';
import { useCreatePalette } from '../../core/api/palette/useCreatePalette';
import { useDeletePalette } from '../../core/api/palette/useDeletePalette';
import { ColorPaletteDef, PaletteSetupDegree } from '../../model/definitions/ColorPaletteDef';
import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { PickerDef } from '../../model/definitions/PickerDef';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import { CustomPaletteDTO } from '../../model/DTO/CustomPaletteDTO';
import { ColorPaletteParamTypeEnum } from '../../model/enums/ColorPaletteParamTypeEnum';
import { PaletteLegendScalingEnum } from '../../model/enums/PaletteLegendScalingEnum';
import { VisualisationTypeEnum } from '../../model/enums/VisualisationTypeEnum';
import { CustomLegend } from '../../pages/playground/properties/components/custom-palette/CustomLegend';
import { isGribLayerType } from '../../pages/playground/properties/mapLayersProperties/MapGribLayers';
import { getGradient } from './utils';

interface PaletteRefactorProps {
  parameter: ColorPaletteParamTypeEnum;
  value: ColorPaletteDef | null;
  setPalette: (palette: ColorPaletteDef) => void;
  layerEdit?: GribMapLayer | RadarMapLayer | SatelliteMapLayer;
  editPalette?: (e: string) => void;
  source?: PickerDef;
  visualisationType?: VisualisationTypeEnum;
  width?: number;
  dataProductId: string | null;
  enterpriseAccountId?: string;
}
export const generateSetup = (colorStops: any) => {
  const degrees: Array<PaletteSetupDegree> = Object.keys(colorStops.pallet).map((key) => {
    return {
      degree: Number(key),
      color: colorStops.pallet[key as unknown as number],
      active: true,
      interpolationSteps: 0,
    };
  });
  return {
    defaultStep: 0,
    scale: degrees.sort((a, b) => a.degree - b.degree),
  };
};
export const PaletteRefactor = ({
  parameter,
  value,
  setPalette,
  layerEdit,
  editPalette,
  source,
  visualisationType,
  dataProductId,
  enterpriseAccountId,
}: PaletteRefactorProps) => {
  const {
    data: palettes,
    isSuccess,
    isLoading: isLoadingQuery,
    refetch: refetchPalette,
  } = useGetPalette(parameter, dataProductId, source?.name, visualisationType, enterpriseAccountId);
  const { mutate: deletePalette, isLoading: isLoadingDelete } = useDeletePalette();
  const { mutate: createPalette } = useCreatePalette();
  function onChangePalette(palette: ColorPaletteDef) {
    if (!palette.setup) {
      palette.setup = generateSetup(palette.colorStops);
    }
    if (value && value.colorStops)
      setPalette({
        ...palette,
        colorStops: { ...palette.colorStops, interval: value.colorStops?.interval },
      });
    else setPalette(palette);
  }

  useEffect(() => {
    if (palettes) {
      const palleteToSet = palettes.find((p) => p.name === 'Default') || palettes[0];
      const findPalette = palettes.find((p) => p.id === layerEdit?.layerSetup?.colorPaletteDef?.id);

      if (!layerEdit) {
        setPalette(palleteToSet);
      } else if (layerEdit) {
        if (isGribLayerType(layerEdit) && !findPalette) {
          onChangePalette(palettes[0]);
        } else if (
          visualisationType &&
          layerEdit.layerSetup?.visualisationType !== visualisationType
        ) {
          setPalette(palleteToSet);
        } else {
          layerEdit.layerSetup?.colorPaletteDef && setPalette(layerEdit.layerSetup.colorPaletteDef);
        }
      }
    }
  }, [isSuccess, parameter, source]);

  useEffect(() => {
    if (parameter && source) refetchPalette();
  }, [parameter, source, visualisationType]);
  const clonePalette = (pal: ColorPaletteDef) => {
    const clonePalette = cloneDeep(pal);
    const { id, versionId, ...paletteWithoutIds } = clonePalette;
    if (id && versionId) {
      createPalette(paletteWithoutIds as CustomPaletteDTO);
    }
  };
  const generatePalettePreview = (pal: ColorPaletteDef) => {
    const gradient = getGradient(pal);
    return (
      <div
        key={pal.id}
        className={`palette-item ${value?.id === pal.id ? ' selected' : ''}`}
        onClick={() => onChangePalette(pal)}
      >
        <div
          className="preview"
          style={{
            background: `linear-gradient(to right, ${gradient.join(',')})`,
          }}
        />
        <span>{pal.name}</span>
        <div className={'flex'}>
          {pal.editableByUser && !pal.enterpriseAccountId && (
            <>
              <RiDeleteBin5Line
                color={'red'}
                onClick={() => !isLoadingDelete && !isLoadingQuery && deletePalette(pal.id)}
              />
              <IoDuplicateOutline color={'green'} onClick={() => clonePalette(pal)} />
            </>
          )}
          <AiOutlineEdit color={'yellow'} onClick={() => editPalette && editPalette(pal.id)} />
        </div>
      </div>
    );
  };
  const generatePalettes = () => {
    if (!isSuccess) return null;
    return palettes.map((palette) => generatePalettePreview(palette));
  };

  return (
    <div className={'grid grid-cols-2 items-center'}>
      {value && <div className="palette-menu">{generatePalettes()}</div>}
      {value && value.setup && (
        <div>
          <CustomLegend
            setup={value.setup}
            proportional={PaletteLegendScalingEnum.HOMOGENOUS}
            key={JSON.stringify(value)}
            w={500}
          />
        </div>
      )}
      {value && !value.setup && (
        <div>
          <CustomLegend
            setup={generateSetup(value.colorStops)}
            proportional={PaletteLegendScalingEnum.HOMOGENOUS}
            key={JSON.stringify(value)}
            w={500}
          />
        </div>
      )}
    </div>
  );
};
