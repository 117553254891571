import { KeyboardEvent, useState } from 'react';
import { IoLinkOutline, IoUnlinkOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';

import { updateProportional } from '../../../../helpers/resizableHelpers';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { MAX_ZINDEX_VALUE } from '../../../../model/constants/constants';
import { PositionControlDef } from '../../../../model/definitions/PositionControlDef';
import { SceneDef } from '../../../../model/definitions/SceneDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../store/slices/active-slice';
import {
  updateIndicatorPosition,
  updateMapOverlay,
  updatePosition,
} from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import InputNumber from '../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { PropertySection } from '../components/PropertySection';
import { positionControlPropertiesDeps } from '../constants/propertiesConstants';
import styles from '../Properties.module.scss';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

interface PositionControlsProps {
  position: PositionControlDef;
  layer: SceneKeys<SceneDef> | 'mapTimeframeTextIndicator';
  poster?: string;
  lockedRatio?: boolean;
  lockRatio?: (e: boolean) => void;
  mapId?: string;
}

export const PositionControls = ({
  position,
  layer,
  poster,
  lockedRatio,
  mapId,
  lockRatio,
}: PositionControlsProps) => {
  const { activeScene, activeElement, activePoster } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const dispatch = useDispatch();
  const { x, y, h, w, zindex, rotation } = position;
  const [internalLockedRatio, setInternalLockedRatio] = useState<boolean>(lockedRatio ?? false);
  const { lastFocused, isOpened } = usePropertyGridActive(positionControlPropertiesDeps);
  const onPositionChange = (name: string, val: string) => {
    if (!val) return;
    const newPos = { ...position, [name]: Number(val) };
    if (lockedRatio || (lockedRatio === undefined && internalLockedRatio)) {
      if (name === 'w') {
        const recalculate = updateProportional(position.w, position.h);
        newPos.h = recalculate(Number(val));
      } else if (name === 'h') {
        const recalculate = updateProportional(position.h, position.w);
        newPos.w = recalculate(Number(val));
      }
    }

    if (layer === 'mapTimeframeTextIndicator')
      dispatch(
        updateIndicatorPosition({
          activeScene: activeScene as string,
          position: newPos,
          mapId: poster,
        }),
      );
    else if (mapId && !poster && !activePoster) {
      dispatch(
        updateMapOverlay({
          position: newPos,
          activeScene,
          elementType: layer,
          elementId: activeElement as string,
          mapId,
        }),
      );
    } else {
      dispatch(
        updatePosition({
          position: newPos,
          activeScene,
          elementType: layer,
          elementId: activeElement as string,
          isPoster: !!activePoster,
          posterId: activePoster,
        }),
      );
    }
  };
  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event?.shiftKey) {
      event.currentTarget.step = '0.01';
    } else {
      event.currentTarget.step = '0.1';
    }
  }
  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  const handleRatioLock = () => {
    if (lockedRatio === undefined) {
      setInternalLockedRatio(!internalLockedRatio);
    }
    console.log(!lockedRatio);
    lockRatio && lockRatio(!lockedRatio ?? true);
  };

  return (
    <PropertySection label={'Position'} isOpened={isOpened}>
      <div className="prop-wrapper">
        <GridWrapper>
          <GridItem
            label="Left:"
            item={
              <input
                value={x}
                autoFocus={lastFocused === 'x'}
                type={'number'}
                onChange={(e) => onPositionChange('x', e.target.value)}
                className={styles.inputWrap}
                step={0.1}
                onKeyDown={handleKeyDown}
                onFocus={() => onFocus('x')}
              />
            }
          />
          <GridItem
            label="Top:"
            item={
              <input
                value={y}
                type={'number'}
                autoFocus={lastFocused === 'y'}
                onChange={(e) => onPositionChange('y', e.target.value)}
                className={styles.inputWrap}
                step={0.1}
                onKeyDown={handleKeyDown}
                onFocus={() => onFocus('y')}
              />
            }
          />
          <GridItem
            label="Width:"
            item={
              <input
                value={w}
                type={'number'}
                autoFocus={lastFocused === 'w'}
                onChange={(e) => onPositionChange('w', e.target.value)}
                className={styles.inputWrap}
                step={0.1}
                onKeyDown={handleKeyDown}
                onFocus={() => onFocus('w')}
              />
            }
          />
          <GridItem
            label={
              <div
                className={'flex items-center justify-between cursor-pointer'}
                onClick={handleRatioLock}
              >
                {lockedRatio !== undefined &&
                  (lockedRatio ? (
                    <IoLinkOutline style={{ color: 'green' }} />
                  ) : (
                    <IoUnlinkOutline />
                  ))}
                {lockedRatio === undefined && (
                  <span onClick={handleRatioLock}>
                    {internalLockedRatio ? (
                      <IoLinkOutline style={{ color: 'green' }} />
                    ) : (
                      <IoUnlinkOutline />
                    )}
                  </span>
                )}
                Height:
              </div>
            }
            item={
              <input
                value={h}
                type={'number'}
                autoFocus={lastFocused === 'h'}
                onChange={(e) => onPositionChange('h', e.target.value)}
                className={styles.inputWrap}
                step={0.1}
                onKeyDown={handleKeyDown}
                onFocus={() => onFocus('h')}
              />
            }
          />
          <GridItem
            label="Layer level:"
            item={
              <InputNumber
                value={zindex}
                autoFocus={lastFocused === 'zindex'}
                onInputChange={(e) => onPositionChange('zindex', e)}
                className={styles.inputWrap}
                onFocus={() => onFocus('zindex')}
                min={0}
                max={MAX_ZINDEX_VALUE}
                step={1}
              />
            }
          />
          {layer != 'mapPanels' && (
            <GridItem
              label="Rotation:"
              item={
                <input
                  value={rotation}
                  type={'number'}
                  autoFocus={lastFocused === 'rotation'}
                  onChange={(e) => onPositionChange('rotation', e.target.value)}
                  className={styles.inputWrap}
                  step={1}
                  onKeyDown={handleKeyDown}
                  onFocus={() => onFocus('rotation')}
                />
              }
            />
          )}
        </GridWrapper>
      </div>
    </PropertySection>
  );
};
