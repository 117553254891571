import { useSlate } from 'slate-react';

import { useFontLoader, useFontSetter } from '../../../../../core/api/useLoadFont';
import { FontInterface, FontVariantInterface } from '../../panels/FontProperties';
import Button from './Button';
import Icon from './Icon';
import { activeMark, addMarkData, isMarkActive, toggleMark } from './SlateUtilityFunctions';
import defaultToolbarGroups from './toolbarGroups';
import transformText from './transformText';

export interface SlateMarkButtonInterface {
  id: number;
  format: string;
  type: string;
  key: number;
}

const Toolbar = ({ isSelected }: { isSelected: boolean }) => {
  const editor = useSlate();

  const { fonts, fontVariants, fetchFontVariants } = useFontLoader(
    activeMark(editor, 'fontFamily'),
  );
  useFontSetter(
    fontVariants,
    activeMark(editor, 'fontType'),
    fontTypeSetter,
    fontVariantIdSetter,
    fetchFontVariants.isLoading,
  );

  function fontTypeSetter(value: string) {
    addMarkData(editor, { format: 'fontType', value });
  }

  function fontVariantIdSetter(value: string) {
    addMarkData(editor, { format: 'fontVariantId', value });
  }

  const MarkButton = ({ format, isSelected }: { format: string; isSelected: boolean }) => {
    return (
      <Button
        active={isMarkActive(editor, format)}
        format={format}
        onMouseDown={(e: MouseEvent) => {
          e.preventDefault();
          toggleMark(editor, format);
        }}
        disabled={!isSelected}
      >
        <Icon icon={format} />
      </Button>
    );
  };
  const changeMarkData = (event: any, format: any) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };
  const Dropdown = ({ isSelected }: { isSelected: boolean }) => {
    return (
      <div className="text-white overflow-hidden flex gap-4 w-1/2">
        <div className="bg-[#060b12] border border-[#2b3441] w-full">
          <select
            className="w-full"
            value={activeMark(editor, 'fontFamily')}
            onChange={(e) => {
              changeMarkData(e, 'fontFamily');
              addMarkData(editor, { format: 'fontType', value: '' });
              addMarkData(editor, { format: 'fontVariantId', value: '' });
            }}
            disabled={!isSelected}
          >
            {fonts.map((font: FontInterface) => (
              <option key={font.id} value={font.name}>
                {font.name}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-[#060b12] border border-[#2b3441] w-full">
          <select
            className="w-full"
            value={activeMark(editor, 'fontType')}
            onChange={(e) => {
              const fontVariant = fontVariants.find(
                (fontVariant: FontVariantInterface) => fontVariant.type === e.target.value,
              );
              changeMarkData(e, 'fontType');
              addMarkData(editor, { format: 'fontVariantId', value: fontVariant.id });
            }}
            disabled={!isSelected}
          >
            {fontVariants?.map((fontVariant: FontVariantInterface) => (
              <option key={fontVariant.id} value={fontVariant.type}>
                {fontVariant.type}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-[#060b12] border border-[#2b3441] w-full">
          <select
            className="w-full"
            value={activeMark(editor, 'textTransform')}
            onChange={(e) => {
              changeMarkData(e, 'textTransform');
            }}
            disabled={!isSelected}
          >
            {transformText.map((item) => (
              <option key={item.id} value={item.format}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center justify-start">
      <div className="w-fit mr-3">
        {defaultToolbarGroups.map((group, index) => (
          <span key={index}>
            {group.map((element) => {
              return (
                <MarkButton key={element.id} format={element.format} isSelected={isSelected} />
              );
            })}
          </span>
        ))}
      </div>
      <Dropdown isSelected={isSelected} />
    </div>
  );
};

export default Toolbar;
