import './style.scss';

import { Tooltip } from 'antd';
import JSZip from 'jszip';
import { ResizeDirection } from 're-resizable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdOutlineDragHandle } from 'react-icons/md';
import { TbChevronsDownRight, TbChevronsUpRight } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { absoluteToPercent, getNumber, getPercentFromData } from '../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { GribMapLayer } from '../../../model/definitions/GribMapLayer';
import { RadarMapLayer } from '../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../model/definitions/SatelliteMapLayer';
import { SymbolLayerDef } from '../../../model/definitions/SymbolLayerDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../../../model/enums/AnimationsEnum';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { LaneProps } from '../../../model/UI/LaneProps';
import { useGetSequenceZip } from '../../../pages/dashboard/queries-NEW/useGetSequenceZip';
import { ActiveDef, setElement } from '../../../store/slices/active-slice';
import {
  multiUpdateTime,
  updateElementTime,
  updateGeoPosterElementTime,
  updateMapGRSLayer,
  updatePosterElementTime,
} from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import { elementEnumToKey } from '../helpers';

const Lane = ({
  segments = [new TimeControlDef()],
  element,
  elementId,
  type,
  scenes,
  duration,
  frames,
  disabled,
  parentTime,
  parentId,
  inPoster,
  isGeo,
  mapId,
  ...props
}: LaneProps) => {
  function framesToMilliseconds(frames: number, framerate: number) {
    if (frames === 0) {
      return 0; // Return 0 milliseconds if frames is 0
    }
    return frames / framerate;
  }
  const { width, ref } = useResizeDetector();
  const { activeScene, mode, activeZoom, posterOpen, activeElement, activeFramerate, multiselect } =
    useSelector<RootState>((state) => state.active) as ActiveDef;
  const elemSection =
    type && multiselect.find((section) => section.type === elementEnumToKey(type));
  const selected = elemSection?.elements.find((listItem) => listItem.element.id === elementId);
  const isOpen = posterOpen.findIndex((value) => value === parentId) > -1 || !parentId;
  const [sequence, setSequence] = useState<string | undefined>();
  const { data } = useGetSequenceZip(sequence);
  const [introImages, setIntroImages] = useState<number>(0);
  const [mainImages, setMainImages] = useState<number>(0);
  const [outroImages, setOutroImages] = useState<number>(0);
  const [isExtracted, setExtracted] = useState(false);
  useEffect(() => {
    if (data && (mainImages < 1 || introImages < 1)) {
      !isExtracted && sequence && data && handleZipData.then(() => setExtracted(true));
    }
  }, [data, introImages, isExtracted, mainImages, sequence]);
  const isLoopRef = useRef<boolean>(false);
  const seqBG = useCallback(
    (item: any): string => {
      const introDuration = framesToMilliseconds(introImages, activeFramerate); //introImages ? (1000 / (introImages * activeFramerate)) * 10 : 0;
      const outroDuration = framesToMilliseconds(outroImages, activeFramerate); //outroImages ? (1000 / (outroImages * activeFramerate)) * 10 : 0;
      const mainDuration = framesToMilliseconds(mainImages, activeFramerate); //Math.round((1000 / (mainImages * activeFramerate)) * 10 * 10) / 10;
      const intro = (introDuration * (width ?? 0)) / (duration / 1000);
      const main = (mainDuration * (width ?? 0)) / (duration / 1000);
      const outro = (outroDuration * (width ?? 0)) / (duration / 1000);
      const totalDuration = (segments[0].endMS - segments[0].startMS) / 1000;
      const laneLength = ((totalDuration * (width ?? 0)) / duration) * 1000;
      let mainLength: number;
      if (introDuration + mainDuration + outroDuration <= totalDuration) {
        mainLength = Math.round((totalDuration - (introDuration + outroDuration)) * 100) / 100;
      } else {
        const outroLength =
          totalDuration - (introDuration + mainDuration) > 0
            ? totalDuration - (introDuration + mainDuration)
            : 0;
        mainLength = Math.round((totalDuration - (introDuration + outroLength)) * 100) / 100;
      }
      isLoopRef.current = (mainLength * 100) % (mainDuration * 100) === 0;
      return sequence
        ? `linear-gradient(90deg, #00B300 ${intro}px, ${
            isLoopRef.current ? 'rgb(0, 128, 0)' : '#800080'
          } ${intro}px, ${isLoopRef.current ? 'rgb(0, 128, 0)' : '#800080'} ${
            intro + main > laneLength - outro ? intro + main : laneLength - outro
          }px, #00B300 ${intro + main > laneLength - outro ? intro + main : laneLength - outro}px)`
        : element.enabled
        ? `linear-gradient( 90deg, hsla(${(element?.volume ?? 1) * 120},${
            (element?.volume ?? 1) * 100
          }%,25%, 0) 0%,
             hsla(${(element?.volume ?? 1) * 120},${(element?.volume ?? 1) * 100}%,25%, 0.99) ${
            item.inAnimationDef !== AnimationsEnum.CUT
              ? (item.inAnimationDuration * 100) /
                ((item.endMS * duration) / 100 - (item.startMS * duration) / 100)
              : 0
          }%,
               hsl(${(element?.volume ?? 1) * 120},${(element?.volume ?? 1) * 100}%,25%) ${
            item.inAnimationDef !== AnimationsEnum.CUT
              ? (item.inAnimationDuration * 100) / duration
              : 0
          }%,    hsl(${(element?.volume ?? 1) * 120},${(element?.volume ?? 1) * 100}%,25%) ${
            item.outAnimationDef !== AnimationsEnum.CUT
              ? 100 -
                (item.outAnimationDuration * 100) /
                  ((item.endMS * duration) / 100 - (item.startMS * duration) / 100)
              : 100
          }%, hsla(${(element?.volume ?? 1) * 120},${(element?.volume ?? 1) * 100}%,25%, 0.99) ${
            item.outAnimationDef !== AnimationsEnum.CUT
              ? 100 -
                (item.outAnimationDuration * 100) /
                  ((item.endMS * duration) / 100 - (item.startMS * duration) / 100)
              : 100
          }%, hsla(${(element?.volume ?? 1) * 120},${
            (element?.volume ?? 1) * 100
          }%,25%, 0) 100%, red 0)`
        : 'rgb(96, 136, 113, 0.2)';
    },
    [
      activeFramerate,
      duration,
      element.enabled,
      element?.volume,
      introImages,
      mainImages,
      outroImages,
      segments,
      sequence,
      width,
    ],
  );
  const handleZipData = useMemo(async () => {
    let introImg = 0;
    let outroImg = 0;
    let mainImg = 0;
    try {
      const zip = new JSZip();
      data && (await zip.loadAsync(data));
      const fileArray: [string, JSZip.JSZipObject][] = Object.entries(zip.files);
      fileArray.sort((a, b) => {
        const filenameA = a[0].split('/').pop() || '';
        const filenameB = b[0].split('/').pop() || '';
        return filenameA.localeCompare(filenameB, undefined, { numeric: true });
      });
      for (const [filename, entry] of fileArray) {
        const ext = filename.split('.').pop()?.toLowerCase();
        if (
          !entry.dir &&
          entry.name.includes('3_outro') &&
          ['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext || '') &&
          filename.indexOf('__MACOSX') === -1
        ) {
          outroImg++;
        }
        if (
          !entry.dir &&
          entry.name.includes('1_intro') &&
          ['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext || '') &&
          filename.indexOf('__MACOSX') === -1
        ) {
          introImg++;
        }
        if (
          !entry.dir &&
          entry.name.includes('2_main') &&
          ['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext || '') &&
          filename.indexOf('__MACOSX') === -1
        ) {
          mainImg++;
        }
      }
    } catch (error) {
      console.error('Error processing the zip file:', error);
    }
    setIntroImages(introImg);
    setOutroImages(outroImg);
    setMainImages(mainImg);
  }, [data]);
  useEffect(() => {
    if (
      element.observedWDSource &&
      element.observedWDSource.valueType === ValueTypeEnum.IMAGE &&
      element.observedWDSource.value?.originalZipSequence
    ) {
      setSequence(element.observedWDSource.value.versionId);
    }
    if (
      element.forecastWDSource &&
      element.forecastWDSource.valueType === ValueTypeEnum.IMAGE &&
      element.forecastWDSource.value?.originalZipSequence
    ) {
      setSequence(element.forecastWDSource.value.versionId);
    }
  }, [element.observedWDSource, element.forecastWDSource]);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const actualScene = project.sceneDefs.find((scenes) => scenes.id === activeScene);
  const scene = actualScene?.mapPanels.find((map) => map.id === elementId);
  const gribMapLayers = scene?.wdSpace[0].gribMapLayers;
  const radarMapLayers = scene?.wdSpace[0].radarMapLayers;
  const satelliteMapLayers = scene?.wdSpace[0].satelliteMapLayers;
  const symbolLayers = scene?.wdSpace[0].symbolLayers;
  const [pieces, setPieces] = useState<Array<TimeControlDef>>(segments);
  const currentScene = useSelector<RootState>((state) => state.active.activeScene) as string;
  const dispatch = useDispatch();
  const onLaneClick = () => {
    multiselect.length < 1 &&
      activeElement !== elementId &&
      type &&
      dispatch(setElement({ activeElement: elementId, activeProp: elementEnumToKey(type) }));
  };
  function framesToTimecode(frames: number, framerate: number) {
    const totalSeconds = frames / framerate;
    const wholeSeconds = Math.floor(totalSeconds);
    const remainingFrames = frames % framerate;

    return `${wholeSeconds}:${remainingFrames.toString().padStart(2, '0')}`;
  }
  useEffect(() => {
    setPieces(absoluteToPercent(segments, duration, parentTime?.startMS, parentTime?.endMS));
  }, [segments, activeZoom, width, mode, duration, parentTime, isOpen]);

  const getPercentFromRef = (position: Position, ref: HTMLElement): number => {
    return (position.x * 100) / ref.offsetParent!.clientWidth;
  };
  const getPercentFromDelta = (data: ResizableDelta, ref: HTMLElement) => {
    return (data.width * 100) / ref.offsetParent!.clientWidth;
  };
  const percentToTime = (percent: number) => {
    return Math.round((duration * percent) / 100);
  };
  const dragEnded = (percent: number, type: ElementsEnum, index: number) => {
    const newPieces = [...pieces];
    const width = newPieces[index].endMS - newPieces[index].startMS;
    if (inPoster && parentTime) {
      if (percent < absoluteToPercent([parentTime], duration)[0].startMS) {
        newPieces[index].startMS = absoluteToPercent([parentTime], duration)[0].startMS;
        newPieces[index].endMS = absoluteToPercent([parentTime], duration)[0].startMS + width;
      } else if (percent + width > absoluteToPercent([parentTime], duration)[0].endMS) {
        newPieces[index].endMS = absoluteToPercent([parentTime], duration)[0].endMS;
        newPieces[index].startMS = absoluteToPercent([parentTime], duration)[0].endMS - width;
      } else {
        newPieces[index].startMS = percent;
        newPieces[index].endMS = percent + width;
      }
    } else if (newPieces[index - 1] && newPieces[index - 1].endMS > percent) {
      newPieces[index].startMS = newPieces[index - 1].endMS;
      newPieces[index].endMS = newPieces[index - 1].endMS + width;
    } else if (newPieces[index + 1] && newPieces[index + 1].startMS < percent + width) {
      newPieces[index].startMS = newPieces[index + 1].startMS - width;
      newPieces[index].endMS = newPieces[index + 1].startMS;
    } else {
      newPieces[index].startMS = percent;
      newPieces[index].endMS = percent + width;
    }
    setPieces(newPieces);
    const newTimes: TimeControlDef[] = [];
    const startDiff =
      percentToTime(newPieces[index].startMS) -
      segments[index].startMS -
      (parentTime?.startMS || 0);
    const endDiff = percentToTime(newPieces[index].endMS) - segments[index].endMS;
    multiUpdate(startDiff, endDiff, 'drag');
    newPieces.forEach((item) => {
      newTimes.push({
        ...item,
        endMS: percentToTime(item.endMS),
        startMS: percentToTime(item.startMS) - (parentTime?.startMS ?? 0),
      });
    });
    !parentTime &&
      gribMapLayers?.forEach((layer: GribMapLayer, index: number) => {
        let time = layer.timeControls[0];
        time = {
          ...time,
          startMS: time.startMS + (newTimes[0].startMS - segments[0].startMS),
          endMS: time.endMS + (newTimes[0].endMS - segments[0].endMS),
        };
        dispatch(
          updateMapGRSLayer({
            time,
            layerType: 'gribMapLayers',
            index,
            mapId: elementId,
            activeScene: activeScene,
          }),
        );
      });
    !parentTime &&
      radarMapLayers?.forEach((layer: RadarMapLayer, index: number) => {
        let time = layer.timeControls[0];
        time = {
          ...time,
          startMS: time.startMS + (newTimes[0].startMS - segments[0].startMS),
          endMS: time.endMS + (newTimes[0].endMS - segments[0].endMS),
        };
        dispatch(
          updateMapGRSLayer({
            time,
            layerType: 'radarMapLayers',
            index,
            mapId: elementId,
            activeScene: activeScene,
          }),
        );
      });
    !parentTime &&
      satelliteMapLayers?.forEach((layer: SatelliteMapLayer, index: number) => {
        let time = layer.timeControls[0];
        time = {
          ...time,
          startMS: time.startMS + (newTimes[0].startMS - segments[0].startMS),
          endMS: time.endMS + (newTimes[0].endMS - segments[0].endMS),
        };
        dispatch(
          updateMapGRSLayer({
            time,
            layerType: 'satelliteMapLayers',
            index,
            mapId: elementId,
            activeScene: activeScene,
          }),
        );
      });
    !parentTime &&
      symbolLayers?.forEach((layer: SymbolLayerDef, index: number) => {
        let time = layer.timeControls[0];
        time = {
          ...time,
          startMS: time.startMS + (newTimes[0].startMS - segments[0].startMS),
          endMS: time.endMS + (newTimes[0].endMS - segments[0].endMS),
        };
        dispatch(
          updateMapGRSLayer({
            time,
            layerType: 'symbolLayers',
            index,
            mapId: elementId,
            activeScene: activeScene,
          }),
        );
      });
    parentTime &&
      parentId &&
      inPoster &&
      dispatch(
        updatePosterElementTime({
          time: newTimes.map((time) => {
            return {
              ...time,
              startMS: time.startMS < 0 ? 0 : time.startMS,
            };
          }),
          elementId: elementId,
          elementType: type,
          activeScene: currentScene,
          posterId: parentId,
        }),
      );
    parentTime &&
      parentId &&
      inPoster &&
      isGeo &&
      mapId &&
      dispatch(
        updateGeoPosterElementTime({
          time: newTimes,
          elementId: elementId,
          elementType: type,
          activeScene: currentScene,
          posterId: parentId,
          mapId: mapId,
        }),
      );
    !parentTime &&
      !inPoster &&
      dispatch(
        updateElementTime({
          time: newTimes,
          elementId: elementId,
          elementType: type,
          activeScene: currentScene,
        }),
      );
  };
  const layerTime = (
    layer: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef,
    newTimes: TimeControlDef,
  ) => {
    let time = layer.timeControls[0];
    if (newTimes.startMS > layer.timeControls[0].startMS) {
      time = { ...time, startMS: newTimes.startMS };
    }
    if (newTimes.endMS < layer.timeControls[0].endMS) {
      time = { ...time, endMS: newTimes.endMS };
    }
    return time;
  };
  const multiUpdate = (diffStart = 0, diffEnd = 0, movement: 'drag' | 'resize') => {
    selected &&
      actualScene &&
      dispatch(
        multiUpdateTime({
          activeScene,
          selection: multiselect,
          start: diffStart,
          end: diffEnd,
          movement,
          sceneLength: actualScene?.durationInMS,
        }),
      );
  };
  const resizeStopped = (
    position: Position,
    delta: ResizableDelta,
    ref: HTMLElement,
    direction: ResizeDirection,
    type: ElementsEnum,
    index: number,
  ): void => {
    const newPieces = [...pieces];
    if (direction === 'left') {
      if (
        parentTime &&
        (inPoster || isGeo) &&
        newPieces[index - 1] &&
        newPieces[index - 1].endMS > getPercentFromRef(position, ref)
      ) {
        newPieces[index].startMS =
          getPercentFromRef(position, ref) < absoluteToPercent([parentTime], duration)[0].startMS
            ? absoluteToPercent([parentTime], duration)[0].startMS
            : getPercentFromRef(position, ref);
      } else if (
        newPieces[index - 1] &&
        newPieces[index - 1].endMS > getPercentFromRef(position, ref)
      ) {
        newPieces[index].startMS = newPieces[index - 1].endMS;
      } else {
        newPieces[index].startMS = getPercentFromRef(position, ref);
      }
    }
    if (direction === 'right') {
      if (
        parentTime &&
        newPieces[index + 1] &&
        newPieces[index + 1].startMS < newPieces[index].endMS + getPercentFromDelta(delta, ref)
      ) {
        newPieces[index].endMS =
          getPercentFromRef(position, ref) > absoluteToPercent([parentTime], duration)[0].endMS
            ? absoluteToPercent([parentTime], duration)[0].endMS
            : getPercentFromRef(position, ref);
      } else if (
        newPieces[index + 1] &&
        newPieces[index + 1].startMS < newPieces[index].endMS + getPercentFromDelta(delta, ref)
      ) {
        newPieces[index].endMS = newPieces[index + 1].startMS;
      } else {
        newPieces[index].endMS += getPercentFromDelta(delta, ref);
      }
    }
    const startDiff =
      percentToTime(newPieces[index].startMS) -
      segments[index].startMS -
      (parentTime?.startMS || 0);
    const endDiff = percentToTime(newPieces[index].endMS) - segments[index].endMS;
    multiUpdate(startDiff, endDiff, 'resize');
    setPieces(newPieces);
    const newTimes: TimeControlDef[] = [];
    newPieces.forEach((item) => {
      newTimes.push({
        ...item,
        endMS: percentToTime(item.endMS),
        startMS: percentToTime(item.startMS),
      });
    });
    gribMapLayers?.forEach((layer: GribMapLayer, index: number) => {
      const time = layerTime(layer, newTimes[0]);
      dispatch(
        updateMapGRSLayer({
          time,
          layerType: 'gribMapLayers',
          index,
          mapId: elementId,
          activeScene: activeScene,
        }),
      );
    });
    radarMapLayers?.forEach((layer: RadarMapLayer, index: number) => {
      const time = layerTime(layer, newTimes[0]);
      dispatch(
        updateMapGRSLayer({
          time,
          layerType: 'radarMapLayers',
          index,
          mapId: elementId,
          activeScene: activeScene,
        }),
      );
    });
    satelliteMapLayers?.forEach((layer: SatelliteMapLayer, index: number) => {
      const time = layerTime(layer, newTimes[0]);
      dispatch(
        updateMapGRSLayer({
          time,
          layerType: 'satelliteMapLayers',
          index,
          mapId: elementId,
          activeScene: activeScene,
        }),
      );
    });
    symbolLayers?.forEach((layer: SymbolLayerDef, index: number) => {
      const time = layerTime(layer, newTimes[0]);
      dispatch(
        updateMapGRSLayer({
          time,
          layerType: 'symbolLayers',
          index,
          mapId: elementId,
          activeScene: activeScene,
        }),
      );
    });
    parentTime &&
      parentId &&
      inPoster &&
      !isGeo &&
      multiselect.length <= 0 &&
      dispatch(
        updatePosterElementTime({
          time: newTimes.map((time) => {
            return {
              ...time,
              startMS:
                time.startMS - parentTime.startMS < 0 ? 0 : time.startMS - parentTime.startMS,
            };
          }),
          elementId: elementId,
          elementType: type,
          activeScene: currentScene,
          posterId: parentId,
        }),
      );
    parentTime &&
      parentId &&
      inPoster &&
      isGeo &&
      mapId &&
      multiselect.length < 1 &&
      dispatch(
        updateGeoPosterElementTime({
          time: newTimes,
          elementId: elementId,
          elementType: type,
          activeScene: currentScene,
          posterId: parentId,
          mapId: mapId,
        }),
      );
    !disabled &&
      !parentId &&
      dispatch(
        updateElementTime({
          time: newTimes,
          elementId: elementId,
          elementType: type,
          activeScene: currentScene,
        }),
      );
  };
  const onDelete = (e: MouseEvent, index: number) => {
    e.preventDefault();
    if (e.type === 'dblclick' && type && segments?.length > 1) {
      const time = [...segments];
      time.splice(index, 1);
      dispatch(
        updateElementTime({
          time: time,
          elementId: elementId,
          elementType: type,
          activeScene: currentScene,
        }),
      );
    }
  };

  const all = () =>
    pieces.map((item, index) => (
      <Rnd
        onClick={onLaneClick}
        key={`${elementId}${index}${element.enabled}`}
        onDoubleClick={(e: MouseEvent) => onDelete(e, index)}
        enableResizing={
          disabled
            ? !disabled
            : !(element?.flyOver?.keyFrames && element.flyOver?.keyFrames.length > 0)
            ? {
                left: true,
                right: true,
              }
            : false
        }
        minHeight={parentTime ? 12 : 24}
        dragAxis={'x'}
        resizeHandleComponent={{
          right: disabled ? undefined : (
            <MdOutlineDragHandle
              size={24}
              color={'rgb(96, 136, 113)'}
              style={{
                transform: 'rotate(90deg) translateY(12px)',
                height: '100%',
              }}
            />
          ),
          left: disabled ? undefined : (
            <MdOutlineDragHandle
              size={24}
              color={'rgb(96, 136, 113)'}
              style={{
                transform: 'rotate(90deg) translateY(2px)',
                height: '100%',
              }}
            />
          ),
        }}
        disableDragging={
          (element?.flyOver?.keyFrames && element.flyOver?.keyFrames.length > 0) || disabled
        }
        title={`${percentToTime(item.startMS) / 1000}s - ${percentToTime(item.endMS) / 1000}s`}
        className={`segment check-class group`}
        style={{
          background: seqBG(item),
          height: parentTime ? 12 : 24,
          minHeight: parentTime ? 12 : 24,
          top: parentTime ? 12 : 0,
        }}
        maxHeight={parentTime ? 12 : 24}
        bounds={'parent'}
        position={{
          x: getNumber(item.startMS, width),
          y: 0,
        }}
        size={{
          width: parentTime
            ? item.endMS > absoluteToPercent([parentTime], duration)[0].endMS
              ? absoluteToPercent([parentTime], duration)[0].endMS - item.startMS + '%'
              : item.endMS - item.startMS + '%'
            : item.endMS - item.startMS + '%',
          height: parentTime ? 12 : 24,
        }}
        onDragStop={(e, data) => {
          e && dragEnded(getPercentFromData(data, width), type!, index);
        }}
        onResizeStop={(e, direction, ref, delta, position) =>
          resizeStopped(position, delta, ref, direction, type!, index)
        }
      >
        {item.inAnimationDef === AnimationsEnum.FADE_IN && <TbChevronsUpRight />}
        {item.outAnimationDef === AnimationsEnum.FADE_OUT && (
          <TbChevronsDownRight style={{ right: 10, position: 'absolute' }} />
        )}
        {!isLoopRef.current && sequence && (
          <>
            <Tooltip
              className={'w-full'}
              title={
                <span className={'whitespace-nowrap'}>
                  Loop duration: <br />
                  {framesToTimecode(mainImages, activeFramerate)}
                </span>
              }
            >
              <span className={'absolute left-0 right-0 top-0 italic group-hover:block hidden'}>
                Loop not finished
              </span>
            </Tooltip>
          </>
        )}
      </Rnd>
    ));
  return (
    <>
      {isOpen ? (
        <div {...props} id={'Lane'} ref={ref}>
          <div className={'rail'} style={{ height: 1 }} />
          {all()}
        </div>
      ) : null}
    </>
  );
};

export default Lane;
