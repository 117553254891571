import './style.scss';

import { Label, Modal, TextInput } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../atoms/button/Button';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';

interface SceneDurationModalProps {
  project: C9ProjectDef;
  show: boolean;
  onClose: () => void;
  onConfirm: (duration: number, scene?: SceneDef) => void;
  sceneName: string;
  setSceneName: (name: string) => void;
  scene?: SceneDef;
}
const SceneDurationModal = ({
  project,
  show,
  onConfirm,
  onClose,
  sceneName,
  setSceneName,
  scene,
}: SceneDurationModalProps) => {
  const { activeFramerate } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const [duration, setDuration] = useState<number>(
    project.sceneDefaultDuration ? project.sceneDefaultDuration / 1000 : 10,
  );
  const [failed, setFailed] = useState<boolean>(false);
  const verifyDuration = () => {
    if (duration > 0 && duration < 3601) {
      onConfirm(duration, scene);
      setFailed(false);
      setDuration(0);
      onClose();
      return;
    }
    setFailed(true);
  };
  const closeModal = () => {
    setFailed(false);
    onClose();
  };
  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') verifyDuration();
  };
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
    scene ? setSceneName(scene.name) : setSceneName(`Scene ${project.sceneDefs.length + 1}`);
    scene && setDuration(project.sceneDefaultDuration ?? scene.durationInMS / 1000);
  }, [show, project.sceneDefs.length, setSceneName]);

  //   const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
  //     selectActiveScene(state),
  //   );

  return (
    <>
      {show && (
        <Modal show={show} popup={true} size="md" onClose={onClose} style={{ zIndex: 9999 }}>
          <div className="duration-modal z-[999]">
            <div className="duration-modal-header">
              <Modal.Header>{'Add scene'}</Modal.Header>
            </div>
            <Modal.Body style={{ paddingTop: 10, paddingBottom: 0 }}>
              <div className="mb-2 block mt-2">
                <Label value="Scene name" />
              </div>
              <TextInput
                aria-label=""
                placeholder={`Type Scene name...`}
                onChange={(e) => setSceneName(e.target.value)}
                value={sceneName}
              />
              <div className="mb-2 mt-2 block">
                <Label value="Select or Enter scene duration in seconds." />
              </div>
              <div className="choose-duration mb-3">
                <Button size="small" label="10s" onClick={() => setDuration(10)} />
                <Button size="small" label="20s" onClick={() => setDuration(20)} />
                <Button size="small" label="30s" onClick={() => setDuration(30)} />
                <Button size="small" label="60s" onClick={() => setDuration(60)} />
                <Button size="small" label="120s" onClick={() => setDuration(120)} />
              </div>
              <TextInput
                placeholder="Insert value"
                ref={inputRef}
                autoFocus
                onKeyDown={(e) => onEnter(e)}
                onClick={() => setFailed(false)}
                value={duration}
                onChange={(e) =>
                  e.target.value.match('^[0-9]*$') && setDuration(Number(e.target.value))
                }
                color={failed ? 'red' : 'base'}
              />
              <div className="mb-2 mt-2 block">
                <Label value="Scene duration in frames." />
              </div>
              <TextInput
                placeholder="Insert value"
                ref={inputRef}
                autoFocus
                onKeyDown={(e) => onEnter(e)}
                onClick={() => setFailed(false)}
                value={duration * activeFramerate}
                onChange={(e) =>
                  e.target.value.match('^[0-9]*$') &&
                  setDuration(Number(e.target.value) / activeFramerate)
                }
                color={failed ? 'red' : 'base'}
              />
              {failed && (
                <span style={{ visibility: failed ? 'visible' : 'hidden' }} className="error-info">
                  <span className="font-medium">Error!</span> Please enter a valid duration.
                </span>
              )}
            </Modal.Body>
            <div className="modal-footer flex justify-left">
              <Button buttonType="primary" onClick={() => verifyDuration()} label="Confirm" />
              <Button buttonType="secondary" onClick={closeModal} label="Cancel" />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default SceneDurationModal;
