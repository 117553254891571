import { Button, InputNumber } from 'antd';
import { useEffect, useState } from 'react';

import useAddEnterpriseProperties from '../queries-NEW/useAddEnterpriseProperties';
import useEditEnterpriseProperties from '../queries-NEW/useEditEnterpriseProperties';
import { useGetEnterpriseProperties } from '../queries-NEW/useGetEnterpriseProperties';

interface PropertiesInterface {
  id: string;
  gammaCorrection: number;
  saturation: number;
  contrast: number;
  enterpriseAccountId: string;
}

function StudioProperties({ enterpriseAccountId }: { enterpriseAccountId: string }) {
  const { data } = useGetEnterpriseProperties(enterpriseAccountId);
  const { mutate: addProperties } = useAddEnterpriseProperties();
  const { mutate: editProperties } = useEditEnterpriseProperties();
  const [properties, setProperties] = useState<PropertiesInterface>(data);

  useEffect(() => {
    setProperties(data);
  }, [data]);

  const handleProperties = (prop: string, e: number | null) => {
    if (e) setProperties((prev) => ({ ...prev, [prop]: e }));
  };

  const saveProperties = () => {
    if (data) {
      editProperties(properties);
    } else {
      addProperties({
        enterpriseAccountId,
        gammaCorrection: properties.gammaCorrection,
        saturation: properties.saturation,
        contrast: properties.contrast,
      });
    }
  };

  return (
    <div>
      <div className="mt-2 flex gap-4">
        <div>
          <p className="pb-2">Gamma</p>
          <InputNumber
            className="w-full"
            value={properties?.gammaCorrection ? properties.gammaCorrection : 1}
            onChange={(e) => handleProperties('gammaCorrection', e)}
            step={0.2}
            min={0}
            max={2}
          />
        </div>

        <div>
          <p className="pb-2">Saturation</p>
          <InputNumber
            className="w-full"
            value={properties?.saturation ? properties.saturation : 1}
            onChange={(e) => handleProperties('saturation', e)}
            step={0.2}
            min={0}
            max={2}
          />
        </div>

        <div>
          <p className="pb-2">Contrast</p>
          <InputNumber
            className="w-full"
            value={properties?.contrast ? properties.contrast : 1}
            onChange={(e) => handleProperties('contrast', e)}
            step={0.2}
            min={0}
            max={2}
          />
        </div>
      </div>
      <Button className="mt-4 " type="primary" onClick={saveProperties}>
        Save
      </Button>
    </div>
  );
}

export default StudioProperties;
