import { Input as AntInput, notification, Select } from 'antd';
import { Button, ToggleSwitch } from 'flowbite-react';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../atoms/input/Input';
import { useApplyBiasGroupOnObserved } from '../../../../core/api/bias/bias-group/useApplyBiasGroupOnObserved';
import { useGetBiasGroups } from '../../../../core/api/bias/bias-group/useGetBiasGroups';
import { useGetOrderedIconSet } from '../../../../core/api/bias/bias-group/useGetOrderedIconSet';
import { useCreateObservedBias } from '../../../../core/api/bias/useCreateObservedBias';
import { stringToUnit } from '../../../../helpers/stringToUnit';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { ObservedWDElementDef } from '../../../../model/definitions/ObservedWDElementDef';
import { ObservedWDSourceDef } from '../../../../model/definitions/ObservedWDSourceDef';
import { SceneDef } from '../../../../model/definitions/SceneDef';
import { ValueTypeEnum } from '../../../../model/enums/ValueTypeEnum';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../store/slices/active-slice';
import {
  addMapOWDLayer,
  updateOWDLayer,
  updatePanel,
} from '../../../../store/slices/project-slice';
import { selectActiveScene } from '../../../../store/slices/selectors';
import { RootState } from '../../../../store/store';
import { DDIcon } from '../../bias-filter/Icon';
import { BiasFilterModal } from '../../modals/BiasFilterModal';
import { PropertySection } from '../components/PropertySection';
import styles from '../Properties.module.scss';
import GridActions from '../shared/GridActions';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';
import { ChangeIconModal } from './ChangeIconModal';

const { Option } = Select;
interface ObservedSpecificPropertiesProps {
  layer: ObservedWDElementDef;
  name: string;
  description: string;
  source: ObservedWDSourceDef;
  displayUnitOfMeasurement: boolean;
  mapId: string;
}
export const ObservedSpecificProperties = ({
  layer,
  source,
  name,
  description,
  displayUnitOfMeasurement,
  mapId,
}: ObservedSpecificPropertiesProps) => {
  const dispatch = useDispatch();
  const { activeScene, activeElement, activePoster, activeMap } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );

  const { isOpened, lastFocused } = usePropertyGridActive([
    'observedWDSource.value',
    'name',
    'description',
    'displayUnitOfMeasurement',
  ]);
  const { data: icons } = useGetOrderedIconSet(source.originalValue?.iconSetVersionId);
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const { data: biasFilters } = useGetBiasGroups('OBSERVED');
  const maps = activeSceneDef?.mapPanels.map((layer) => {
    return { label: layer.name, value: layer.id };
  });
  const handleChange = (index: number) => {
    console.log(index);
    if (icons.length - 1 >= index && index >= 0) {
      const newIcon = icons.find((icon: any) => icon.iconIndexInSet === index);
      updateValue(newIcon, 'observedWDSource.value');
    }
  };
  const [open, setOpen] = useState<boolean>(isOpened);
  const [modal, setModal] = useState<boolean>(false);
  const [isModal, setIsModal] = useState(false);
  const [selectedMap, setSelectedMap] = useState(maps ? maps[0]?.value : undefined);
  const { mutate: createBias } = useCreateObservedBias();
  const { station, datetime, parameterType, unitOfMeasurement, value, valueType, valueHistory } =
    source;
  const { fullName, latitude, longitude, value: code } = station;
  const hasBias = layer.observedWDSource.biasFilterAppliedOnValue;
  const { mutate: applyBiasGroup } = useApplyBiasGroupOnObserved();

  const applyBiasGroupOnElement = (id: string) => {
    applyBiasGroup(
      {
        biasGroupId: id,
        body: layer,
      },
      {
        onSuccess: (data) => {
          dispatch(
            updateOWDLayer({
              newValue: data.observedWDSource,
              //@ts-ignore
              propertyPath: 'observedWDSource',
              activeScene,
              elementId: activeElement,
              parentId: activePoster,
              mapId: activeMap,
            }),
          );
          notification.success({ message: 'Group Filter Applied!' });
        },
      },
    );
  };
  const filterSelector = () => {
    const options = biasFilters?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select
        defaultValue={layer.observedWDSource.valueHistory.slice(-1)[0]?.appliedBiasFilterId}
        onChange={applyBiasGroupOnElement}
      >
        {options}
      </Select>
    );
  };
  const addObservedDataToMap = () => {
    if (selectedMap) {
      dispatch(
        addMapOWDLayer({
          owdLayer: { ...layer, positionControl: { ...layer.positionControl, x: 0, y: 0 } },
          activeScene,
          mapId: selectedMap,
        }),
      );
      dispatch(
        updatePanel({
          activeScene: activeScene,
          activeProp: 'observedWDElements' as keyof SceneDef,
          activeElement: activeElement,
        }),
      );
    }
  };
  const updateValue = (
    newValue: string | number | boolean | unknown,
    propertyPath: Leaves<ObservedWDElementDef>,
  ) => {
    onFocus(propertyPath);
    dispatch(
      updateOWDLayer({
        newValue,
        propertyPath,
        activeScene,
        elementId: activeElement,
        parentId: activePoster,
        mapId: activeMap,
      }),
    );
  };

  function onFocus(path: Leaves<ObservedWDElementDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  const unitsSelection = () => (
    <Select
      onChange={(e) => updateValue(e, 'observedWDSource.unitOfMeasurement')}
      value={value?.length < 1 ? undefined : unitOfMeasurement}
      options={
        Array.isArray(value)
          ? value.map((val: { unit: string; value: number }) => {
              return { label: stringToUnit(val.unit), value: val.unit };
            })
          : []
      }
    />
  );
  const removeBiasFilter = () => {
    const values =
      layer.observedWDSource.valueType === ValueTypeEnum.NUMERICAL
        ? layer.observedWDSource.value.map((val: any) => {
            return { ...val, value: val.originalValue };
          })
        : layer.observedWDSource.originalValue;
    const newValue = {
      ...layer.observedWDSource,
      biasFilterAppliedOnValue: false,
      value: values,
    };
    dispatch(
      updateOWDLayer({
        newValue,
        //@ts-ignore
        propertyPath: 'observedWDSource',
        activeScene,
        elementId: activeElement,
        parentId: activePoster,
        mapId: activeMap,
      }),
    );
  };
  return (
    <>
      <div className="mb-2 subheader" onClick={() => setOpen(!open)}>
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Observed Data Properties
      </div>
      {open && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Name:'}
              item={
                <Input
                  value={name}
                  onFocus={() => onFocus('name')}
                  autoFocus={lastFocused === 'name'}
                  onChange={(e) => updateValue(e.target.value, 'name')}
                />
              }
            />
            <GridItem
              label={'Description:'}
              item={
                <AntInput
                  value={description}
                  onFocus={() => onFocus('description')}
                  autoFocus={lastFocused === 'description'}
                  onChange={(e) => updateValue(e.target.value, 'description')}
                />
              }
            />
            <GridItem noBorderBg label={'Station name:'} item={fullName} />
            <GridItem
              label={'Location:'}
              item={
                <>
                  {latitude.toFixed(4)},{longitude.toFixed(4)}
                </>
              }
            />
            <GridItem label={'Station code:'} item={code} />
            <GridItem
              label={'Measurement Time:'}
              item={new Date(datetime * 1000).toLocaleString('sr-RS', {
                timeZoneName: 'short',
                timeZone: 'UTC',
              })}
            />

            {valueType === ValueTypeEnum.NUMERICAL && (
              <GridItem noBorderBg label={'Parameter:'} item={parameterType} />
            )}
            {valueType === ValueTypeEnum.IMAGE && (
              <GridItem noBorderBg label={'Parameter:'} item={<div>{parameterType}</div>} />
            )}
            <GridItem label={'Apply Group Bias:'} noBorderBg item={filterSelector()} />
            {valueType === ValueTypeEnum.NUMERICAL && (
              <>
                <GridItem noBorderBg label={'Unit of measurement:'} item={unitsSelection()} />
                <GridItem
                  label={'Value:'}
                  item={
                    <div className={'flex'}>
                      <AntInput
                        disabled={value?.length < 1}
                        value={
                          Array.isArray(value)
                            ? (value.find(
                                (val: { unit: string; value: number }) =>
                                  val.unit === unitOfMeasurement,
                              ).value as number)
                            : NaN
                        }
                        type={'number'}
                        onFocus={() => onFocus('observedWDSource.value')}
                        autoFocus={lastFocused === 'observedWDSource.value'}
                        onChange={(e) => {
                          const newVal = cloneDeep(value);
                          newVal.find(
                            (val: { unit: string; value: number }) =>
                              val.unit === unitOfMeasurement,
                          ).value = e.target.value;
                          updateValue(newVal, 'observedWDSource.value');
                        }}
                        className={styles.inputWrap}
                      />
                    </div>
                  }
                />
                <GridItem
                  noBorderBg
                  label={'Display unit:'}
                  item={
                    <ToggleSwitch
                      disabled={!!activePoster}
                      style={{ width: 'auto' }}
                      label={''}
                      checked={displayUnitOfMeasurement}
                      onChange={(e) => {
                        updateValue(e, 'displayUnitOfMeasurement');
                      }}
                    />
                  }
                />
                <GridItem
                  noBorderBg
                  label={`${!mapId ? 'Select' : ''} Parent Map`}
                  item={
                    <Select
                      value={selectedMap}
                      onChange={(e) => setSelectedMap(e)}
                      options={maps}
                      disabled={!!mapId}
                    >
                      Add to selected map
                    </Select>
                  }
                />
                {!mapId && (
                  <GridItem
                    noBorderBg
                    label={''}
                    item={
                      <Button disabled={!selectedMap} onClick={addObservedDataToMap}>
                        Add to map
                      </Button>
                    }
                  />
                )}
              </>
            )}
            {valueType === ValueTypeEnum.IMAGE && (
              <GridItem
                label={'Value:'}
                noBorderBg
                item={
                  source.value?.thumbnailUrls.length > 0 ? (
                    <img
                      className="h-2/3"
                      src={source.value?.thumbnailUrls[0]}
                      alt={source.value?.name}
                    />
                  ) : (
                    <DDIcon
                      versionId={source.value?.versionId}
                      isJson={source.value?.jsonAnimation}
                      full
                    />
                  )
                }
              />
            )}
          </GridWrapper>
          {valueType === ValueTypeEnum.IMAGE && (
            <PropertySection isOpened={true} label={'Available icons'}>
              <div className={'grid grid-cols-6 gap-2'}>
                {icons?.map((icon: any) => (
                  <div
                    onClick={() => handleChange(icon.iconIndexInSet)}
                    key={icon.versionId}
                    className={`${
                      icon?.versionId === source.value?.versionId
                        ? 'border-green-500'
                        : 'border-slate-200'
                    } flex flex-col items-center justify-center border border-solid border-slate-200 h-[90px] cursor-pointer`}
                  >
                    {icon.thumbnailUrls.length > 0 ? (
                      <img className="h-2/3" src={icon.thumbnailUrls[0]} alt={icon.name} />
                    ) : (
                      <DDIcon versionId={icon.versionId} isJson={icon.jsonAnimation} />
                    )}
                    <div className={'text-center'}>{icon.name}</div>
                  </div>
                ))}
              </div>
            </PropertySection>
          )}

          <GridActions>
            <Button
              color="purple"
              onClick={() => setIsModal(true)}
              disabled={valueHistory[valueHistory.length - 1]?.newValue === source.value}
            >
              Save bias filter
            </Button>
            {hasBias && (
              <Button
                color="purple"
                onClick={() => removeBiasFilter()}
                disabled={valueHistory[valueHistory.length - 1]?.newValue === source.value}
              >
                Remove bias filter
              </Button>
            )}
          </GridActions>
        </div>
      )}
      <BiasFilterModal
        sourceVal={source.value}
        type={source.valueType}
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        value={source.value}
        originalValue={source.originalValue}
        unit={source.unitOfMeasurement}
        parameter={source.parameterType}
        onCreate={(form) => {
          const biased =
            valueType === ValueTypeEnum.NUMERICAL
              ? Number(
                  value.find(
                    (val: { unit: string; value: number }) => val.unit === unitOfMeasurement,
                  ).value,
                )
              : value;
          createBias({
            filterName: `${form.name}`,
            filterDescription: form.description,
            observedWDSource: {
              ...source,
              changedValue: biased,
            },
          });
          setIsModal(false);
        }}
      />
      <ChangeIconModal open={modal} onClose={() => setModal(false)} />
    </>
  );
};
