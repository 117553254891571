import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useFontLoader } from '../../core/api/useLoadFont';
import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { BorderDef } from '../../model/definitions/BorderDef';
import { PointLocationDef } from '../../model/definitions/PointLocationDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import ElementContainer from './ElementContainer';
import { transformPercentToAbsolute, translateText } from './utils';

interface PointLocationElementElementProps {
  panelProps: PointLocationDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  isMapTextElement?: boolean;
  mapId?: string;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  geoPosterId?: string;
  scene: SceneDef | WeatherPosterDef;
}

export const PointLocationElement = ({
  panelProps,
  canvas,
  disabled,
  mapId,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
}: PointLocationElementElementProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { activeAspectRatio } = useSelector<RootState, ActiveDef>((state) => state.active);
  const {
    boxDef,
    fontSize,
    fontFamily,
    fontType,
    fontColor,
    fontAlignment,
    textAnimation,
    timeControls,
    positionControl,
    //useMaskValue,
    maskValue,
    //value,
    strokeWidth,
    strokeColor,
    textTransform,
  } = panelProps;
  const {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    background,
    borderRight,
    borderTop,
    borderLeft,
    borderBottom,
  } = boxDef;
  const { cnvHeight } = canvas;
  let fontUnit: number;
  if (inPoster && cnvHeight) {
    const cH = transformPercentToAbsolute(
      cnvHeight,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    fontUnit = cH / 100;
  } else {
    fontUnit = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  }
  // const findInForecast = () => {
  //   return scene.forecastWDElements.find(
  //     (element) => element.pointWDGroupId === panelProps.pointWDGroupId,
  //   )?.forecastWDSource.location.name;
  // };
  // const findInObserved = () => {
  //   return [...scene.observedWDElements].find(
  //     (element) => element.pointWDGroupId === panelProps.pointWDGroupId,
  //   )?.observedWDSource.station.fullName;
  // };
  if (inPoster && cnvHeight) {
    const cH = transformPercentToAbsolute(
      cnvHeight,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    fontUnit = cH / 100;
  } else {
    fontUnit = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  }
  const { color } = background;
  const backgroundClip = fontColor?.includes('linear') ? 'text' : 'initial';
  const bgColor = fontColor?.includes('linear-gradient')
    ? gradientOpacity(fontColor)
    : singleColorOpacity(color);
  useFontLoader(fontFamily);
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;
  const borderString = (val: BorderDef) =>
    val && `${fontUnit * val.width}px ${val.style} ${singleColorOpacity(val.color)}`;
  return (
    <ElementContainer
      visibility={panelProps.enabled}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      type={'pointLocation'}
      lock={false}
      style={{
        fontFamily: fontFamily + ' ' + fontType,
        textTransform,
        color: fontColor?.includes('linear') ? 'transparent' : singleColorOpacity(fontColor),
        textAlign: fontAlignment as FontAlignment,
        fontSize: fontUnit * fontSize,
      }}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
    >
      <div
        key={fontColor + 'container'}
        style={{
          height: '100%',
          overflow: 'hidden',
          background: bgColor,
          paddingTop: fontUnit * paddingTop,
          paddingLeft: fontUnit * paddingLeft,
          paddingRight: fontUnit * paddingRight,
          paddingBottom: fontUnit * paddingBottom,
          borderLeft: borderString(borderLeft),
          borderRight: borderString(borderRight),
          borderTop: borderString(borderTop),
          borderBottom: borderString(borderBottom),
          // @ts-ignore
          WebkitBackgroundClip: backgroundClip,
        }}
      >
        <div style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
          <div
            style={{
              height: '100%',
              minWidth: textAnimation.active ? '100%' : 'auto',
              width: textAnimation.active ? 'fit-content' : 'auto',
              whiteSpace: textAnimation.active ? 'nowrap' : 'break-spaces',
              /*** I don't know the reason behind fixing margin and line height, but it is causing issues ***/
              lineHeight: 1.15,
              // @ts-ignore
              WebkitBackgroundClip: backgroundClip,
              transform: textAnimation?.active
                ? translateText(
                    positionControl.w,
                    activeAspectRatio,
                    time,
                    ref,
                    textAnimation.speed,
                    timeControls[0].startMS,
                    MAX_FULLSCREEN_HEIGHT,
                    textAnimation.direction,
                  )
                : 'none',
              WebkitTextStroke: strokeWidth
                ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
                : '0px',
            }}
            ref={ref}
          >
            {maskValue}
          </div>
        </div>
      </div>
    </ElementContainer>
  );
};
