import { isEqualWith } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LoadingIndicator from '../../atoms/loadingIndicator/LoadingIndicator';
import { usePrompt } from '../../hooks/useBlockNavigation';
import { useIsPlaying } from '../../hooks/useIsPlaying';
import useRoleAccess from '../../hooks/useRoleAccess';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { RolesEnum } from '../../model/enums/RolesEnum';
import { ProjectState } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import styles from './UnsavedChanges.module.scss';

interface Props {
  onSave?: () => void;
  isLoading: boolean;
}

function removeIndicators(project: C9ProjectDef) {
  const proj = { ...project };
  proj.sceneDefs = project.sceneDefs.map((s) => ({
    ...s,
    mapPanels: s.mapPanels.map((m) => ({
      ...m,
      wdSpace: m.wdSpace.map((wd) => ({ ...wd, indicator: [] })),
    })),
  }));
  return proj;
}

const UnsavedChanges: React.FC<Props> = ({ onSave, isLoading }) => {
  const isPlaying = useIsPlaying();
  const { project, currentSavedProject } = useSelector<RootState, ProjectState>(
    (state) => state.project.present,
  );
  const roleAccess = useRoleAccess(
    [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER, RolesEnum.ROLE_FORECASTER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const mapIsLoading = useSelector<RootState, boolean>((state) => state.mapLoading.mapIsLoading);
  // const propertiesToOmit = ['dataFrames', 'unLoadedDataFrames'];

  // const getOmittedProject = (project: C9ProjectDef) => {
  //   const projectNoFrames = cloneDeep(project) as any;
  //   forEach(projectNoFrames.sceneDefs, (sceneDef) => {
  //     forEach(sceneDef.mapPanels, (mapDef) => {
  //       forEach(mapDef.wdSpace, (wdSpace) => {
  //         wdSpace.gribMapLayers = map(wdSpace.gribMapLayers, (gribMapLayer) => {
  //           return omit(gribMapLayer, propertiesToOmit);
  //         });
  //         wdSpace.satelliteMapLayers = map(wdSpace.satelliteMapLayers, (satelliteMapLayer) => {
  //           return omit(satelliteMapLayer, propertiesToOmit);
  //         });
  //         wdSpace.radarMapLayers = map(wdSpace.radarMapLayers, (radarMapLayer) => {
  //           return omit(radarMapLayer, propertiesToOmit);
  //         });
  //       });
  //     });
  //   });
  //   return projectNoFrames;
  // };
  const hasChanges = useMemo(() => {
    return !isEqualWith(project, currentSavedProject, (proj, savedProj) => {
      return JSON.stringify(removeIndicators(proj)) === JSON.stringify(removeIndicators(savedProj));
    });
  }, [currentSavedProject, project]);

  usePrompt('You have unsaved changes. Are you sure you want to leave?', hasChanges);
  if (hasChanges && !isPlaying)
    return (
      <div
        className={`fixed flex items-center text-sm inset-x-0 top-[10px] 
        ${styles.wrapper}`}
        style={{ left: isLoading ? 'calc(-117px - 1rem)' : 'calc(-117px - 0.5rem)' }}
      >
        <span
          className={`flex items-center gap-x-2 ${
            roleAccess ? '' : '!pointer-events-none opacity-30'
          } ${mapIsLoading ? styles.disabled : ''}`}
          onClick={() => {
            if (!mapIsLoading && onSave) {
              onSave();
            }
          }}
        >
          Save changes! {isLoading && <LoadingIndicator />}
        </span>
      </div>
    );
  return null;
};
export default UnsavedChanges;
