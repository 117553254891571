import { Editor } from 'slate';

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);

  return marks ? marks[format] === true : false;
};
export const addMarkData = (editor, data) => {
  Editor.addMark(editor, data.format, data.value);
};
export const activeMark = (editor, format) => {
  const defaultMarkData = {
    fontFamily: 'Arial',
    fontType: 'Regular',
    fontVariantId: '652df005-06c6-4149-88d8-d0ce17e1b59e',
  };
  const marks = Editor.marks(editor);
  const defaultValue = defaultMarkData[format];
  return marks?.[format] ?? defaultValue;
};
