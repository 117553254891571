import { TbBoxPadding } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';

import { usePropertyGridActive } from '../../../../../hooks/usePropertyGridActive';
import { BoxDef } from '../../../../../model/definitions/BoxDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../../store/slices/active-slice';
import { RootState } from '../../../../../store/store';
import styles from '../../Properties.module.scss';

interface PaddingControlProps {
  top: number;
  bottom: number;
  left: number;
  right: number;
  onChange: (e: number, name: Leaves<BoxDef>) => void;
}
export const PaddingControl = ({ top, bottom, left, right, onChange }: PaddingControlProps) => {
  const { activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const dispatch = useDispatch();
  const { lastFocused } = usePropertyGridActive([]);
  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  return (
    <>
      <h4>Padding</h4>
      <div className="grid prop-grid-4">
        <div className={'grid-item-label'}>
          <label> </label>
        </div>
        <div className={'grid-item tb-box-wrapper'}>
          <div className="padding-top-box">
            <input
              type={'number'}
              value={top}
              onChange={(e) => onChange(parseFloat(e.target.value), 'paddingTop')}
              className={styles.inputWrap}
              onFocus={() => onFocus('paddingTop')}
              autoFocus={lastFocused === 'paddingTop'}
            />
          </div>
          <div className="padding-left-box">
            <input
              type={'number'}
              value={left}
              onChange={(e) => onChange(parseFloat(e.target.value), 'paddingLeft')}
              className={styles.inputWrap}
              onFocus={() => onFocus('paddingLeft')}
              autoFocus={lastFocused === 'paddingLeft'}
            />
          </div>
          <div className="padding-right-box">
            <input
              type={'number'}
              value={right}
              onChange={(e) => onChange(parseFloat(e.target.value), 'paddingRight')}
              onFocus={() => onFocus('paddingRight')}
              autoFocus={lastFocused === 'paddingRight'}
              className={styles.inputWrap}
            />
          </div>
          <div className="padding-bottom-box">
            <input
              type={'number'}
              value={bottom}
              onChange={(e) => onChange(parseFloat(e.target.value), 'paddingBottom')}
              onFocus={() => onFocus('paddingBottom')}
              autoFocus={lastFocused === 'paddingBottom'}
              className={styles.inputWrap}
            />
          </div>
          <div className="tb-box">
            <TbBoxPadding size={32} />
          </div>
        </div>
      </div>
    </>
  );
};
