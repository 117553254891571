import { chunk } from 'lodash';
import { Pixel } from 'ol/pixel';

import { FrameLoadingResultData } from '../WeatherDataLoaderTypes';

export const COORDS_POSITIONS: Record<string, CoordinatePositions> = {};

interface CoordinatePositions {
  positions: Float32Array;
  width: number;
  height: number;
  loading: boolean;
}

const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

export const recalculatePositions = async (
  frame: FrameLoadingResultData,
  size: number[],
  isNsper: boolean,
  projectionCenterLat: number,
  projectionCenterLon: number,
  getPixelFromCoordinate: (coord: number[], projString: string) => Pixel,
) => {
  const id = frame.frameInfo.coordinates.id;
  const projectionString = frame.frameInfo.coordinates.projection;

  const w = size[0],
    h = size[1];

  // if (COORDS_POSITIONS[id] && !COORDS_POSITIONS[id].loading) {
  //   return COORDS_POSITIONS[id];
  // }

  const coords = new Image();
  coords.src = frame.coordinates;
  await coords.decode();

  const coordsPixels = getImagePixelData(coords);

  const coordsInRows = chunk(chunk(coordsPixels, 4), coords.width);

  const { min_x, max_x, min_y, max_y } = frame.frameInfo.coordinates;

  const dataResolutionX = coords.width;
  const dataResolutionY = coords.height;

  const numParticles = dataResolutionX * dataResolutionY;

  const positions = new Float32Array(numParticles * 4 * 6);

  let index = 0;
  let lastGoodInt: number[] = [];
  for (let y = 0; y < dataResolutionY; y++) {
    // let prevTr: Pixel | null = null;
    // let prevBr: Pixel | null = null;

    for (let x = 0; x < dataResolutionX; x++) {
      const xa = x + 1 == dataResolutionX ? x : x + 1;
      const ya = y + 1 == dataResolutionY ? y : y + 1;

      let tlPixelValue = coordsInRows[y][x];

      if (tlPixelValue) lastGoodInt = tlPixelValue;
      else tlPixelValue = lastGoodInt;

      let trPixelValue = coordsInRows[y][xa];

      if (trPixelValue) lastGoodInt = trPixelValue;
      else trPixelValue = lastGoodInt;

      let brPixelValue = coordsInRows[ya][xa];

      if (brPixelValue) lastGoodInt = brPixelValue;
      else brPixelValue = lastGoodInt;

      let blPixelValue = coordsInRows[ya][x];

      if (blPixelValue) lastGoodInt = blPixelValue;
      else blPixelValue = lastGoodInt;

      const tlPixelLocation = getLocationFromPixel(tlPixelValue, min_x, max_x, min_y, max_y);
      const trPixelLocation = getLocationFromPixel(trPixelValue, min_x, max_x, min_y, max_y);

      let valueInvalid = false;

      if (isNsper) {
        // For near-side projection, check distance
        const distc = distance(
          tlPixelLocation[1],
          tlPixelLocation[0],
          projectionCenterLat,
          projectionCenterLon,
        );
        if (distc > 6371) {
          valueInvalid = true;
        }
      }

      // added because satellite had empty pixels which caused artifacts
      if (
        tlPixelValue.every((x) => x === 0) ||
        trPixelValue.every((x) => x === 0) ||
        blPixelValue.every((x) => x === 0) ||
        brPixelValue.every((x) => x === 0)
      ) {
        valueInvalid = true;
      }

      const spTL = valueInvalid
        ? [0, 0]
        : getPixelFromCoordinate(tlPixelLocation, projectionString);
      const spTR = valueInvalid
        ? [0, 0]
        : getPixelFromCoordinate(trPixelLocation, projectionString);
      const spBR = valueInvalid
        ? [0, 0]
        : getPixelFromCoordinate(
            getLocationFromPixel(brPixelValue, min_x, max_x, min_y, max_y),
            projectionString,
          );
      const spBL = valueInvalid
        ? [0, 0]
        : getPixelFromCoordinate(
            getLocationFromPixel(blPixelValue, min_x, max_x, min_y, max_y),
            projectionString,
          );

      // Update previous values for next iteration
      // prevTr = spTR;
      // prevBr = spBR;

      // TOP LEFT
      positions[index] = spTL[0] / w;
      positions[index + 1] = spTL[1] / h;
      positions[index + 2] = x;
      positions[index + 3] = y;
      index += 4;

      // TOP RIGHT
      positions[index] = spTR[0] / w;
      positions[index + 1] = spTR[1] / h;
      positions[index + 2] = xa;
      positions[index + 3] = y;
      index += 4;

      // BOTTOM LEFT
      positions[index] = spBL[0] / w;
      positions[index + 1] = spBL[1] / h;
      positions[index + 2] = x;
      positions[index + 3] = ya;
      index += 4;

      // SECOND TRIANGLE

      // TOP RIGHT
      positions[index] = spTR[0] / w;
      positions[index + 1] = spTR[1] / h;
      positions[index + 2] = xa;
      positions[index + 3] = y;
      index += 4;

      // BOTTOM LEFT
      positions[index] = spBL[0] / w;
      positions[index + 1] = spBL[1] / h;
      positions[index + 2] = x;
      positions[index + 3] = ya;
      index += 4;

      // BOTTOM RIGHT
      positions[index] = spBR[0] / w;
      positions[index + 1] = spBR[1] / h;
      positions[index + 2] = xa;
      positions[index + 3] = ya;
      index += 4;
    }
  }

  COORDS_POSITIONS[id] = {
    positions,
    width: dataResolutionX,
    height: dataResolutionY,
    loading: false,
  };

  return COORDS_POSITIONS[id];
};

const getImagePixelData = (image: HTMLImageElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  const gl = canvas.getContext('webgl');
  if (!gl) return;

  const texture = gl.createTexture();

  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
  gl.generateMipmap(gl.TEXTURE_2D);

  // Create a framebuffer object
  const framebuffer = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);

  // Read pixel values from the framebuffer
  const pixels = new Uint8Array(canvas.width * canvas.height * 4);
  gl.readPixels(0, 0, canvas.width, canvas.height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

  // Remove context
  gl.getExtension('WEBGL_lose_context')?.loseContext();

  return pixels;
};

function decode(a: number, b: number, min: number, max: number) {
  return min + (((a << 8) | b) / 65535) * (max - min);
}

const getLocationFromPixel = (
  rgba: number[],
  min_x: number,
  max_x: number,
  min_y: number,
  max_y: number,
) => {
  const x = decode(rgba[0], rgba[1], min_x, max_x);
  const y = decode(rgba[2], rgba[3], min_y, max_y);

  return [x, y];
};
